import fetchStream from 'fetch-readablestream'
import qs from 'qs'
import ObjectHelper from './object'
import { useApi } from '/@src/composable/useApi'
import { IOptions } from '/@src/interfaces'
import { useUserSession } from '/@src/stores'

// Composable
const api = useApi()
const apiWithoutApp = useApi(false)

export default class HttpHelper {
  public static get = async (url: string, config?: IOptions | undefined) => {
    const {
      filters,
      search,
      orderBy,
      expand,
      groupBy,
      propertyUid,
      withoutApp,
      headers,
      limit,
      start,
      returnMetadata,
    } = config || {}
    const _api = withoutApp ? apiWithoutApp : api
    let _filters = filters ?? []

    if (propertyUid) {
      _filters = [
        ..._filters,
        {
          key: 'property_uid',
          operator: '=',
          value: propertyUid,
        },
      ]
    }

    let _url = url
    const query = qs.stringify(
      {
        limit,
        start,
        search,
        expand: qs.parse(expand),
        filters: qs.parse(_filters),
        orderBy: qs.parse(orderBy),
      },
      { arrayFormat: 'indices' }
    )

    if (query) {
      _url += `?${query}`
    }

    const response = await _api({
      method: 'GET',
      url: _url,
      headers: {
        ...headers,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': true,
      },
    })

    if (returnMetadata) {
      return response?.data
    }

    const rows = response?.data?.data ?? response?.data

    if (groupBy) {
      return ObjectHelper.groupBy(rows, groupBy)
    }

    return rows
  }

  public static post = async (
    url: string,
    updateUid: string | null,
    data: any,
    config?: IOptions | undefined,
    callback?: Function
  ) => {
    let _api = api
    const { filters, expand, withoutApp, headers, responseType, accumulateChunks } = config || {}
    const controller = new AbortController()
    const userSession = useUserSession()

    if (withoutApp) {
      _api = apiWithoutApp
    }

    let _headers = { ...headers }
    let _url = updateUid ? `${url}/${updateUid}` : url
    const query = qs.stringify(
      // @ts-ignore
      { expand: qs.parse(expand), filters: qs.parse(filters) },
      { arrayFormat: 'indices' }
    )

    if (query) {
      _url += `?${query}`
    }

    if (userSession?.token) {
      _headers = { ..._headers, Authorization: `Bearer ${userSession?.token}` }
    }

    if (responseType === 'stream') {
      try {
        const readAllChunks = async (readableStream: ReadableStream) => {
          const reader = readableStream.getReader()
          const decoder = new TextDecoder()
          const chunks: string[] = []

          const pump = () => {
            return reader.read().then(async ({ value, done }) => {
              if (done) {
                return
              }

              const decodedValue = decoder.decode(value, { stream: true })
              const matches = decodedValue.match(/\[AI\](.*?)\[\/AI\]/gs)
              const chunk =
                matches && matches.length > 0
                  ? matches[matches.length - 1].replace(/\[\/?AI\]/g, '')
                  : ''

              if (accumulateChunks) {
                chunks.push(chunk)
              }

              await callback?.(accumulateChunks ? chunks : chunk)

              return pump()
            })
          }

          return pump()
        }

        const BASE_URL = import.meta.env.VITE_API_URL
        const response = await fetchStream(BASE_URL + _url, {
          signal: controller.signal,
          method: updateUid ? 'PUT' : 'POST',
          headers: _headers,
          body: JSON.stringify(data),
          timeout: 0,
        })

        if (response?.status !== 200) {
          console.error(response?.statusText ?? response?.message)

          throw {
            code: response?.status === 426 ? 'Upgrade Required' : undefined,
            message: response?.statusText ?? response?.message,
            status: response?.status,
          }
        }

        return { signalController: controller, chunks: readAllChunks(response.body) }
      } catch (error) {
        throw error
      }
    } else {
      if (data instanceof FormData) {
        _headers = {
          ...headers,
          'Content-Type': 'multipart/form-data',
        }
      }

      const response = await _api({
        method: updateUid ? 'PUT' : 'POST',
        url: _url,
        headers: _headers,
        data,
      })

      if (callback) {
        callback()
      }

      return response?.data?.data ?? response?.data
    }
  }

  public static abort = async (controller: any) => {
    controller.abort()
  }

  public static batch = async (
    url: string,
    updateUid: string | null,
    data: any,
    config?: IOptions | undefined,
    callback?: Function
  ) => {
    const { batchSize, batchKey } = config ?? {}

    if (!batchSize) {
      throw new Error('batchSize or batchKey not found')
    }

    let batchData

    if (batchKey && data instanceof FormData) {
      batchData = data.getAll(batchKey)
    } else if (batchKey) {
      batchData = Array.isArray(data) ? data.map((item: any) => item[batchKey]) : data[batchKey]
    } else {
      batchData = Array.isArray(data) ? data : []
    }

    if (
      !batchData ||
      !Array.isArray(batchData) ||
      (Array.isArray(batchData) && !batchData.length)
    ) {
      return HttpHelper.post(url, updateUid, data, config, callback)
    }

    const numBatches = Math.ceil(batchData.length / batchSize)
    const responses = []

    for (let i = 0; i < numBatches; i++) {
      const startIndex = i * batchSize
      const endIndex = Math.min(startIndex + batchSize, batchData.length)
      let batchPayload: any

      if (data instanceof FormData) {
        batchPayload = new FormData()

        data.forEach((value: any, key: string) => {
          if (key !== batchKey) {
            batchPayload.append(key, value)
          }
        })

        batchData.slice(startIndex, endIndex).forEach((value: any) => {
          batchPayload.append(batchKey, value)
        })
      } else {
        if (batchKey) {
          batchPayload = { ...data, [batchKey]: batchData.slice(startIndex, endIndex) }
        } else {
          batchPayload = batchData.slice(startIndex, endIndex)
        }
      }

      if (callback) {
        callback(numBatches, i)
      }

      const response = await HttpHelper.post(url, updateUid, batchPayload, config)

      responses.push(response)
    }

    return responses
  }

  public static destroy = async (
    url: string,
    updateUid: string | null,
    config?: IOptions | undefined
  ) => {
    let _api = api
    const { withoutApp } = config || {}

    if (withoutApp) {
      _api = apiWithoutApp
    }

    const _url = updateUid ? `${url}/${updateUid}` : url

    const response = await _api({
      method: 'DELETE',
      url: _url,
    })

    return response?.data?.data ?? response?.data
  }
}
