<script setup lang="ts">
import { computed, onBeforeMount, onMounted, Ref, ref } from 'vue'
import {
  PwaHelper,
  StringHelper,
  UrlHelper,
  ViaPlaceholderHelper,
  WindowHelper,
} from '/@src/helpers'
import { IQueryParameters } from '/@src/interfaces'
import { ICONS } from '/@src/resources/files/constant'
import { useWidget } from '/@src/stores'

const emit = defineEmits<{
  (e: 'newChat'): void
}>()

// Composable
const widgetStore = useWidget()
const defaultUtm = `?utm_source=insertchat.com&utm_medium=header&chatbot_uid=${widgetStore.getWidget()?.uid}`

// Reactive data
const isLoading = ref(false)
const logoSettings: any = ref({})
const bubbleSettings: any = ref({})
const agentsLimitation = ref([])
const mobileAppLimitations = ref()
const accountsAgent: any = ref([])
const installPromptEvent: Ref<Event | null> = ref(null)
const userMessageStylingSettings: any = ref({})
const mobileAppSettings: any = ref({})
const copyrightSettings: any = ref({})
const isPWAInstalled = ref(false)
const brandingLimitation = ref()
const modalShareProps: any = ref({ open: false })

// Computed
const computedIsBubble = computed(() => {
  const { botType, isBubble }: IQueryParameters = UrlHelper.getWidgetQueryParams()

  return isBubble || botType === 'bubble'
})

// Lifecycle hooks
onBeforeMount(() => {
  try {
    logoSettings.value = widgetStore.getLogoSettings()
    bubbleSettings.value = widgetStore.getBubbleSettings()
    accountsAgent.value = widgetStore.getAccountsAgent()
    userMessageStylingSettings.value = widgetStore.getUserMessageStylingSettings()
    mobileAppSettings.value = widgetStore.getMobileAppSettings()
    copyrightSettings.value = widgetStore.getCopyrightSettings()
    agentsLimitation.value = widgetStore.getPlanLimitations('agents')
    mobileAppLimitations.value = widgetStore.getPlanLimitations('mobile_app')
    brandingLimitation.value = widgetStore.getPlanLimitations('branding')
  } catch (error) {
    console.error(error)
  }
})

onMounted(async () => {
  try {
    isLoading.value = true

    isPWAInstalled.value = await PwaHelper.isPWAInstalled()
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }

  window.addEventListener('beforeinstallprompt', (event) => {
    // Prevent the mini-infobar from appearing on mobile
    event.preventDefault()

    // Save the event for later use
    installPromptEvent.value = event
  })
})

// Computed
const computedUrl = computed(() => {
  let url

  if (brandingLimitation.value && copyrightSettings.value?.url) {
    url = copyrightSettings.value?.url
  } else {
    url = 'https://insertchat.com'
  }

  return url + defaultUtm
})

const isAccountsEnabled = computed(
  () =>
    agentsLimitation.value?.includes('accounts') &&
    accountsAgent.value?.status &&
    widgetStore.getAccount()?.uid
)

const isMobileAppEnabled = computed(
  () => mobileAppLimitations.value && mobileAppSettings.value?.status && !isPWAInstalled.value
)

// Function
const onShare = async () => {
  try {
    WindowHelper.postMessage('click_share', {
      widget_uid: widgetStore.getWidget()?.uid,
      chat_uid: widgetStore.getChat()?.uid,
      account_uid: widgetStore.getAccount()?.uid,
    })

    modalShareProps.value.open = true
  } catch (error) {
    console.error(error)
  }
}

const onClose = () => {
  WindowHelper.postMessage('send_close_chat_bubble', {
    widget_uid: widgetStore.state.widget?.uid,
    chat_uid: widgetStore.state.chat?.uid,
    account_uid: widgetStore.state.account?.uid,
  })
}

const toggleSidebar = () => {
  widgetStore.setWidgetState('sidebarStatus', !widgetStore.state?.sidebarStatus)
}

const promptInstall = () => {
  // @ts-ignore
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream

  if (isIOS) {
    alert(
      '1. Tap on the Share icon at the bottom center.' +
        '\n' +
        '2. Scroll down and select "Add to Home Screen".' +
        '\n' +
        '3. Tap "Add" in the upper right corner.'
    )

    return
  }

  const event = installPromptEvent.value as any

  if (event) {
    event.prompt()

    event.userChoice.then((choiceResult: any) => {
      if (choiceResult.outcome === 'accepted') {
        console.info('User accepted the install prompt')
      } else {
        console.info('User dismissed the install prompt')
      }

      installPromptEvent.value = null
    })
  }
}
</script>

<template>
  <ChatShareModal
    v-if="modalShareProps?.open && modalShareProps.open === true"
    v-model:modalShareProps="modalShareProps"
    @close="modalShareProps.open = false"
  />

  <nav class="chat-navbar" aria-label="main navigation">
    <div
      v-if="isAccountsEnabled"
      class="button mr-2"
      :style="widgetStore.computedIconStyle"
      @keydown.space.prevent="toggleSidebar"
      @click="toggleSidebar"
    >
      <VIcon
        :icon="widgetStore.state.sidebarStatus ? 'ic:round-close' : 'ic:round-menu'"
        class="chat-icons-color"
      />
    </div>

    <VButton
      v-if="isMobileAppEnabled"
      :style="{
        background: userMessageStylingSettings?.background + '!important',
        color: userMessageStylingSettings?.color + '!important',
        width: 'auto',
        height: '30px',
        left: '0',
        padding: '0 10px',
      }"
      class="no-border mr-2"
      @click="promptInstall"
    >
      {{ mobileAppSettings.text }}
    </VButton>

    <a
      :href="computedUrl"
      target="_blank"
      class="logo"
      :class="{ 'ml-2': isAccountsEnabled || isMobileAppEnabled }"
    >
      <img
        v-if="logoSettings?.status"
        :src="StringHelper.getImageUrl(logoSettings?.url)"
        alt="Logo"
        @error.once="(event) => ViaPlaceholderHelper.onceImageErrored(event, '40x40')"
      />
    </a>

    <div
      v-if="isAccountsEnabled"
      class="button mr-2"
      :style="widgetStore.computedIconStyle"
      @click="emit('newChat')"
    >
      <VIcon icon="ic:round-add" class="chat-icons-color" />
    </div>

    <div
      v-if="widgetStore.state.signalController"
      class="button mr-2"
      :style="widgetStore.computedIconStyle"
      @click="widgetStore.onStop"
    >
      <VIcon :icon="ICONS.actions.stop" class="chat-icons-color" />
    </div>

    <div
      v-if="bubbleSettings?.status_share_button"
      class="button mr-2"
      :style="widgetStore.computedIconStyle"
      @click="onShare"
    >
      <VIcon :icon="ICONS.actions.share" class="chat-icons-color" />
    </div>

    <div
      class="button"
      :class="{ 'mr-2': computedIsBubble }"
      :style="widgetStore.computedIconStyle"
      @click="widgetStore.onReset"
    >
      <VIcon :icon="ICONS.actions.reset" class="chat-icons-color" />
    </div>

    <div
      v-if="computedIsBubble"
      class="button"
      :style="widgetStore.computedIconStyle"
      @click="onClose"
    >
      <VIcon :icon="ICONS.forms.close" class="chat-icons-color" />
    </div>
  </nav>
</template>
