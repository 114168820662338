<script setup lang="ts">
import { useHead } from '@vueuse/head'
import { onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { ChatbotHelper, HttpHelper } from '/@src/helpers'
import { IFilter, IPlan, ITab, IWidget } from '/@src/interfaces'
import Accounts from '/@src/pages/chatbots/accounts.vue'
import Agents from '/@src/pages/chatbots/agents.vue'
import Inbox from '/@src/pages/chatbots/inbox.vue'
import Integrations from '/@src/pages/chatbots/integrations.vue'
import Interface from '/@src/pages/chatbots/interface.vue'
import Playground from '/@src/pages/chatbots/playground.vue'
import Settings from '/@src/pages/chatbots/settings.vue'
import Training from '/@src/pages/chatbots/training.vue'
import { ICONS } from '/@src/resources/files/constant'
import { useApp, useViewWrapper } from '/@src/stores'

// Const data
const uid: any = useRoute().params?.uid ?? undefined
const filters: IFilter[] = [
  {
    key: 'widget_uid',
    operator: '=',
    value: uid,
  },
]
const url = `/widgets/${uid}?expand[0]=model`

// Reactive data
const tabs = ref<ITab[]>([
  {
    label: 'Playground',
    value: 'playground',
    icon: ICONS.pages.playground,
    component: Playground,
    class: 'p-0',
  },
  {
    label: 'Interface',
    value: 'interface',
    icon: ICONS.pages.interface,
    component: Interface,
    class: 'p-0',
  },
  {
    label: 'Settings',
    value: 'settings',
    icon: ICONS.pages.settings,
    component: Settings,
    class: 'p-0',
  },
  {
    label: 'Knowledge',
    value: 'knowledge',
    icon: ICONS.pages.training,
    component: Training,
  },
  {
    label: 'Automations',
    value: 'agents',
    icon: ICONS.pages.agents,
    component: Agents,
    class: 'p-0',
  },
  { label: 'Inbox', value: 'inbox', icon: ICONS.pages.inbox, component: Inbox, class: 'p-0' },
  {
    label: 'Accounts',
    value: 'accounts',
    icon: ICONS.pages.accounts,
    component: Accounts,
  },
  {
    label: 'Integrations',
    value: 'integrations',
    icon: ICONS.pages.integrations,
    component: Integrations,
    class: 'p-0',
  },
])
const title = ref('')
const isLoading = ref(true)
const selectedTab = ref(tabs.value[0]?.value)
const widget = ref<IWidget>({} as IWidget)
const plans = ref<IPlan[]>()
const addons = ref({})

// Composable
const router = useRouter()
const app = useApp()
const viewWrapper = useViewWrapper()
viewWrapper.setPageTitle(title.value)
viewWrapper.setPageIcon(ICONS.pages.chats)
useHead({ title: title.value })

// Lifecycle hooks
onMounted(async () => {
  try {
    isLoading.value = true

    const [plansResponse, widgetResponse] = await Promise.all([
      app.loadPlans('all'),
      HttpHelper.get(url),
    ])

    widget.value = { ...widgetResponse }
    plans.value = plansResponse?.plans
    addons.value = plansResponse?.addons
    title.value = widgetResponse?.label

    viewWrapper.setPageTitle(title.value)

    ChatbotHelper.loadChatbot(widget.value.uid)
  } catch (error) {
    console.error(error)

    await router.push({
      path: `/chatbots`,
    })
  } finally {
    isLoading.value = false
  }
})

watch(selectedTab, () => {
  title.value = selectedTab.value?.label

  viewWrapper.setPageTitle(title.value)
  viewWrapper.setPageIcon(ICONS.pages.chats)

  useHead({ title: title.value })
})
</script>

<template>
  <VLoader v-if="isLoading" card="smooth" />
  <VCard style="min-height: 650px" class="h-100 p-0" v-else>
    <VTabs
      v-model:selectedTab="selectedTab"
      :tabs="tabs"
      :vertical="true"
      :data="{
        widget,
        filters,
        plans,
        addons,
      }"
    >
    </VTabs>
  </VCard>
</template>
