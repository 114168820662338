<script setup lang="ts">
import { useEventBus } from '@vueuse/core'
import { computed, nextTick, onMounted, reactive, Reactive, ref, Ref, toRefs } from 'vue'
import { getNode } from '@formkit/core'
import { HttpHelper } from '/@src/helpers'
import { useUserSession } from '/@src/stores'

const emit = defineEmits(['update:modalFormProps', 'submit', 'close', 'ignore'])
const props = defineProps<{
  modalFormProps: {
    widgetUid: string
    updateUid?: string
    open: boolean
    type?: string
    question?: string
    answer?: string
    text?: string
    plans?: any
  }
}>()
const { widgetUid, updateUid, open, question, answer, plans } = toRefs(props.modalFormProps)

// Const data
const formId = 'formTraining'
const eventBus = useEventBus('refreshTrainingTable')

// Reactive data
const modalContainer = ref()
const trainingType: Ref<string> = ref('')
const isLoading = ref(false)
const isLocked = ref(false)
const formData: Ref<any> = ref({ training: {}, material: {} })
const formState: Reactive<any> = reactive({})
const provider = ref({})
const isFreeLimitation = ref()

// Computed
const title = computed((): string => (updateUid?.value ? 'Update knowledge' : 'Add knowledge'))

// Composable
const userSession = useUserSession()

// Lifecycle hooks
onMounted(async () => {
  Object.assign(formState, toRefs(getNode(formId)?.context?.state || {}))
  isFreeLimitation.value = userSession.getPlanLimitations('is_free')

  if (updateUid?.value) {
    isLoading.value = true
    isLocked.value = true

    try {
      const providerResponse = await HttpHelper.get(`/providers/${updateUid?.value}`)

      provider.value = providerResponse
      setTrainingType(providerResponse?.type)
    } catch (error) {
      console.error(error)
    } finally {
      isLoading.value = false
    }

    nextTick(() => {
      const formkitTabs = document.querySelector('.formkit-tabs') as HTMLElement

      if (formkitTabs) {
        formkitTabs.style.display = 'none'
      }
    })
  }

  if (question?.value || answer?.value) {
    setTrainingType('quizzes')
  }
})

// Functions
const setTrainingType = (type: string) => {
  trainingType.value = type

  formData.value.training = {
    ...formData.value.training,
    type: { value: type },
  }

  setTimeout(() => {
    navigate('goTo', 'material')
  }, 100)
}

const navigate = (action: string, stepName?: string) => {
  const node: any = getNode('multi-step')

  if (!node) {
    return
  }

  if (action === 'next' || action === 'previous') {
    node?.[action]()
  }

  if (action === 'goTo') {
    node?.goTo(stepName as string)
  }
}

const closeModal = (refresh = false) => {
  emit('update:modalFormProps', { open: false, refresh })
  userSession.clearAllIntervals(formId)
  !refresh && emit('close')
}

const onRefreshTrainingTable = () => {
  eventBus.emit()
}
</script>

<template>
  <CustomModal
    ref="modalContainer"
    size="big"
    :open="open"
    :title="title"
    :override-modal="false"
    :noscroll="false"
    :noclose="true"
    :disable-footer="true"
    @close="closeModal(true)"
  >
    <FormKit :id="formId" v-model="formData" type="form" :disabled="isLoading" :actions="false">
      <VLoader v-if="isLoading" :translucent="true" :opaque="true" />

      <FormKit
        v-else
        id="multi-step"
        name="training"
        type="multi-step"
        tab-style="progress"
        :allow-incomplete="false"
        class="w-100"
        :disabled="isLoading"
      >
        <!-- Type -->
        <FormKit
          v-if="!updateUid"
          id="type"
          :disabled="isLoading"
          type="step"
          name="type"
          label="Training type"
        >
          <FormKit type="hidden" name="value" validation="required:trim" />

          <TrainingTypeSelector @set-training-type="setTrainingType" />

          <!-- Previous & Next -->
          <template #stepNext="{}"> </template>
        </FormKit>

        <!-- Material -->
        <FormKit
          id="material"
          :disabled="isLoading"
          type="step"
          name="material"
          label="Training material"
        >
          <VMessage v-if="!isFreeLimitation && !provider?.uid" color="info" class="px-3">
            During training, we typically use 1 to 3 credits for each URL, document or file,
            depending on the content length, to enhance the raw data. The investment in this
            improvement is justified as we consistently witness a minimum 48% enhancement in the AI
            response effectiveness.
          </VMessage>

          <!-- URLs -->
          <div v-if="['urls', 'youtube'].includes(trainingType)">
            <TrainingUrls
              :widget-uid="widgetUid"
              :provider="provider"
              :training-type="trainingType"
              :plans="plans"
              @training="onRefreshTrainingTable"
              @synch="onRefreshTrainingTable"
            />
          </div>

          <!-- Files -->
          <div v-if="['files', 'audios'].includes(trainingType)">
            <TrainingFiles
              :widget-uid="widgetUid"
              :provider="provider"
              :training-type="trainingType"
              @training="onRefreshTrainingTable"
              @synch="onRefreshTrainingTable"
            />
          </div>

          <!-- Q&A -->
          <div v-if="['quizzes'].includes(trainingType)">
            <TrainingQuizzes
              :widget-uid="widgetUid"
              :provider="provider"
              :training-type="trainingType"
              :question="question"
              :answer="answer"
              @training="onRefreshTrainingTable"
              @synch="onRefreshTrainingTable"
            />
          </div>

          <!-- Texts -->
          <div v-if="['texts'].includes(trainingType)">
            <TrainingTexts
              :widget-uid="widgetUid"
              :provider="provider"
              :training-type="trainingType"
              @training="onRefreshTrainingTable"
              @synch="onRefreshTrainingTable"
            />
          </div>

          <!-- Products -->
          <div v-if="['products'].includes(trainingType)">
            <TrainingProducts
              :widget-uid="widgetUid"
              :provider="provider"
              :training-type="trainingType"
              @training="onRefreshTrainingTable"
              @synch="onRefreshTrainingTable"
            />
          </div>

          <!-- Cloud Storage -->
          <div v-if="['cloud_storage'].includes(trainingType)">
            <TrainingCloudStorage
              :widget-uid="widgetUid"
              :provider="provider"
              :training-type="trainingType"
              @training="onRefreshTrainingTable"
              @synch="onRefreshTrainingTable"
            />
          </div>

          <!-- Uploaded -->
          <TrainingMaterialList
            v-if="provider?.uid"
            :provider="provider"
            :training-type="trainingType"
            @training="onRefreshTrainingTable"
            @synch="onRefreshTrainingTable"
          />

          <!-- Previous & Next -->
          <template #stepPrevious="{ handlers }">
            <div v-if="!updateUid">
              <VButton
                :loading="isLoading"
                type="submit"
                color="primary"
                icon="ic:round-arrow-back"
                class="mr-3"
                @click.prevent="handlers.incrementStep(-1)()"
              >
                Previous
              </VButton>
            </div>
          </template>

          <template v-if="updateUid" #stepNext="{}"></template>
        </FormKit>
      </FormKit>
    </FormKit>
  </CustomModal>
</template>
