<script setup lang="ts">
import { computed, onMounted, PropType, reactive, ref, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { getNode } from '@formkit/core'
import { useNotyf } from '/@src/composable/useNotyf'
import { ChatbotHelper, CookiesHelper, HttpHelper } from '/@src/helpers'
import { IFilter, ITab } from '/@src/interfaces'
import Embed from '/@src/pages/embed/:uid.vue'
import { PURPOSES } from '/@src/resources/files/constant'
import { VOICES } from '/@src/resources/files/constant'
import formats from '/@src/resources/files/formats.json'
import languages from '/@src/resources/files/languages.json'
import lengths from '/@src/resources/files/lengths.json'
import styles from '/@src/resources/files/styles.json'
import timezones from '/@src/resources/files/timezones.json'
import tones from '/@src/resources/files/tones.json'
import { useUserSession } from '/@src/stores'

const props = defineProps({
  title: {
    type: String,
    default: () => '',
    required: false,
  },
  filters: {
    type: Array as PropType<IFilter[]>,
    default: () => [],
    required: false,
  },
  addons: {
    type: Object,
    default: () => {},
    required: true,
  },
})

// Composable
const route = useRoute()

// Const data
const formId = 'formSettings'
const widgetUid: string = Array.isArray(route?.params?.uid)
  ? route.params.uid[0]
  : route?.params?.uid
const tabs = ref<ITab[]>([
  { label: 'General', value: 'general', icon: 'solar:settings-linear' },
  { label: 'Persona', value: 'model', icon: 'hugeicons:artificial-intelligence-04' },
  { label: 'Greetings', value: 'greetings', icon: 'solar:hand-stars-outline' },
  { label: 'Writing', value: 'writing_style', icon: 'hugeicons:quill-write-01' },
  { label: 'Copyright', value: 'copyright', icon: 'ph:copyright' },
  // { label: 'E-mailing', value: 'emailing', icon: 'ic:round-alternate-email' },
  { label: 'Audio', value: 'audio_mode', icon: 'solar:volume-loud-broken' },
  { label: 'Vision', value: 'vision_mode', icon: 'hugeicons:vision' },
  { label: 'Access', value: 'access_control', icon: 'solar:lock-keyhole-outline' },
  { label: 'Code', value: 'custom_code', icon: 'solar:code-file-outline' },
  { label: 'Misc', value: 'advanced', icon: 'codicon:symbol-misc' },
])

// Reactive data
const isLoading = ref(false)
const formData: any = ref({})
const formState: any = reactive({})
const models: any = ref([])
const modelsLimitation = ref()
const brandingLimitation = ref()
const selectedTab = ref(tabs.value[0]?.value)

// Computed
const isFormDisabled = computed(() => {
  const isInvalid = !formState.valid

  if (isLoading.value || isInvalid) {
    return true
  }

  return false
})

const computedModels = computed(() => {
  const filteredModels = [...models.value].filter((model) => {
    if (model.is_text) {
      return model
    }
  })

  return computeModels(filteredModels)
})

const computedVisionModels = computed(() => {
  const filteredModels = [...models.value].filter((model) => {
    if (model.is_vision) {
      return model
    }
  })

  return computeModels(filteredModels)
})

// Composable
const notyf = useNotyf()
const { t } = useI18n()
const userSession = useUserSession()

// Lifecycle hooks
onMounted(async () => {
  try {
    Object.assign(formState, toRefs(getNode(formId)?.context?.state || {}))

    isLoading.value = true
    modelsLimitation.value = userSession.getPlanLimitations('models')
    brandingLimitation.value = userSession.getPlanLimitations('branding')

    const [widgetResponse, modelsResponse] = await Promise.all([
      HttpHelper.get(`/widgets/${widgetUid}`),
      HttpHelper.get(`/models`, { withoutApp: true }),
    ])

    models.value = [...modelsResponse]

    formData.value = {
      ...widgetResponse,
      ...(widgetResponse?.computed_settings || {}),
    }

    formData.value.timezone = widgetResponse?.timezone?.replace(/&#x2F;/g, '/')
    formData.value.is_vision = Boolean(widgetResponse?.model_vision_uid)

    formData.value.greetings =
      typeof widgetResponse?.greetings === 'string'
        ? JSON.parse(widgetResponse?.greetings)
        : widgetResponse?.greetings || []

    formData.value.suggestions =
      typeof widgetResponse?.suggestions === 'string'
        ? JSON.parse(widgetResponse?.suggestions)
        : widgetResponse?.suggestions || []

    formData.value.notifications =
      typeof widgetResponse?.notifications === 'string'
        ? JSON.parse(widgetResponse?.notifications)
        : widgetResponse?.notifications || []
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
})

// Functions
const triggerSubmit = () => {
  getNode(formId)?.submit()
}

const onSubmit = async (fields: any) => {
  try {
    if (isLoading.value) {
      return
    }

    isLoading.value = true

    const {
      greetings,
      suggestions,
      notifications,
      copyright,
      label,
      name,
      company,
      timezone,
      voice,
      context_behavior,
      prompt_system,
      temperature,
      password,
      model_uid,
      model_vision_uid,
      conversation_saver,
      source_discloser,
      writing_language,
      writing_tone,
      writing_style,
      writing_format,
      writing_length,
      code_head,
      code_body,
      hide_widget,
      hide_inbox,
      hide_urls,
      hide_filenames,
      is_vocal_only,
      is_vision,
      sender_name,
      sender_email,
      sender_signature,
    } = fields

    await HttpHelper.post('/widgets', widgetUid, {
      greetings,
      suggestions,
      notifications,
      copyright,
      label,
      name,
      company,
      timezone: timezone?.replace(/&#x2F;/g, '/'),
      voice,
      context_behavior,
      prompt_system,
      password,
      temperature,
      type: 'chat',
      model_uid,
      model_vision_uid: is_vision ? model_vision_uid : null,
      conversation_saver,
      source_discloser,
      writing_language,
      writing_tone,
      writing_style,
      writing_format,
      writing_length,
      code_head,
      code_body,
      hide_widget,
      hide_inbox,
      hide_urls,
      hide_filenames,
      is_vocal_only,
      sender_name,
      sender_email,
      sender_signature,
    })

    // CookiesHelper.remove(`chat_${widgetUid}`)
    ChatbotHelper.refreshChatbot(widgetUid)

    notyf.success(t('notifications.success'))
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
}

const computeModels = (models: any) => {
  return models.map((model: any) => {
    const { queries, uid, label, help, company, is_unfiltered, is_agent, is_connected, is_beta } =
      model
    const formattedLabel = `${label} ${company ? `by ${company}` : ''} - Consume ${queries} ${queries === 1 ? 'credit' : 'credits'}/answer`

    const modelData = {
      ...model,
      label: formattedLabel,
      value: uid,
      help,
      is_unfiltered,
      is_agent,
      is_connected,
      is_beta,
      attrs: modelsLimitation.value?.includes(model.value) ? {} : { disabled: true },
    }

    return modelData
  })
}
</script>

<template>
  <VLoader v-if="tabs && !tabs.length" class="h-100" />

  <VTabs v-else v-model:selected="selectedTab" :tabs="tabs" :vertical="true">
    <template #tab>
      <FormKit
        :id="formId"
        v-model="formData"
        type="form"
        :config="{ validationVisibility: 'live' }"
        :disabled="isLoading"
        :actions="false"
        :classes="{
          form: 'h-100',
        }"
        :preserve="true"
        :preserve-errors="true"
        @submit="onSubmit"
      >
        <!-- General -->
        <template v-if="selectedTab === 'general'">
          <CustomForm title="General" subtitle="">
            <template #buttons>
              <VButton
                :disabled="isFormDisabled"
                :loading="isLoading"
                type="submit"
                color="primary"
                icon="ic:round-check"
                @click="triggerSubmit"
              >
                Save Changes
              </VButton>
            </template>

            <template #body>
              <FormKit
                type="text"
                label="Chatbot name"
                name="label"
                validation="required:trim"
                placeholder="Chatbot #1"
              />

              <FormKit
                type="text"
                label="Company name"
                name="company"
                validation="trim"
                placeholder="Company"
              />

              <FormKit
                type="text"
                label="AI name"
                name="name"
                validation="trim"
                placeholder="Ava"
              />

              <FormKit
                type="select"
                label="AI Timezone"
                name="timezone"
                validation="trim"
                placeholder="Timezone"
                :options="timezones"
              />

              <FormKit
                name="voice"
                type="dropdown"
                label="AI Voice"
                :options="VOICES"
                :disabled="isLoading"
                placeholder="Default"
                :close-on-select="true"
                :classes="{ selectionWrapper: 'w-100', selection: 'w-100' }"
              >
                <template #option="{ option }">
                  <div class="formkit-option w-100">
                    <div class="text-container columns is-vcentered">
                      <div class="column p-0 is-4 has-text-left">
                        {{ option.label }}
                      </div>
                      <p class="option-overview column p-0 is-8 has-text-right">
                        <audio controls style="margin-bottom: -3px; height: 36px; width: 260px">
                          <source :src="option.help" />
                          Browser does not support audio element.
                          <track src="" kind="captions" />
                        </audio>
                      </p>
                    </div>
                  </div>
                </template>
              </FormKit>
            </template>
          </CustomForm>
        </template>

        <!-- Model -->
        <template v-if="selectedTab === 'model'">
          <CustomForm title="Persona" subtitle="">
            <template #buttons>
              <VButton
                :disabled="isFormDisabled"
                :loading="isLoading"
                type="submit"
                color="primary"
                icon="ic:round-check"
                @click="triggerSubmit"
              >
                Save Changes
              </VButton>
            </template>

            <template #body>
              <FormKit
                type="dropdown"
                label="Model (LLM)"
                placeholder="Select a model"
                validation="required:trim"
                name="model_uid"
                :options="computedModels"
                :close-on-select="true"
                popover
                :disabled="false"
              >
                <template #option="{ option, classes }">
                  <div :class="classes.option">
                    <div class="text-container">
                      <div>
                        <VTag v-if="option?.is_agent" class="mr-2" color="success">
                          <b>Support Agents</b>
                        </VTag>

                        <VTag v-if="option?.is_unfiltered" class="mr-2" color="danger">
                          <b>RolePlay Enhanced</b>
                        </VTag>

                        <VTag v-if="option?.is_connected" class="mr-2" color="purple">
                          <b>Internet Connected</b>
                        </VTag>

                        <VTag v-if="option?.is_beta" class="mr-2" color="warning">
                          <b>Beta</b>
                        </VTag>

                        {{ option.label }}
                      </div>
                    </div>
                  </div>
                </template>
              </FormKit>

              <FormKit
                type="togglebuttons"
                name="context_behavior"
                label="Limitation of the AI knowledge"
                enforced
                :options="{
                  answer_with_context: 'Provided Training Only',
                  chatgpt: 'Generic AI Knowledge',
                  answer_without_context: 'Provided Training + Generic AI Knowledge',
                }"
              />

              <FormKit
                type="textarea"
                label="AI persona"
                name="prompt_system"
                validation="trim"
                :placeholder="`Examples of GOOD AI personas:\n(1) As a representative of InsertChat, your role is to embody the values and strengths of our startup while promoting our product in the most favorable light. Avoid discussing competitors or making negative remarks about our company. Your focus should be on showcasing the excellence of InsertChat and its offerings.\n(2) Act like you are Elon Musk, the visionary entrepreneur and CEO of SpaceX and Tesla. Capture his unique blend of optimism, innovation, and occasional humor. Feel free to include references to current events or developments in the tech and space industries.`"
                :help="`The AI persona define the default behavior of your AI, to provide a more customized experience, allowing your chatbot to act in a manner that aligns with your specific requirements and preferences.`"
                :disabled="isLoading"
                rows="6"
              />

              <FormKit
                name="temperature"
                type="range"
                :label="`Creativity (${formData.temperature})`"
                min="0"
                max="1.5"
                step="0.1"
                help="Creativity controls randomness, so a low creativity is less random (deterministic), while a high creativity is more random. A higher creativity value, such as 1.5, can result in more unpredictable and diverse outputs, while a lower temperature value, such as 0.7, can produce a more precise and specific output."
                :disabled="isLoading"
              />
            </template>
          </CustomForm>
        </template>

        <!-- Greetings -->
        <template v-if="selectedTab === 'greetings'">
          <CustomForm title="Greetings" subtitle="">
            <template #buttons>
              <VButton
                :disabled="isFormDisabled"
                :loading="isLoading"
                type="submit"
                color="primary"
                icon="ic:round-check"
                @click="triggerSubmit"
              >
                Save Changes
              </VButton>
            </template>

            <template #body>
              <CustomForm title="Openers">
                <template #body>
                  <FormKit
                    v-slot="{ index: indexGreetings }"
                    type="repeater"
                    name="greetings"
                    :preserve="false"
                    :disabled="isLoading"
                    :up-control="false"
                    :down-control="true"
                    :add-button="true"
                    :insert-control="false"
                    :remove-control="true"
                    min="0"
                    max="6"
                  >
                    <FormKit
                      name="text"
                      type="textarea"
                      :label="`Opener ${Number(indexGreetings) + 1}`"
                      validation="trim"
                      placeholder="Hello! How can I help you today?"
                      :disabled="isLoading"
                    />
                  </FormKit>
                </template>
              </CustomForm>

              <CustomForm title="Suggestions">
                <template #body>
                  <FormKit
                    v-slot="{ index: indexSuggestions }"
                    type="repeater"
                    name="suggestions"
                    :up-control="false"
                    :down-control="true"
                    :add-button="true"
                    :insert-control="false"
                    :remove-control="true"
                    :disabled="isLoading"
                    min="0"
                    max="6"
                  >
                    <FormKit
                      type="text"
                      name="text"
                      :label="`Suggestion ${Number(indexSuggestions) + 1}`"
                      validation="trim"
                      placeholder="Suggestion"
                      :disabled="isLoading"
                    />
                  </FormKit>
                </template>
              </CustomForm>

              <CustomForm title="Bubble notifications">
                <template #body>
                  <FormKit
                    v-slot="{ index: indexNotifications }"
                    type="repeater"
                    name="notifications"
                    :up-control="false"
                    :down-control="true"
                    :add-button="true"
                    :insert-control="false"
                    :remove-control="true"
                    :disabled="isLoading"
                    min="0"
                    max="6"
                  >
                    <FormKit
                      type="text"
                      name="text"
                      :label="`Notification ${Number(indexNotifications) + 1}`"
                      validation="trim"
                      placeholder="Notification"
                      :disabled="isLoading"
                    />
                  </FormKit>
                </template>
              </CustomForm>
            </template>
          </CustomForm>
        </template>

        <!-- Copyright -->
        <template v-if="selectedTab === 'copyright'">
          <CustomForm title="Copyright" subtitle="">
            <template #buttons>
              <VButton
                :disabled="isFormDisabled"
                :loading="isLoading"
                type="submit"
                color="primary"
                icon="ic:round-check"
                @click="triggerSubmit"
              >
                Save Changes
              </VButton>
            </template>

            <template #body>
              <MarketingMessage
                v-if="!brandingLimitation"
                :plan="props.addons.branding"
                cta="Purchase Now"
              >
                Purchase the “Copyright” addon to unlock this feature!
              </MarketingMessage>

              <FormKit name="copyright" type="group" :disabled="isLoading">
                <FormKit
                  label="Status"
                  name="status"
                  type="toggle"
                  :off-value="false"
                  :on-value="true"
                  alt-label-position
                  value-label-display="inner"
                  off-value-label="OFF"
                  on-value-label="ON"
                  validation="required:trim"
                  :disabled="isLoading || !brandingLimitation"
                />

                <FormKit
                  name="text"
                  type="text"
                  label="Text"
                  validation="trim"
                  :disabled="isLoading || !brandingLimitation"
                />

                <FormKit
                  type="url"
                  name="url"
                  label="URL"
                  validation="trim|url"
                  :disabled="isLoading || !brandingLimitation"
                  placeholder="https://example.com"
                />
              </FormKit>
            </template>
          </CustomForm>
        </template>

        <!-- Audio Mode -->
        <template v-if="selectedTab === 'audio_mode'">
          <CustomForm title="Audio Mode" subtitle="">
            <template #buttons>
              <VButton
                :disabled="isFormDisabled"
                :loading="isLoading"
                type="submit"
                color="primary"
                icon="ic:round-check"
                @click="triggerSubmit"
              >
                Save Changes
              </VButton>
            </template>
            <template #body>
              <FormKit
                label="Disable the text input and let the users speak to the chatbot using only their microphones."
                name="is_vocal_only"
                type="toggle"
                :off-value="false"
                :on-value="true"
                label-position="secondary"
                value-label-display="inner"
                off-value-label="OFF"
                on-value-label="ON"
                validation="required:trim"
                :disabled="isLoading"
              />
            </template>
          </CustomForm>
        </template>

        <!-- Vision Mode -->
        <template v-if="selectedTab === 'vision_mode'">
          <CustomForm title="Vision Mode" subtitle="">
            <template #buttons>
              <VButton
                :disabled="isFormDisabled"
                :loading="isLoading"
                type="submit"
                color="primary"
                icon="ic:round-check"
                @click="triggerSubmit"
              >
                Save Changes
              </VButton>
            </template>
            <template #body>
              <FormKit
                label="Add the ability to the chatbot to download and understand images."
                name="is_vision"
                type="toggle"
                :off-value="false"
                :on-value="true"
                label-position="secondary"
                value-label-display="inner"
                off-value-label="OFF"
                on-value-label="ON"
                validation="required:trim"
                :disabled="isLoading"
              />

              <FormKit
                v-if="formData.is_vision"
                name="model_vision_uid"
                type="dropdown"
                label="Vision model"
                placeholder="Select a vision model"
                :options="computedVisionModels"
                help="You have the option to select from a variety of vision models."
                :disabled="false"
                :close-on-select="true"
              >
                <template #option="{ option, classes }">
                  <div :class="classes.option">
                    <div class="text-container">
                      <div>
                        <VTag v-if="option.is_vision" class="mr-2" color="blue">
                          <b>Vision</b>
                        </VTag>

                        <VTag v-if="option.is_connected" class="mr-2" color="purple">
                          <b>Internet-Connected</b>
                        </VTag>

                        <VTag v-if="option.is_beta" class="mr-2" color="warning">
                          <b>Beta</b>
                        </VTag>

                        {{ option.label }}
                      </div>
                    </div>
                  </div>
                </template>
              </FormKit>
            </template>
          </CustomForm>
        </template>

        <!-- Writing style -->
        <template v-if="selectedTab === 'writing_style'">
          <CustomForm title="Writing Style" subtitle="">
            <template #buttons>
              <VButton
                :disabled="isFormDisabled"
                :loading="isLoading"
                type="submit"
                color="primary"
                icon="ic:round-check"
                @click="triggerSubmit"
              >
                Save Changes
              </VButton>
            </template>
            <template #body>
              <VMessage color="info">
                Occasionally, the AI may disregard your writing style configuration.
              </VMessage>

              <FormKit
                type="dropdown"
                label="Language"
                name="writing_language"
                :options="languages"
                :disabled="isLoading"
                placeholder="Default"
                :close-on-select="true"
              />

              <FormKit
                type="dropdown"
                label="Tone"
                name="writing_tone"
                :options="tones"
                :disabled="isLoading"
                placeholder="Default"
                :close-on-select="true"
              />

              <FormKit
                type="dropdown"
                label="Style"
                name="writing_style"
                :options="styles"
                :disabled="isLoading"
                placeholder="Default"
                :close-on-select="true"
              />

              <FormKit
                type="dropdown"
                label="Format"
                name="writing_format"
                :options="formats"
                :disabled="isLoading"
                placeholder="Default"
                :close-on-select="true"
              />

              <FormKit
                type="dropdown"
                label="Max response length"
                name="writing_length"
                :options="lengths"
                :disabled="isLoading"
                placeholder="Default"
                :close-on-select="true"
              />
            </template>
          </CustomForm>
        </template>

        <!-- Emailing -->
        <template v-if="selectedTab === 'emailing'">
          <CustomForm title="E-mailing" subtitle="">
            <template #buttons>
              <VButton
                :disabled="isFormDisabled"
                :loading="isLoading"
                type="submit"
                color="primary"
                icon="ic:round-check"
                @click="triggerSubmit"
              >
                Save Changes
              </VButton>
            </template>

            <template #body>
              <MarketingMessage
                v-if="!brandingLimitation"
                :plan="props.addons.branding"
                cta="Purchase Now"
              >
                Purchase the “Copyright” addon to unlock this feature!
              </MarketingMessage>

              <FormKit
                type="text"
                label="Sender name"
                name="sender_name"
                validation="trim"
                :disabled="isLoading || !brandingLimitation"
                placeholder="InsertChat"
              />

              <FormKit
                type="email"
                label="Sender e-mail"
                name="sender_email"
                validation="trim|email"
                :disabled="isLoading || !brandingLimitation"
                placeholder="admin@insertchat.com"
              />

              <FormKit
                type="textarea"
                label="Sender signature"
                name="sender_signature"
                validation="trim"
                :disabled="isLoading || !brandingLimitation"
                placeholder="Sent by InsertChat"
              />
            </template>
          </CustomForm>
        </template>

        <!-- Access control -->
        <template v-if="selectedTab === 'access_control'">
          <CustomForm title="Access" subtitle="">
            <template #buttons>
              <VButton
                :disabled="isFormDisabled"
                :loading="isLoading"
                type="submit"
                color="primary"
                icon="ic:round-check"
                @click="triggerSubmit"
              >
                Save Changes
              </VButton>
            </template>

            <template #body>
              <InputPinCodeSimple
                v-model="formData.password"
                :digit-count="6"
                label="PIN protection"
                help="Utilizing a PIN will enhance the security of your chatbot URL by adding an additional layer of protection, ensuring that only authorized users with the correct PIN can access it. This helps safeguard the information and functionality provided by the chatbot, offering an extra level of control and privacy."
              />

              <FormKit
                label="Temporarily hide the chatbot on my website."
                name="hide_widget"
                type="toggle"
                :off-value="false"
                :on-value="true"
                label-position="secondary"
                value-label-display="inner"
                off-value-label="OFF"
                on-value-label="ON"
                validation="required:trim"
                :disabled="isLoading"
              />

              <FormKit
                label="Temporarily disable the inbox and history."
                name="hide_inbox"
                type="toggle"
                :off-value="false"
                :on-value="true"
                label-position="secondary"
                value-label-display="inner"
                off-value-label="OFF"
                on-value-label="ON"
                validation="required:trim"
                :disabled="isLoading"
              />
            </template>
          </CustomForm>
        </template>

        <!-- Custom Code -->
        <template v-if="selectedTab === 'custom_code'">
          <CustomForm title="Code" subtitle="">
            <template #buttons>
              <VButton
                :disabled="isFormDisabled"
                :loading="isLoading"
                type="submit"
                color="primary"
                icon="ic:round-check"
                @click="triggerSubmit"
              >
                Save Changes
              </VButton>
            </template>

            <template #body>
              <FormKit
                type="textarea"
                label="Code injected in the <head> tag of the page of your chatbot"
                name="code_head"
                validation="trim"
                :placeholder="`Your JS tags (Google Tag Manager, Google Analytics, Piwik, ...).\n\n<script>...</script>`"
                :help="formData?.code_head ? 'Include the <script> tags.' : ''"
              />

              <FormKit
                type="textarea"
                label="Code injected in the <body> tag of the page of your chatbot"
                name="code_body"
                validation="trim"
                :placeholder="`Noscript tags.\n\n<script>...</script>`"
                :help="formData?.code_body ? 'Include the <script> tags.' : ''"
              />
            </template>
          </CustomForm>
        </template>

        <!-- Advanced Settings -->
        <template v-if="selectedTab === 'advanced'">
          <CustomForm title="Miscellaneous" subtitle="">
            <template #buttons>
              <VButton
                :disabled="isFormDisabled"
                :loading="isLoading"
                type="submit"
                color="primary"
                icon="ic:round-check"
                @click="triggerSubmit"
              >
                Save Changes
              </VButton>
            </template>

            <template #body>
              <FormKit
                label="Ensure that the current chat remains open, even if you refresh the page or navigate to a different page."
                name="conversation_saver"
                type="toggle"
                :off-value="false"
                :on-value="true"
                label-position="secondary"
                value-label-display="inner"
                off-value-label="OFF"
                on-value-label="ON"
                validation="required:trim"
                :disabled="isLoading"
              />

              <FormKit
                label="Disclose the sources used to formulate the answers."
                name="source_discloser"
                type="toggle"
                :off-value="false"
                :on-value="true"
                label-position="secondary"
                value-label-display="inner"
                off-value-label="OFF"
                on-value-label="ON"
                validation="required:trim"
                :disabled="isLoading"
              />

              <FormKit
                name="hide_urls"
                type="toggle"
                label="Incorporate the URLs into the AI answers."
                :off-value="false"
                :on-value="true"
                label-position="secondary"
                value-label-display="inner"
                off-value-label="OFF"
                on-value-label="ON"
                validation="required:trim"
                :disabled="isLoading"
              />

              <FormKit
                name="hide_filenames"
                type="toggle"
                label="Incorporate the filenames into the AI answers."
                :off-value="false"
                :on-value="true"
                label-position="secondary"
                value-label-display="inner"
                off-value-label="OFF"
                on-value-label="ON"
                validation="required:trim"
                :disabled="isLoading"
              />
            </template>
          </CustomForm>
        </template>
      </FormKit>
    </template>

    <template #tab-2>
      <div class="column is-3">
        <VLoader v-if="isLoading" card="smooth" class="h-100" />

        <VCard v-else class="p-0 sticky-card">
          <Embed :key="selectedTab" class="radius-large" :widget-uid="widgetUid" height="600px" />
        </VCard>
      </div>
    </template>
  </VTabs>
</template>
