import StringHelper from './string'

export default class CookiesHelper {
  public static set = (
    name: string,
    value: string,
    expiry: number = 365,
    domain?: string
  ): string | undefined => {
    try {
      const _domain = domain
        ? domain
        : StringHelper.getDomainFromUrl({
            url: window.location.origin,
            includeSubdomain: true,
            includeProtocol: false,
          })

      const date = new Date()
      date.setTime(date.getTime() + expiry * 24 * 60 * 60 * 1000)

      document.cookie = `${name}=${value}; expires=${date.toUTCString()}; domain=${_domain}; path=/; SameSite=None; Secure;`

      try {
        localStorage?.setItem(name, value)
      } catch (error) {}

      return value
    } catch (error) {
      console.error(error)
    }
  }

  public static get = (name: string): string | null | undefined => {
    try {
      const _name = name + '='
      const split = document.cookie.split(';')

      for (let j = 0; j < split.length; j++) {
        let char = split[j]

        while (char.charAt(0) == ' ') {
          char = char.substring(1)
        }

        if (char.indexOf(_name) == 0) {
          return char.substring(_name.length, char.length)
        }
      }

      try {
        return localStorage?.getItem(name)
      } catch (error) {}
    } catch (error) {
      console.error(error)
    }
  }

  public static remove = (substring: string, domain?: string) => {
    const _domain = domain
      ? domain
      : StringHelper.getDomainFromUrl({
          url: window.location.origin,
          includeSubdomain: true,
          includeProtocol: false,
        })
    const cookies = document.cookie.split(';')

    // Delete cookies
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim().toLowerCase()

      if (cookie.indexOf(substring) !== -1) {
        const cookieName = cookie.split('=')[0]

        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${_domain}; path=/; SameSite=None; Secure;`
      }
    }

    try {
      localStorage?.removeItem(substring)
    } catch (error) {
      console.error(error)
    }
  }
}

// @ts-ignore
window.cookie_set = CookiesHelper.set

// @ts-ignore
window.cookie_get = CookiesHelper.get

// @ts-ignore
window.cookie_remove = CookiesHelper.remove
