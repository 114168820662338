<script setup lang="ts">
import { computed, nextTick, onBeforeMount, onMounted, Ref, ref, watch } from 'vue'
import { useNotyf } from '/@src/composable/useNotyf'
import { EventHelper, UrlHelper, WindowHelper } from '/@src/helpers'
import { IQueryParameters } from '/@src/interfaces/index'
import { ICONS } from '/@src/resources/files/constant'
import { useUserSession, useWidget } from '/@src/stores'

// Reactive data
const input: any = ref('')
const isLoading = ref(false)
const contentRef: any = ref(null)
const textAreaHeight = ref('auto')
const modalMarketingProps: any = ref({
  open: false,
  title: 'Upgrade your messaging capacity now and never miss important conversations',
  translationKey: 'errors.subscription/messages-limit-reached',
})
const bubbleSettings: any = ref({})
const recordingLimitation = ref()
const userMessageStylingSettings: any = ref({})
const uploadedFiles: Ref<{ file: File; blob_url: string }[]> = ref([])

// Composable
const notyf = useNotyf({
  duration: 1000,
  position: {
    x: 'center',
    y: 'bottom',
  },
})
const widgetStore = useWidget()
const userSession = useUserSession()

// Computed
const computedFocus = computed(() => {
  const { focus, botAutofocus }: IQueryParameters = UrlHelper.getWidgetQueryParams()

  return focus || botAutofocus
})

// Lifecycle hooks
onBeforeMount(() => {
  bubbleSettings.value = widgetStore.getBubbleSettings()
  userMessageStylingSettings.value = widgetStore.getUserMessageStylingSettings()
  recordingLimitation.value = widgetStore.getPlanLimitations('speech_to_text')
})

onMounted(async () => {
  try {
    nextTick(() => {
      if (computedFocus.value && contentRef.value) {
        // @ts-ignore
        contentRef.value?.focus()
      }
    })
  } catch (error) {
    console.error(error)
  }
})

// Watchers
watch(
  () => widgetStore.state.overrideContent,
  (newValue) => {
    input.value = newValue

    nextTick(() => {
      updateTextArea()
      contentRef.value?.focus()
    })
  }
)

// Functions
const onSubmit = async () => {
  try {
    if (isLoading.value) {
      return
    }

    isLoading.value = true
    const _content = input.value.trim()

    if (!_content) {
      return
    }

    input.value = ''

    nextTick(() => {
      updateTextArea()
    })

    // Create message
    try {
      WindowHelper.postMessage('submit_chat_message', {
        widget_uid: widgetStore.state.widget?.uid,
        chat_uid: widgetStore.state.chat?.uid,
        account_uid: widgetStore.state.account?.uid,
        message: _content,
        embed_url: widgetStore.getEmbedUrl(),
        share_url: widgetStore.getShareUrl(),
      })

      let filesResponse =
        uploadedFiles.value && uploadedFiles.value?.length
          ? await widgetStore.createFiles({
              type: 'image',
              files: uploadedFiles.value,
              transcribe: false,
            })
          : []

      uploadedFiles.value = []

      if (userSession && userSession?.user) {
        EventHelper.push('created_message')
      }

      await widgetStore.createMessage({
        files: filesResponse,
        role: 'user',
        input: _content,
        showTypingIndicator: true,
      })

      // Start onboarding after first message
      /*
      if (userSession?.user && !userSession?.user?.finished_onboarding_chat_page) {
        window.parent.postMessage({ action: 'start_onboarding_chatbot_page', data: {} }, '*')
        userSession.user.finished_onboarding_chat_page = true
      }
      */
    } catch (error: any) {
      if (error?.status === 426 || error?.message === 'Upgrade Required') {
        if (!widgetStore.getAccount()?.uid) {
          modalMarketingProps.value.open = true
        }

        widgetStore.setWidgetState('isUpgradeRequired', true)
      } else {
        notyf.error(error?.message)
      }
    }
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false

    nextTick(() => {
      if (contentRef.value) {
        // @ts-ignore
        contentRef.value?.focus()
      }
    })
  }
}
const onUpload = () => {
  const fileInput = document.createElement('input')
  fileInput.type = 'file'
  fileInput.accept = 'image/*'
  fileInput.multiple = true
  fileInput.click()

  fileInput.onchange = (event) => {
    // @ts-ignore
    const files = event?.target?.files
    const totalSelectedFiles = uploadedFiles.value.length + files.length

    if (totalSelectedFiles > 3) {
      alert('You can only have a total of 3 images selected!')
      return
    }

    if (files) {
      const validImages = [...files].filter((file) => file.size <= 5 * 1024 * 1024)

      validImages.forEach((file) => {
        if (uploadedFiles.value.length < 3) {
          uploadedFiles.value.push({ file, blob_url: URL.createObjectURL(file) })
        }
      })
    }
  }
}

const removeImage = (index: number) => {
  uploadedFiles.value.splice(index, 1)
}

const updateTextArea = () => {
  if (contentRef.value) {
    contentRef.value.style.height = 'auto'
    contentRef.value.style.height = `${contentRef.value.scrollHeight}px`
    textAreaHeight.value = `${contentRef.value.style.height}`
  }
}
</script>

<template>
  <MarketingModal v-if="modalMarketingProps.open" v-model="modalMarketingProps" />

  <div class="chat-textarea p-3 m-0 columns is-multiline is-mobile is-centered is-vcentered">
    <template v-if="!widgetStore.state.isChatBoxDisabled">
      <!-- Upload -->
      <div v-if="widgetStore.getWidget()?.model_vision_uid" class="column is-narrow pr-2 p-0">
        <div
          class="button chat-radius-rounded"
          aria-haspopup="true"
          :style="widgetStore.computedIconStyle"
          @click="onUpload"
        >
          <VIcon icon="ic:round-attach-file" class="chat-icons-color" />
        </div>
      </div>

      <!-- Text Mode -->
      <template v-if="!widgetStore.getWidget()?.is_vocal_only">
        <div class="column p-0 mr-2">
          <form @submit.prevent="onSubmit" @keydown.enter.exact="onSubmit">
            <textarea
              id="content_textarea"
              ref="contentRef"
              v-model="input"
              class="input driverjs-chatbot-textarea custom-textarea chat-radius-large"
              :style="{ height: textAreaHeight }"
              :disabled="isLoading"
              rows="1"
              :placeholder="bubbleSettings?.placeholder || ''"
              :aria-label="bubbleSettings?.placeholder || ''"
              @input="updateTextArea"
            />
          </form>
        </div>

        <!-- Submit -->
        <VButton
          v-if="input"
          :rounded="true"
          :raised="true"
          :style="{
            background: userMessageStylingSettings?.background + '!important',
            width: '33px',
            height: '33px',
          }"
          class="no-border driverjs-chatbot-send chat-icons-color pl-0 pr-0"
          :disabled="isLoading"
          @click="onSubmit"
        >
          <VIcon
            :icon="ICONS.actions.send"
            class="chat-icons-color chat-radius-rounded"
            :style="{
              'vertical-align': 'middle',
              'background': userMessageStylingSettings?.background + '!important',
              'color': userMessageStylingSettings?.color + '!important',
            }"
          />
        </VButton>

        <div v-else-if="bubbleSettings?.status_microphone_button" class="column p-0 is-narrow">
          <ChatVoiceButton />
        </div>
      </template>

      <!-- Voice Mode -->
      <div v-else class="column p-0 is-12 mt-3">
        <ChatVoiceButton />
      </div>

      <!-- Vision Mode -->
      <div v-if="uploadedFiles.length" class="column is-12 mt-3 p-0">
        <div class="images-preview-box p-2 m-0">
          <div class="columns is-12 is-mobile is-multiline is-centered is-vcentered">
            <div
              v-for="(src, index) in uploadedFiles"
              :key="index"
              class="column is-narrow is-relative pt-2 pb-1"
            >
              <img :src="src?.blob_url" alt="Image" :style="{ height: '60px' }" />

              <button class="delete-button" @click="removeImage(index)">X</button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
