import { RecordRTCPromisesHandler } from 'recordrtc'
import { useNotyf } from '/@src/composable/useNotyf'
import { HttpHelper } from '/@src/helpers'

const notyf = useNotyf()

export default class SttHelper {
  private recorder: RecordRTCPromisesHandler | null
  private stream: MediaStream | null
  public isRecording: boolean
  public isStopped: boolean
  public isPaused: boolean

  constructor() {
    this.recorder = null
    this.stream = null
    this.isRecording = false
    this.isStopped = true
    this.isPaused = false
  }

  public pauseRecording = async (): Promise<void> => {
    if (!this.recorder) {
      console.error('pauseRecording - Cannot stop recording: no recorder')

      return
    }

    await this.recorder.pauseRecording()

    this.isPaused = true
    this.isRecording = false
  }

  public resumeRecording = async (): Promise<void> => {
    if (!this.recorder) {
      console.error('resumeRecording - Cannot stop recording: no recorder')

      return
    }

    await this.recorder.resumeRecording()

    this.isPaused = false
    this.isRecording = true
  }

  public askForPermission = async (): Promise<boolean> => {
    try {
      await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
        },
      })

      return true
    } catch (error) {
      throw error
    }
  }

  public startRecording = async (): Promise<void> => {
    try {
      this.stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
        },
      })

      this.recorder = new RecordRTCPromisesHandler(this.stream, {
        type: 'audio',
        disableLogs: true,
        sampleRate: 48000, // Higher sample rate for better quality
        bufferSize: 256,
        numberOfAudioChannels: 2,
        mimeType: 'audio/wav', // Use WAV format for lossless quality
      })

      if (!this.stream || !this.recorder) {
        console.error('startRecording - Cannot stop recording: no recorder')

        this.isRecording = false
        this.isStopped = true

        return
      }

      this.recorder.startRecording()
      this.isRecording = true
      this.isStopped = false
    } catch (error: any) {
      this.isRecording = false
      this.isStopped = true

      console.error('startRecording', error)

      throw new Error(`Error starting recording: ${error?.message ?? error}`)
    }
  }

  public stopRecording = async (): Promise<Blob | undefined> => {
    if (!this.isRecording || !this.recorder) {
      console.error('stopRecording - Cannot stop recording: no recorder')

      this.isRecording = false
      this.isStopped = true

      return
    }

    try {
      await this.recorder.stopRecording()

      const blob = await this.recorder.getBlob()

      if (!blob || (blob && blob.size <= 100)) {
        notyf.info({
          message: 'Push-to-talk - Continue holding the button as you speak!',
          icon: false,
        })

        return
      }

      this.stream?.getTracks().forEach((track) => {
        track.stop()
      })

      this.recorder = null
      this.stream = null
      this.isRecording = false
      this.isStopped = true
      this.isPaused = false

      return blob
    } catch (error: any) {
      this.isRecording = false
      this.isStopped = true

      if (error && error.includes('Empty blob')) {
        notyf.info({
          message: 'Push-to-talk - Continue holding the button as you speak!',
          icon: false,
        })

        return
      } else {
        console.error(`Error stopping recording: ${error?.message ?? error}`)

        notyf.info({
          message: 'Push-to-talk - Continue holding the button as you speak!',
          icon: false,
        })

        return
      }
    }
  }

  public static transcribe = async (params: { widgetUid: string; blob: Blob }): Promise<string> => {
    const { widgetUid, blob } = params

    const formData = new FormData()
    formData.append('file', blob)

    try {
      const response = await HttpHelper.post(
        `/embeds/audio/transcribe/${widgetUid}`,
        null,
        formData,
        {
          withoutApp: true,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )

      return response?.text || ''
    } catch (error: any) {
      console.error('transcribe', error)

      throw new Error(`Error transcribing audio: ${error?.message ?? error}`)
    }
  }
}
