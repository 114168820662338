<route lang="yaml">
meta:
  requiresAuth: false
</route>

<template>
  <AuthLayout>
    <RouterView v-bind="$attrs" />
  </AuthLayout>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'

// Lifecycle hooks
onMounted(async () => {
  // Facebook SDK
  ;(function (d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0]
    if (d.getElementById(id)) {
      return
    }
    js = d.createElement(s)
    js.id = id
    // @ts-ignore
    js.src = 'https://connect.facebook.net/en_US/sdk.js'
    // @ts-ignore
    fjs.parentNode.insertBefore(js, fjs)
  })(document, 'script', 'facebook-jssdk')

  // Facebook init
  // @ts-ignore
  window.fbAsyncInit = function () {
    // @ts-ignore
    FB.init({
      appId: '3170207953273921',
      autoLogAppEvents: true,
      cookie: true,
      xfbml: true,
      version: 'v19.0',
    })

    // @ts-ignore
    FB.AppEvents.logPageView()
  }
})
</script>
