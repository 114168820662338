<script setup lang="ts">
import { useEventBus } from '@vueuse/core'
import { decodeHTML } from 'entities'
import {
  computed,
  nextTick,
  onBeforeUnmount,
  onMounted,
  reactive,
  Reactive,
  ref,
  Ref,
  toRefs,
} from 'vue'
import { useI18n } from 'vue-i18n'
import { getNode } from '@formkit/core'
import { useNotyf } from '/@src/composable/useNotyf'
import { HttpHelper, StringHelper } from '/@src/helpers'
import { TRAINING_FILTERS } from '/@src/resources/files/constant'
import { useUserSession } from '/@src/stores'

const emit = defineEmits<{
  (e: 'training'): void
  (e: 'synch'): void
}>()

const eventBus = useEventBus('refreshMaterialList')

const props = defineProps({
  provider: { type: Object, default: () => ({}), required: true },
})

const { provider } = toRefs(props)

// Const data
const formId = 'trainingMaterialList'

// Composable
const userSession = useUserSession()
const notyf = useNotyf()
const { t } = useI18n()

// Reactive data
const formState: Reactive<any> = reactive({})
const isLoading = ref(false)
const filterByStatus: Ref<'all' | 'trained' | 'other'> = ref('all')
const existingMaterial = ref([])
const isLastRequestInProgress = ref(false)
const cloudStorageTrainingLimitation = ref(false)
const queueLength = ref(0)

// Computed
const computedTraining = computed((): Array<any> => {
  return filterByStatus.value === 'all'
    ? existingMaterial?.value
    : existingMaterial?.value.filter(({ status }: { status: string }) => {
        if (filterByStatus.value === 'other') {
          return !['training_processing', 'training', 'trained', 'training_duplicate'].includes(
            status
          )
        }

        return filterByStatus.value === status
      })
})

const computedTrainingWithErrors = computed((): String[] => {
  return computedTraining?.value?.filter(
    ({ status }) =>
      !['training_processing', 'training', 'trained', 'training_duplicate'].includes(status)
  )
})

const computedTrainingWithDuplicates = computed((): String[] => {
  return computedTraining?.value?.filter(({ status }) => ['training_duplicate'].includes(status))
})

// Lifecycle hooks
onMounted(async () => {
  Object.assign(formState, toRefs(getNode(formId)?.context?.state || {}))

  isLoading.value = true
  isLastRequestInProgress.value = false
  cloudStorageTrainingLimitation.value = userSession.getPlanLimitations('cloud_storage_training')

  userSession.clearAllIntervals(formId)

  await refreshMaterial()

  isLoading.value = false

  userSession.setIntervals(formId, setInterval(refreshMaterial, 3000))
})

onBeforeUnmount(() => {
  isLastRequestInProgress.value = false
  userSession.clearAllIntervals(formId)
})

// Functions
const refreshMaterial = async () => {
  if (!provider.value?.uid || isLastRequestInProgress.value) {
    return
  }

  isLastRequestInProgress.value = true

  try {
    const providerResponse = await HttpHelper.get(`/providers/${provider.value?.uid}?training=true`)

    const newMaterial = providerResponse[providerResponse.type] ?? []

    queueLength.value = providerResponse?.queue_length || 0

    if (
      newMaterial.length !== existingMaterial.value.length ||
      JSON.stringify(newMaterial) !== JSON.stringify(existingMaterial.value)
    ) {
      existingMaterial.value = newMaterial

      emit('synch')
    }
  } catch (error) {
    console.error(error)
    userSession.clearAllIntervals(formId)
  } finally {
    isLastRequestInProgress.value = false
  }
}

const onDelete = async (item: any, index: number, uid: string | null) => {
  try {
    isLoading.value = true

    if (index > -1) {
      item.splice(index, 1)
      await nextTick()

      if (uid) {
        await HttpHelper.get(`/providers/delete-source/${uid}`)
        await refreshMaterial()
      }
    }
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
}

const onRefresh = async (items: any[]) => {
  try {
    const uids = []

    for (const item of items) {
      item.loading = true
      item.status = 'training_processing'
      uids.push(item?.uid)
    }

    await HttpHelper.post(`/providers/refresh`, null, {
      uids,
    })

    emit('synch')

    notyf.success('Retraining in progress. Please wait!')
  } catch (error) {
    console.error(error)
  } finally {
    isLastRequestInProgress.value = false
  }
}

const onFixErrors = async () => {
  if (!computedTrainingWithErrors.value || !Object.keys(computedTrainingWithErrors.value).length) {
    notyf.info('No errors found!')
    return
  }

  await onRefresh(computedTrainingWithErrors.value)
}

const onRemoveDuplicates = async () => {
  if (
    !computedTrainingWithDuplicates.value ||
    !Object.keys(computedTrainingWithDuplicates.value).length
  ) {
    notyf.info('No duplicates found!')
    return
  }

  try {
    for (const item of computedTrainingWithDuplicates.value) {
      // @ts-ignore
      item.loading = true
      // @ts-ignore
      item.status = 'training_deleting'
    }

    await HttpHelper.get(`/providers/delete-duplicates/${provider.value?.uid}`)
    await refreshMaterial()

    notyf.success('Deleting duplicates in progress. Please wait!')
  } catch (error) {
    console.error(error)
  } finally {
    isLastRequestInProgress.value = false
  }
}
</script>

<template>
  <CustomForm v-if="provider?.uid" title="Existing Knowledge">
    <template #buttons>
      <VButtons align="right">
        <VButton
          v-if="computedTrainingWithDuplicates && computedTrainingWithDuplicates.length"
          color="info"
          :disabled="false"
          :rounded="true"
          :lower="true"
          :loading="isLoading"
          icon="ic:round-cleaning-services"
          class="m-0 mr-3"
          @click="onRemoveDuplicates"
        >
          Erase Duplicates
        </VButton>

        <VButton
          v-if="computedTrainingWithErrors && computedTrainingWithErrors.length"
          color="info"
          :disabled="false"
          :rounded="true"
          :lower="true"
          :loading="isLoading"
          icon="ic:round-refresh"
          class="m-0 mr-3"
          @click="onFixErrors"
        >
          Fix Errors
        </VButton>

        <FormKit
          v-model="filterByStatus"
          type="select"
          :options="TRAINING_FILTERS"
          :classes="{ outer: 'm-0' }"
        />
      </VButtons>
    </template>

    <template #body>
      <template v-if="provider?.type === 'cloud_storage'">
        <VMessage color="danger" v-if="provider?.metadata?.warning">
          {{ provider?.metadata?.warning }}
        </VMessage>

        <VMessage color="info">
          Your cloud storage is fetched every 5 minutes to avoid wasting GET requests.
        </VMessage>
      </template>

      <div id="training-material-list" class="big-list">
        <VLoader v-if="!existingMaterial?.length" />

        <VMessage v-if="queueLength > 0" color="info">
          There is {{ queueLength }} item(s) in the training queue. The queue operates on a
          first-come, first-served basis. Please be patient, you will receive an email when your
          training is complete!
        </VMessage>

        <FormKit
          v-slot="{ value }"
          v-model="computedTraining"
          type="list"
          :preserve="false"
          :disabled="isLoading"
        >
          <div v-for="(item, index) of value" :key="index">
            <div v-if="item">
              <!-- URLs & Files -->
              <div
                v-if="
                  ['urls', 'youtube', 'files', 'audios', 'cloud_storage'].includes(provider?.type)
                "
                class="columns is-multiline"
              >
                <div class="column pl-0">
                  <input v-if="item?.uid" type="hidden" name="uid" :value="item?.uid" />

                  <div class="formkit-outer mb-0" style="position: relative">
                    <div class="formkit-wrapper">
                      <VTag
                        v-if="item?.status"
                        :color="t(`status.${item?.status}.color`)"
                        :label="t(`status.${item?.status}.label`)"
                        :loader="['training_processing', 'training'].includes(item?.status)"
                      />

                      {{ item?.url ?? item?.file_name }}
                    </div>
                  </div>
                </div>

                <div class="column is-narrow p-0 is-flex">
                  <FormKitControls
                    :is-loading="item?.loading"
                    :is-vertical="true"
                    :disable-insert="true"
                    :disable-refresh="
                      ['training_processing', 'training'].includes(item?.status) ? true : false
                    "
                    @delete="onDelete(existingMaterial, index, item?.uid)"
                    @refresh="onRefresh([item])"
                  />
                </div>
              </div>

              <!-- Quizzes -->
              <div v-if="['quizzes'].includes(provider?.type)" class="columns is-multiline">
                <div class="column is-11 pb-0 is-flex is-align-self-center">
                  <VTag
                    v-if="item?.status"
                    :color="t(`status.${item?.status}.color`)"
                    :label="t(`status.${item?.status}.label`)"
                    :loader="['training_processing', 'training'].includes(item?.status)"
                  />
                </div>

                <div
                  class="column is-1 pb-0 is-flex is-justify-content-flex-end is-align-self-right"
                >
                  <FormKitControls
                    :is-vertical="true"
                    :disable-insert="true"
                    @delete="onDelete(existingMaterial, index, item?.uid)"
                  />
                </div>

                <div class="column px-0 is-12">
                  <input v-if="item?.uid" type="hidden" name="uid" :value="item?.uid" />

                  <label class="formkit-label ml-2">Question(s)</label>
                  <div class="training-display">
                    <div v-for="(qItem, qIndex) of item?.questions" :key="`question-${qIndex}`">
                      - {{ qItem }}
                    </div>
                  </div>

                  <br />
                  <label class="formkit-label ml-2">Content</label>

                  <div class="training-display">
                    {{ decodeHTML(item?.content ?? '') }}
                  </div>
                </div>
              </div>

              <!-- Products -->
              <div v-if="['products'].includes(provider?.type)" class="columns is-multiline">
                <div class="column is-11 pb-0 is-flex is-align-self-center">
                  <VTag
                    v-if="item?.status"
                    :color="t(`status.${item?.status}.color`)"
                    :label="t(`status.${item?.status}.label`)"
                    :loader="['training_processing', 'training'].includes(item?.status)"
                  />
                </div>

                <div
                  class="column is-1 pb-0 is-flex is-justify-content-flex-end is-align-self-right"
                >
                  <FormKitControls
                    :is-vertical="true"
                    :disable-insert="true"
                    @delete="onDelete(existingMaterial, index, item?.uid)"
                  />
                </div>

                <div class="column px-0 is-12">
                  <input v-if="item?.uid" type="hidden" name="uid" :value="item?.uid" />

                  <div class="training-display">
                    <div class="columns">
                      <div class="column">
                        <template
                          v-for="(pItem, pIndex) in item?.product"
                          :key="`product-${pIndex}`"
                        >
                          <template
                            v-if="pItem && !['app_uid', 'image', 'images'].includes(pIndex)"
                          >
                            - {{ StringHelper.capitalizeFirstLetter(pIndex) }}:
                            {{ pItem }}
                            <br />
                          </template>
                        </template>
                      </div>

                      <div v-if="item?.product?.images" class="column is-narrow">
                        <template
                          v-for="(image, index) in item?.product?.images"
                          :key="`product-image-${index}`"
                        >
                          <img
                            :src="image"
                            :alt="item?.product?.name"
                            class="m-1"
                            style="height: 36px"
                          />
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Text -->
              <div v-if="['texts'].includes(provider?.type)" class="columns is-multiline">
                <div class="column is-11 pb-0 is-flex is-align-self-center">
                  <VTag
                    v-if="item?.status"
                    :color="t(`status.${item?.status}.color`)"
                    :label="t(`status.${item?.status}.label`)"
                    :loader="['training_processing', 'training'].includes(item?.status)"
                  />
                </div>

                <div
                  class="column is-1 pb-0 is-flex is-justify-content-flex-end is-align-self-center"
                >
                  <FormKitControls
                    :is-vertical="true"
                    :disable-insert="true"
                    @delete="onDelete(existingMaterial, index, item?.uid)"
                  />
                </div>

                <div class="column px-0 is-12">
                  <input v-if="item?.uid" type="hidden" name="uid" :value="item?.uid" />

                  <div class="training-display">
                    {{ decodeHTML(item?.content ?? '') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </FormKit>
      </div>
    </template>
  </CustomForm>
</template>

<style lang="scss">
@import '/@src/scss/abstracts/_mixins';
@import '/@src/scss/pages/profile/_user-profile';

.big-list {
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: var(--radius-large);
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}
</style>
