import hljs from 'highlight.js'
import { marked } from 'marked'
// @ts-ignore
import markedBidi from 'marked-bidi'
import { markedHighlight } from 'marked-highlight'
// @ts-ignore
// import extendedTables from 'marked-extended-tables'
// @ts-ignore
import { markedSmartypants } from 'marked-smartypants'
import { v4 as uuid } from 'uuid'

marked.use({
  gfm: true,
  breaks: true,
})
// marked.use(extendedTables())
marked.use(markedSmartypants())
marked.use(markedBidi())
marked.use(
  markedHighlight({
    langPrefix: 'hljs language-',
    highlight(code, lang) {
      const language = hljs.getLanguage(lang) ? lang : 'plaintext'

      return hljs.highlight(code, { language }).value
    },
  })
)
const renderer = new marked.Renderer()

renderer.link = function ({ href, title }) {
  if (!href) {
    return ''
  }

  if (href?.startsWith('mailto:')) {
    const email = href.substring(7) // Remove "mailto:" prefix
    return `<a target="_blank" href="${href}" title="${email}">${email}</a>`
  }

  const regexp =
    /(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()'@:%_\+.~#?!&//=]*))/gi
  const urls = href.match(regexp)
  const url = urls && urls.length ? urls[0] : ''
  const beforeText = url ? href.split(url)[0] : ''
  const afterText = url ? href.split(url)[1] : ''

  return `${beforeText}<a target="_blank" rel="nofollow" title="${
    title ?? url
  }" href="${url}">${url}</a>${afterText}`
}

export default class StringHelper {
  public static isValidUrl(url: string): boolean {
    try {
      const parsedUrl = new URL(url)

      return parsedUrl.protocol === 'http:' || parsedUrl.protocol === 'https:'
    } catch (error) {
      return false
    }
  }

  public static formatNumberOrPrice(
    value: number | string,
    isPrice: boolean = false
  ): string | undefined {
    if (!value && value !== 0) {
      return // Handle undefined or null values
    }

    let numericValue = typeof value === 'number' ? value : parseFloat(value as string)

    if (isNaN(numericValue)) {
      return 'Invalid input'
    }

    const suffixes = ['', 'K', 'M', 'B', 'T']

    let suffixIndex = 0

    while (numericValue >= 1000 && suffixIndex < suffixes.length - 1) {
      numericValue /= 1000
      suffixIndex++
    }

    const formattedValue = isPrice
      ? numericValue.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          currencyDisplay: 'symbol',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : numericValue % 1 === 0
        ? numericValue.toFixed(0)
        : numericValue.toFixed(1)

    return isPrice ? formattedValue + suffixes[suffixIndex] : formattedValue + suffixes[suffixIndex]
  }

  public static capitalizeFirstLetter(myString: string) {
    return myString?.charAt(0)?.toUpperCase() + myString?.slice(1)
  }

  public static getRandomString(maxCharCount: number = 36) {
    return uuid().substring(0, maxCharCount)
  }

  public static subString(myString: string, maxCharCount: number) {
    if (myString.length > maxCharCount) {
      return myString.substring(0, maxCharCount) + '...'
    }

    return myString
  }

  public static getImageUrl = (url: string) => {
    let image = ''

    if (url) {
      if (url.includes('http')) {
        image = url
      } else {
        image = import.meta.env.VITE_S3_PUBLIC + '/' + url
      }
    }

    return image
  }

  public static getDomainFromUrl(params: {
    url: string
    includeSubdomain: boolean
    includeProtocol: boolean
  }) {
    const { url, includeSubdomain, includeProtocol } = params

    const includePort = true

    let domain = ''
    let protocol = ''

    // Extract the protocol if required
    if (includeProtocol) {
      const matchProtocol = url.match(/^(https?:\/\/)/i)
      protocol = matchProtocol ? matchProtocol[1] : ''
    }

    // Remove the protocol if not required
    if (!includeProtocol) {
      domain = url.replace(/^(https?:\/\/)?/, '')
    }

    // Get the domain and port from the URL
    const matches = url.match(/^(?:https?:\/\/)?(?:www\.)?([^:/\n?]+)(?::(\d+))?/i)
    const port = matches && matches[2] ? `:${matches[2]}` : ''
    domain = matches ? matches[1] : ''

    // Remove subdomain if not required
    if (!includeSubdomain && domain !== null) {
      const domainParts = domain.split('.')
      if (domainParts.length > 2) {
        return protocol + domainParts.slice(1).join('.') + (includePort ? port : '')
      }
    }

    return protocol + domain + (includeProtocol && port ? port : '')
  }

  // Function to wrap a table element with a div and add classes
  public static wrapTableWithDiv(tableElement: HTMLElement) {
    const classes = [
      'table',
      'is-bordered',
      'is-fullwidth',
      'is-hoverable',
      'is-striped',
      'is-narrow',
    ]

    classes.forEach((item) => {
      tableElement.classList.add(item)
    })

    const div = document.createElement('div')
    div.className = 'table-container'

    const parent = tableElement.parentNode

    if (parent) {
      parent.replaceChild(div, tableElement)
      div.appendChild(tableElement)
    }
  }

  public static convertMdToHtml(text: string, settings?: { target: string }) {
    if (!text) {
      return ''
    }

    const tempElement = document.createElement('div')
    tempElement.innerHTML = marked(text, { renderer })

    const tableElements = tempElement.querySelectorAll('table')
    tableElements.forEach((tableElement) => {
      StringHelper.wrapTableWithDiv(tableElement)
    })

    if (settings && settings?.target) {
      const searchValue = settings && settings?.target === '_blank' ? '_parent' : '_blank'
      const regex = new RegExp(searchValue, 'g')
      tempElement.innerHTML = tempElement.innerHTML.replace(regex, settings?.target)
    }

    return tempElement.innerHTML
  }

  public static convertToVariableName(text: string) {
    const accentMap: any = {
      À: 'A',
      Á: 'A',
      Â: 'A',
      Ã: 'A',
      Ä: 'A',
      Å: 'A',
      à: 'a',
      á: 'a',
      â: 'a',
      ã: 'a',
      ä: 'a',
      å: 'a',
      È: 'E',
      É: 'E',
      Ê: 'E',
      Ë: 'E',
      è: 'e',
      é: 'e',
      ê: 'e',
      ë: 'e',
      Ì: 'I',
      Í: 'I',
      Î: 'I',
      Ï: 'I',
      ì: 'i',
      í: 'i',
      î: 'i',
      ï: 'i',
      Ò: 'O',
      Ó: 'O',
      Ô: 'O',
      Õ: 'O',
      Ö: 'O',
      Ø: 'O',
      ò: 'o',
      ó: 'o',
      ô: 'o',
      õ: 'o',
      ö: 'o',
      ø: 'o',
      Ù: 'U',
      Ú: 'U',
      Û: 'U',
      Ü: 'U',
      ù: 'u',
      ú: 'u',
      û: 'u',
      ü: 'u',
      Ý: 'Y',
      ý: 'y',
      ÿ: 'y',
      Ç: 'C',
      ç: 'c',
      Ñ: 'N',
      ñ: 'n',
    }

    let _text = text.trim()

    // Normalize the text to Unicode NFC form
    _text = _text.normalize('NFC')

    // Remove special characters, convert to lowercase, and replace accented characters
    _text = _text
      .replace(/[^\w\s]|./g, function (matched) {
        return accentMap[matched] || matched
      })
      .toLowerCase()

    // Replace non-letter characters with underscores
    _text = _text.replace(/[^a-zA-Z]+/g, '_')

    // Replace consecutive underscores with a single underscore
    _text = _text.replace(/^_+|_+$/g, '').replace(/_+/g, '_')

    return _text
  }
}
