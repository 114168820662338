<script setup lang="ts">
import { useEventBus } from '@vueuse/core'
import { decodeHTML } from 'entities'
import { onBeforeMount, PropType, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useNotyf } from '/@src/composable/useNotyf'
import { ChatbotHelper, CookiesHelper, DateHelper, HttpHelper } from '/@src/helpers'
import { IFilter } from '/@src/interfaces'
import { ICONS } from '/@src/resources/files/constant'

const emit = defineEmits(['update:modalFormProps', 'close'])
const props = defineProps({
  title: {
    type: String,
    default: () => '',
    required: false,
  },
  widget: {
    type: Object,
    default: () => {},
    required: true,
  },
  filters: {
    type: Array as PropType<IFilter[]>,
    default: () => [],
    required: false,
  },
  plans: {
    type: Object,
    default: () => {},
    required: true,
  },
})

// Composable
const { t } = useI18n()
const route = useRoute()
const notyf = useNotyf()

// Const data
const columns = {
  label: {
    label: 'Label',
    sortable: false,
    searchable: false,
    grow: false,
  },
  status: { label: 'Status', sortable: false, searchable: false, grow: false },
  fetch_every_hours: {
    label: 'Auto Refresh',
    sortable: false,
    searchable: false,
    grow: false,
  },
  created_at: {
    label: 'Creation date',
    sortable: false,
    searchable: false,
    grow: false,
  },
  last_synch_at: { label: 'Last refresh', sortable: false, searchable: false, grow: false },
  mode: {
    label: 'Mode',
    sortable: false,
    searchable: false,
    grow: false,
  },
  actions: {
    label: '',
    align: 'start',
    maxPixelWidth: '100px',
  },
} as const
const url = '/providers'
const eventBus = useEventBus(url)
const widgetUid: string = Array.isArray(route?.params?.uid)
  ? route.params.uid[0]
  : route?.params?.uid

// Reactive data
const modalFormProps: any = ref({ open: false, refresh: false, plans: props.plans })
const isLoading = ref(false)
const contextBehavior = ref('')
const metadata = ref()

// Lifecycle hooks
onBeforeMount(() => {
  contextBehavior.value = props.widget?.context_behavior
})

// Functions
const openFormModal = (row?: any) => {
  if (row && row?.uid) {
    modalFormProps.value.updateUid = row.uid
  }

  if (widgetUid) {
    modalFormProps.value.widgetUid = widgetUid
  }

  modalFormProps.value.open = true
}

const onChangeMode = async (providerUid: string, mode: boolean | undefined) => {
  if (mode === undefined) {
    return
  }

  await HttpHelper.post('/providers/change-mode', providerUid, {
    mode,
  })
}

const closeModal = (refresh = false) => {
  emit('update:modalFormProps', { open: false })

  if (!refresh) {
    emit('close')
  }
}

const onChangeContextBehavior = async (context_behavior: string) => {
  try {
    if (isLoading.value) {
      return
    }

    isLoading.value = true
    contextBehavior.value = context_behavior

    await HttpHelper.post('/widgets', widgetUid, {
      context_behavior,
    })

    CookiesHelper.remove(`chat_${widgetUid}`)
    ChatbotHelper.refreshChatbot(widgetUid)

    notyf.success(t('notifications.success'))
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
}

const refreshTable = () => {
  eventBus.emit(url)
}
</script>

<template>
  <FormTraining
    v-if="modalFormProps?.open && modalFormProps.open === true"
    v-model:modalFormProps="modalFormProps"
    @close="closeModal"
  />

  <CustomTable
    :refreshEveryMs="3000"
    v-model:metadata="metadata"
    v-model:override-loading="isLoading"
    :title="props.title"
    :columns="columns"
    :filters="props.filters"
    :url="url"
    @row-click="openFormModal"
  >
    <template #buttons>
      <VButtons align="right">
        <VButton color="primary" icon="ic:round-plus" @click.capture="openFormModal">
          Add knowledge
        </VButton>
      </VButtons>
    </template>

    <template #pinned>
      <VCard class="mb-3">
        <div class="columns is-vcentered">
          <div class="column"><b>Limitation of the AI knowledge</b></div>
          <div class="column is-narrow">
            <FormKit
              v-model="contextBehavior"
              type="togglebuttons"
              name="context_behavior"
              label=""
              enforced
              :options="{
                answer_with_context: 'Provided Training Only',
                answer_without_context: 'Provided Training + Generic AI Knowledge',
                chatgpt: 'Generic AI Knowledge',
              }"
              @input="onChangeContextBehavior"
            />
          </div>
        </div>
      </VCard>
    </template>

    <template #row="{ row }">
      <VFlexTableCell :column="columns.label">
        <div>
          <VTextEllipsis width="200px" class="item-name dark-text">
            {{ decodeHTML(row?.label ?? '') }}
          </VTextEllipsis>

          <VTextEllipsis class="light-text">
            {{ t('forms.provider.type.' + row?.type) }}
          </VTextEllipsis>
        </div>
      </VFlexTableCell>

      <VFlexTableCell :column="columns.status">
        <VTag
          :color="t('status.' + row?.sources_status + '.color')"
          :label="t('status.' + row?.sources_status + '.label')"
        />
      </VFlexTableCell>

      <VFlexTableCell :column="columns.fetch_every_hours">
        <VTag
          v-if="row?.fetch_every_hours > 0"
          color="success"
          :label="`Enabled (${row?.fetch_every_hours}h)`"
        />
        <VTag v-else color="info" label="Disabled" />
      </VFlexTableCell>

      <VFlexTableCell :column="columns.created_at">
        <span>{{ DateHelper.formatDate(row?.created_at, 'MM-dd-yyyy') }}</span>
      </VFlexTableCell>

      <VFlexTableCell :column="columns.last_synch_at">
        <span>{{ DateHelper.formatDate(row?.last_synch_at, 'MM-dd-yyyy hh:mm') }}</span>
      </VFlexTableCell>

      <VFlexTableCell :column="columns.created_at" @click.stop="() => {}">
        <FormKit
          v-model="row.mode"
          name="mode"
          label=""
          type="toggle"
          :off-value="false"
          :on-value="true"
          off-value-label="OFF"
          on-value-label="ON"
          value-label-display="inner"
          label-position="secondary"
          @input="(value) => onChangeMode(row?.uid, value)"
        />
      </VFlexTableCell>

      <VFlexTableCell :column="columns.actions" class="cell-end">
        <VButton :icon="ICONS.tables.open" class="pr-3 pl-3 mr-2"> Open </VButton>

        <CustomDropdown
          v-model:isLoading="isLoading"
          title="Actions"
          :actions="['edit', 'delete']"
          :reload-on-delete="false"
          :uid="row?.uid"
          :url="url"
          @edit="openFormModal"
          @refresh="refreshTable"
        />
      </VFlexTableCell>
    </template>
  </CustomTable>
</template>
