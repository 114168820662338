import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useNotyf } from '/@src/composable/useNotyf'
import {
  CookiesHelper,
  HttpHelper,
  ObjectHelper,
  PwaHelper,
  SleepHelper,
  UrlHelper,
  WindowHelper,
} from '/@src/helpers'
import { ChatbotHelper } from '/@src/helpers'
import {
  IAccount,
  IChat,
  IFile,
  IMessage,
  IModelResponse,
  IPlan,
  IQueryParameters,
  ISequenceMessage,
  ISubscription,
  ISuggestion,
  IUsage,
  IWidget,
  TypeRole,
} from '/@src/interfaces'
import { TYPING_INDICATOR_VISIBILITY } from '/@src/resources/files/constant'

interface WidgetState {
  sidebarStatus: boolean
  usage: IUsage | undefined
  subscription: ISubscription | undefined
  plan: Partial<IPlan>
  widget: IWidget | undefined
  chat: IChat | undefined
  account: IAccount | undefined
  selectedConversationUid: string
  chats: IChat[]
  messages: IMessage[]
  suggestions: ISuggestion[]
  isChatBoxDisabled: boolean
  isWidgetDisabled: boolean
  isWidgetLocked: boolean
  isWidgetLoginRequired: boolean
  isUpgradeRequired: boolean
  overrideContent: string
  messagesQueue: ISequenceMessage[]
  signalController: AbortController | null
  dynamicUser: Record<string, any>
  dynamicContext: string
  dynamicQuestion: string
  dynamicSystemBehavior: string
  isResolved: boolean
  isEmailed: boolean
  brandingPreview: Record<string, any>
}

export const useWidget = defineStore('widget', () => {
  const domain = ChatbotHelper.getDomain()
  const {
    welcomeMessage,
    botOverrideOpener,
    userId,
    userEmail,
    userFirstname,
    userLastname,
    userTags,
    userMetadata,
  }: IQueryParameters = UrlHelper.getWidgetQueryParams()
  const notyf = useNotyf()
  const state = ref<WidgetState>({
    sidebarStatus: false,
    usage: undefined,
    subscription: undefined,
    plan: {},
    widget: undefined,
    chat: undefined,
    account: undefined,
    selectedConversationUid: '',
    chats: [],
    messages: [],
    suggestions: [],
    isChatBoxDisabled: false,
    isWidgetDisabled: false,
    isWidgetLocked: false,
    isWidgetLoginRequired: false,
    isUpgradeRequired: false,
    overrideContent: '',
    messagesQueue: [],
    signalController: null,
    dynamicUser: {},
    dynamicContext: '',
    dynamicQuestion: '',
    dynamicSystemBehavior: '',
    isResolved: false,
    isEmailed: false,
    brandingPreview: {},
  })

  // Getters
  const getters = {
    getCookieName: (type: 'chat' | 'contact' | 'account') => `${type}_${state.value.widget?.uid}`,
    getPlanLimitations: (limitationKey: string) => {
      const limitations = state.value.plan?.limitations as { [key: string]: any } | undefined

      if (limitations && limitationKey in limitations) {
        return limitations[limitationKey]
      } else {
        console.error(`Undefined limitation key: ${limitationKey}`)
      }
    },
    getState: <K extends keyof typeof state.value>(key: K) => state.value[key],
    getWidget: (): IWidget => state.value.widget || ({} as IWidget),
    getWidgetSettings: (): IWidget['computed_settings'] =>
      state.value.widget?.computed_settings || ({} as IWidget['computed_settings']),
    getWidgetAgents: (): IWidget['computed_agents'] =>
      state.value.widget?.computed_agents || ({} as IWidget['computed_agents']),
    getChat: (): IChat => state.value.chat || ({} as IChat),
    getAccount: (): IAccount => state.value.account || ({} as IAccount),
    getCopyrightSettings: () => getters.getWidgetSettings()?.copyright || {},
    getMobileAppSettings: () => getters.getWidgetSettings()?.mobile_app || {},
    getBubbleSettings: () => getters.getWidgetSettings()?.bubble || {},
    getWelcomeSettings: () => getters.getWidgetSettings()?.welcome_screen || {},
    getWelcomeTitleSettings: () => getters.getWidgetSettings()?.welcome_screen?.title || {},
    getWelcomeDescriptionSettings: () =>
      getters.getWidgetSettings()?.welcome_screen?.description || {},
    getWelcomeCardSettings: () => getters.getWidgetSettings()?.welcome_screen?.cards || {},
    getWelcomeCardStylingSettings: () =>
      getters.getWidgetSettings()?.welcome_screen?.cards?.styling || {},
    getWelcomeCardItemsSettings: () => getters.getWidgetSettings()?.welcome_screen?.items || [],
    getAllStylingSettings: () => getters.getWidgetSettings()?.styling || {},
    getWindowStylingSettings: () => getters.getWidgetSettings()?.styling?.window || {},
    getUserMessageStylingSettings: () => getters.getWidgetSettings()?.styling?.user_message || {},
    getAssistantMessageStylingSettings: () =>
      getters.getWidgetSettings()?.styling?.assistant_message || {},
    getAllImagesSettings: () => getters.getWidgetSettings()?.images || {},
    getLogoSettings: () => getters.getWidgetSettings()?.images?.logo || {},
    getFaviconSettings: () => getters.getWidgetSettings()?.images?.favicon || {},
    getUserAvatarSettings: () => getters.getWidgetSettings()?.images?.user_avatar || {},
    getAssistantAvatarSettings: () => getters.getWidgetSettings()?.images?.assistant_avatar || {},
    getSmartSuggestionsAgent: () => state.value.widget?.computed_agents?.smart_suggestions || {},
    getRequestHumanAgent: () => state.value.widget?.computed_agents?.request_human || {},
    getMarkAsResolvedAgent: () => state.value.widget?.computed_agents?.mark_as_resolved || {},
    getTranscribeAgent: () => state.value.widget?.computed_agents?.transcribe || {},
    getCaptureLeadAgent: () => state.value.widget?.computed_agents?.capture_lead || {},
    getQuizzesAgent: () => state.value.widget?.computed_agents?.question_answer || {},
    getSequencesAgent: () => state.value.widget?.computed_agents?.sequences || {},
    getButtonsAgent: () => state.value.widget?.computed_agents?.buttons || {},
    getAccountsAgent: () => state.value.widget?.computed_agents?.accounts || {},
    computedIconStyle: computed(() => ({
      width: '26px',
      height: '26px',
      background: 'var(--chat-bg-color)',
      color: 'var(--chat-icons-color)',
      border: 'none !important',
      // border: '1px solid var(--chat-border-color) !important',
      // borderRadius: 'var(--chat-radius-rounded)',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '11px',
      cursor: 'pointer',
    })),
    getDynamicStylesByObject: (cssObj: any, keys?: string[]) => {
      const styles: any = {}

      if (cssObj && (cssObj?.status || typeof cssObj?.status === 'undefined')) {
        const allKeys = ['color', 'background', 'size', 'font', 'width', 'height']
        const styleKeys = keys || allKeys

        if (styleKeys.includes('color') && cssObj.color) styles.color = `${cssObj.color} !important`
        if (styleKeys.includes('background') && cssObj.background)
          styles.backgroundColor = `${cssObj.background} !important`
        if (styleKeys.includes('size') && cssObj.size)
          styles.fontSize = `${cssObj.size}px !important`
        if (styleKeys.includes('font') && cssObj.font)
          styles.fontFamily = `${cssObj.font} !important`
        if (styleKeys.includes('width') && cssObj.width)
          styles.width = `${cssObj.width}px !important`
        if (styleKeys.includes('height') && cssObj.height)
          styles.height = `${cssObj.height}px !important`
      } else {
        styles.display = 'none !important'
      }

      return styles
    },
    getEmbedUrl: () => `${domain}/embed/${state.value.widget?.uid}`,
    getShareUrl: () => `${domain}/embed/${state.value.widget?.uid}?share=${state.value.chat?.uid}`,
  }

  // Setters
  function setWidgetState<K extends keyof typeof state.value>(
    key: K,
    value: (typeof state.value)[K]
  ) {
    state.value[key] = value
  }

  function setSelectedConversationUid(data: string) {
    if (data) {
      setWidgetState('selectedConversationUid', data)

      if (state.value.widget?.conversation_saver) {
        CookiesHelper.set(getters.getCookieName('chat'), data, 365)
      }
    } else {
      const lastChatUid = state.value.chats?.[state.value.chats.length - 1]?.uid

      if (lastChatUid) {
        setWidgetState('selectedConversationUid', lastChatUid)
      }
    }
  }

  function setDynamicUser(data: string | null) {
    setWidgetState('dynamicUser', data && ObjectHelper.isJSON(data) ? JSON.parse(data) : {})
  }

  // Functions
  /**
   * Misc
   */
  async function removeAllCookies() {
    CookiesHelper.remove(getters.getCookieName('chat'))
    CookiesHelper.remove(getters.getCookieName('contact'))
  }

  /**
   * Accounts
   */
  async function register(fields: object) {
    await HttpHelper.post(
      '/embeds/accounts/register',
      null,
      { ...fields, widget_uid: state.value.widget?.uid },
      { withoutApp: true }
    )

    WindowHelper.postMessage('submit_account_register', {
      widget_uid: state.value.widget?.uid,
      chat_uid: state.value.chat?.uid,
      account_uid: state.value.account?.uid,
    })
  }

  async function login(token: string) {
    if (!state.value.widget) {
      throw new Error("Can't login before loading the widget")
    }

    const accountCookieName = getters.getCookieName('account')
    const savedToken = token || CookiesHelper.get(accountCookieName)

    if (!savedToken) {
      return false
    }

    const savedAccount = await HttpHelper.post(
      '/embeds/accounts/login',
      null,
      {
        token: savedToken,
        widget_uid: state.value.widget?.uid,
      },
      { withoutApp: true }
    )

    if (savedAccount?.token) {
      CookiesHelper.set(accountCookieName, savedAccount?.token, 365)

      setWidgetState('account', savedAccount?.account)
      setWidgetState('chats', savedAccount?.chats)
      setWidgetState('isChatBoxDisabled', false)
      setWidgetState('isWidgetLoginRequired', false)

      WindowHelper.postMessage('submit_account_login', {
        widget_uid: state.value.widget?.uid,
        chat_uid: state.value.chat?.uid,
        account_uid: state.value.account?.uid,
      })

      return true
    }

    // Ask for login
    setWidgetState('isWidgetLoginRequired', true)

    return false
  }

  async function addConversation() {
    try {
      await createChat()

      return await loadChatsFromAccount()
    } catch (error) {
      console.error(error)
    }
  }

  async function loadChatsFromAccount() {
    const savedToken = CookiesHelper.get(getters.getCookieName('account'))

    if (!savedToken) {
      return false
    }

    const chats = await HttpHelper.post(
      '/embeds/accounts/get-chats',
      null,
      {
        token: savedToken,
        widget_uid: state.value.widget?.uid,
      },
      { withoutApp: true }
    )

    setWidgetState('chats', chats)

    return chats
  }

  async function deleteChatFromAccount(chatUid: string, index: number) {
    const savedToken = CookiesHelper.get(getters.getCookieName('account'))

    if (!savedToken) {
      return false
    }

    state.value.chats.splice(index, 1)

    await HttpHelper.post(
      '/embeds/accounts/destroy-chat',
      null,
      {
        widget_uid: state.value.widget?.uid,
        chat_uid: chatUid,
        token: savedToken,
      },
      { withoutApp: true }
    )

    if (Object.keys(state.value.chats).length) {
      await loadChat(state.value.chats[0]?.uid ?? '')
    }
  }

  /**
   * Chats
   */
  function resetChat() {
    setWidgetState('chat', undefined)
    setWidgetState('messages', [])
    setWidgetState('overrideContent', '')
    setWidgetState('messagesQueue', [])
    setWidgetState('suggestions', [])
    setWidgetState('signalController', null)
    setWidgetState('dynamicContext', '')
    setWidgetState('dynamicQuestion', '')
    setWidgetState('dynamicSystemBehavior', '')
    setWidgetState('isResolved', false)
    setWidgetState('isEmailed', false)
    setWidgetState('brandingPreview', {})

    CookiesHelper.remove(getters.getCookieName('chat'))
  }

  async function createChat(widgetUid?: string) {
    const computedWidgetUid = widgetUid || state.value.widget?.uid

    // Reset chat first
    resetChat()

    const opener = botOverrideOpener ?? welcomeMessage

    const url = opener
      ? `/embeds/chats?welcome_message=${encodeURIComponent(opener)}`
      : '/embeds/chats'

    const response = await HttpHelper.post(
      url,
      null,
      {
        widget_uid: computedWidgetUid,
        account_uid: state.value.account?.uid,
        metadata: {
          user: {
            id: userId,
            email: userEmail,
            firstname: userFirstname,
            lastname: userLastname,
            tags: userTags,
            metadata: userMetadata,
          },
        },
      },
      { withoutApp: true }
    )

    await loadChat(response)

    if (response?.suggestions && Object.keys(response?.suggestions).length) {
      setWidgetState('suggestions', response?.suggestions)
    }

    return response
  }

  async function loadChat(chat: string | object) {
    // Reset chat first
    resetChat()

    // Determine if chat is a UID string or an object with the response from createChat
    try {
      const response =
        typeof chat === 'string'
          ? await HttpHelper.get(`/embeds/chats/${chat}`, { withoutApp: true })
          : chat

      setWidgetState('chat', response)

      if (response?.computed_messages && Object.keys(response?.computed_messages).length) {
        for (const message of response?.computed_messages) {
          state.value.messages.push(message)
        }
      }

      const allWelcomeMessages = response?.computed_messages.every(
        (message) => message?.is_welcome_message === true
      )

      if (
        allWelcomeMessages &&
        response?.suggestions &&
        Object.keys(response?.suggestions).length
      ) {
        setWidgetState('suggestions', response?.suggestions)
      }

      if (state.value.chat?.uid) {
        setSelectedConversationUid(state.value.chat?.uid)
      }

      return response
    } catch (error) {
      console.error(error)

      const response = await createChat(state.value.widget?.uid)

      return response
    }
  }

  /**
   * Agents
   */
  // TODO: OPTIMIZE
  async function autoStartAgents() {
    const sequences = getters.getSequencesAgent()
    const captureLead = getters.getCaptureLeadAgent()
    const accounts = getters.getAccountsAgent()
    const hasAgents = getters.getPlanLimitations('agents')

    if (state.value.messages.length > 0) {
      return
    }

    if (hasAgents) {
      if (hasAgents.includes('accounts') && accounts?.status && accounts?.is_open) {
        setWidgetState('isChatBoxDisabled', true)
        setWidgetState('isWidgetLoginRequired', true)

        await login('')
      } else if (
        hasAgents.includes('capture_lead') &&
        captureLead?.status &&
        captureLead?.is_open
      ) {
        // const contactUid = CookiesHelper.get(getters.getCookieName('contact'))

        setWidgetState('isChatBoxDisabled', true)
        await onAgentClick({ ...captureLead, prompt: null, text_button: null })
      } else if (hasAgents.includes('sequences')) {
        const openSequence = Object.keys(sequences).length
          ? sequences?.find((sequence: any) => sequence.status && sequence.is_open)
          : []

        if (openSequence) {
          setWidgetState('isChatBoxDisabled', false)
          await onAgentClick(openSequence)

          CookiesHelper.set(
            `sequence_${state.value.widget?.uid}_${openSequence?.uid}`,
            JSON.stringify({ uid: openSequence?.uid }),
            365
          )
        }
      }
    }
  }

  async function onAgentClick(agent) {
    setWidgetState('overrideContent', '')

    const widget = getters.getWidget()
    const chat = getters.getChat()
    const account = getters.getAccount()

    const data = {
      widget_uid: widget?.uid,
      chat_uid: chat?.uid,
      account_uid: account?.uid,
      embed_url: getters.getEmbedUrl(),
      share_url: getters.getShareUrl(),
    }

    switch (agent?.name) {
      case 'capture_lead':
        WindowHelper.postMessage('click_lead_form', data)

        await createMessage({
          role: 'user',
          agentChoice: 'capture_lead',
          input: agent?.prompt ?? agent?.text_button,
          showTypingIndicator: true,
        })
        break

      case 'transcribe':
        WindowHelper.postMessage('click_transcribe', data)

        await createMessage({
          role: 'user',
          agentChoice: 'transcribe',
          input: agent?.prompt ?? agent?.text_button,
          showTypingIndicator: true,
        })
        break

      case 'question_answer':
        WindowHelper.postMessage('click_question_answer', data)

        await createMessage({
          role: 'user',
          disableAI: !agent?.ai_answer,
          input: agent?.prompt ?? agent?.text_button,
          showTypingIndicator: true,
        })

        if (!agent?.ai_answer) {
          await createMessage({
            role: 'assistant',
            input: agent?.answer,
          })
        }
        break

      case 'sequences':
        WindowHelper.postMessage('click_sequence', data)

        setWidgetState('messagesQueue', [...agent?.messages])
        await processMessagesQueue()
        break

      case 'request_human':
        WindowHelper.postMessage('click_talk_to_human', data)

        await createMessage({
          role: 'user',
          agentChoice: 'request_human',
          input: agent?.prompt ?? agent?.text_button,
          showTypingIndicator: true,
        })
        break

      case 'accounts':
        setWidgetState('isWidgetLoginRequired', true)

        if (widget?.uid) {
          await login('')
        }
        break

      case 'buttons':
        const { type, text, url, starter_text } = agent
        const isPWAInstalled = await PwaHelper.isPWAInstalled()

        const openUrl = (url: string, appProtocol: string, webUrl: string) => {
          const fullUrl = isPWAInstalled
            ? `${appProtocol}${url}&text=${starter_text}`
            : `${webUrl}${url}?text=${starter_text}`

          window.open(fullUrl, '_blank')
        }

        switch (type) {
          case 'url':
            WindowHelper.postMessage('click_button_url', { url, ...data })
            return window.open(url, '_blank')

          case 'embed':
            WindowHelper.postMessage('click_button_embed', { url, ...data })

            await createMessage({
              role: 'assistant',
              input: `<iframe src="${url}" width="350" height="500" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>`,
              showTypingIndicator: true,
            })
            return

          case 'whatsapp':
            openUrl(url, 'whatsapp://send?phone=', 'https://wa.me/')
            WindowHelper.postMessage('click_button_whatsapp', { whatsapp: true, ...data })
            break

          case 'telegram':
            openUrl(url, 'tg://resolve?domain=', 'https://t.me/')
            WindowHelper.postMessage('click_button_telegram', { telegram: true, ...data })
            break

          case 'messenger':
            openUrl(url, 'fb-messenger://user/', 'https://www.messenger.com/t/')
            WindowHelper.postMessage('click_button_messenger', { messenger: true, ...data })
            break
        }
        break

      case 'mark_as_resolved':
        await createMessage({
          role: 'user',
          agentChoice: 'mark_as_resolved',
          input: agent?.prompt ?? agent?.text_button,
          showTypingIndicator: true,
        })
        break
    }
  }

  /**
   * Widget
   */
  function resetWidget() {
    setWidgetState('sidebarStatus', false)
    setWidgetState('usage', undefined)
    setWidgetState('subscription', undefined)
    setWidgetState('plan', {})
    setWidgetState('widget', undefined)
    setWidgetState('selectedConversationUid', '')
    setWidgetState('chats', [])
    setWidgetState('messages', [])
    setWidgetState('suggestions', [])
    setWidgetState('isChatBoxDisabled', false)
    setWidgetState('isWidgetDisabled', false)
    setWidgetState('isWidgetLocked', false)
    setWidgetState('isWidgetLoginRequired', false)
    setWidgetState('isUpgradeRequired', false)
    setWidgetState('overrideContent', '')
    setWidgetState('messagesQueue', [])
    setWidgetState('signalController', null)
    setWidgetState('dynamicUser', {})
    setWidgetState('dynamicContext', '')
    setWidgetState('dynamicQuestion', '')
    setWidgetState('dynamicSystemBehavior', '')
    setWidgetState('isResolved', false)
    setWidgetState('isEmailed', false)
    setWidgetState('brandingPreview', {})

    removeAllCookies()
  }

  async function unlockWidget(password: string) {
    const response = await HttpHelper.post(
      `/embeds/verify-password/${state.value.widget?.uid}`,
      null,
      {
        password,
      },
      { withoutApp: true }
    )

    WindowHelper.postMessage('submit_chat_unlock', {
      widget_uid: state.value.widget?.uid,
      chat_uid: state.value.chat?.uid,
      account_uid: state.value.account?.uid,
      password,
    })

    if (response?.password_verified) {
      setTimeout(() => {
        setWidgetState('isWidgetLocked', false)
      }, 1000)
    } else {
      setWidgetState('isWidgetLocked', true)

      throw new Error('Wrong password')
    }
  }

  async function loadWidget(uid: string) {
    // Reset widget
    resetWidget()

    const response = await HttpHelper.get(`/embeds/widgets/${uid}`, {
      withoutApp: true,
    })

    setWidgetState('widget', response?.widget)
    setWidgetState('plan', response?.plan)

    if (response?.is_password_protected) {
      setWidgetState('isWidgetLocked', true)
    }

    return response
  }

  /**
   * Sequences
   */
  async function processMessagesQueue() {
    if (state.value.messagesQueue?.length) {
      await insertTypingIndicator('assistant', TYPING_INDICATOR_VISIBILITY)

      removeTypingIndicator()

      const messageData = state.value.messagesQueue?.shift()

      if (messageData) {
        const { message, suggestions } = messageData

        await createMessage({
          input: message,
          role: 'assistant',
          suggestions,
          disableAI: true,
          showTypingIndicator: false,
        })
      }
    }
  }

  /**
   * Messages
   */
  async function createFiles(params: {
    type: string
    files?: { file: File; blob_url: string }[]
    transcribe?: boolean
  }): Promise<IFile[]> {
    const { type, files, transcribe } = params ?? {}

    if (!files) {
      return []
    }

    await insertTypingIndicator('user')

    const data = ObjectHelper.convertToFormData({
      app_uid: state.value.widget?.app_uid,
      widget_uid: state.value.widget?.uid,
      chat_uid: state.value.chat?.uid,
      type,
      transcribe,
    })

    for (const file of files) {
      if (file?.file) {
        data.append('files[]', file?.file)
      }
    }

    const response = await HttpHelper.post(`/embeds/store-files`, null, data, {
      withoutApp: true,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    removeTypingIndicator()

    return response ?? []
  }

  async function createMessage(params: {
    input: string
    role: TypeRole
    showTypingIndicator?: boolean
    agentChoice?: string
    disableAI?: boolean
    suggestions?: ISuggestion[]
    files?: IFile[]
    metadata?: object
  }) {
    try {
      const {
        input,
        role,
        agentChoice,
        suggestions,
        files,
        disableAI,
        showTypingIndicator,
        metadata,
      } = params
      const computedDisableAI = state.value.messagesQueue?.length ? true : disableAI
      const filesUids = files?.map((file) => file?.uid) ?? []
      let requestHumanLock = false

      setWidgetState('signalController', null)
      setWidgetState('suggestions', suggestions ?? [])

      if (input || (filesUids && filesUids?.length)) {
        state.value.messages.push({
          role,
          output_text: input,
          agent_choice: agentChoice,
          suggestions,
          files,
          audio: {
            loading: false,
            playing: false,
            element: null,
          },
        })
      }

      let typingIndicatorPromise = showTypingIndicator
        ? insertTypingIndicator('assistant')
        : Promise.resolve()

      const [, response] = await Promise.all([
        typingIndicatorPromise,
        HttpHelper.post(
          `/embeds/messages`,
          null,
          {
            chat_uid: state.value.chat?.uid,
            widget_uid: state.value.widget?.uid,
            dynamic_context: state.value.dynamicContext,
            dynamic_question: state.value.dynamicQuestion,
            dynamic_system_behavior: state.value.dynamicSystemBehavior,
            role,
            input,
            agent_choice: agentChoice,
            files_uids: filesUids,
            disable_ai: computedDisableAI,
            disable_stream: false,
            metadata,
          },
          {
            withoutApp: true,
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
            responseType: 'stream',
            accumulateChunks: false,
          },
          async (modelResponseString: string) => {
            if (!modelResponseString || !ObjectHelper.isJSON(modelResponseString)) {
              return
            }

            const modelResponse: IModelResponse[] = JSON.parse(modelResponseString)

            for await (const item of modelResponse) {
              if (item?.show_typing_indicator) {
                await insertTypingIndicator(item?.role, TYPING_INDICATOR_VISIBILITY)
              }

              typingIndicatorPromise = Promise.resolve()
              removeTypingIndicator()

              const {
                message_uid,
                role,
                output_text,
                output_form,
                agent_choice,
                sources,
                context,
                files,
                streaming,
                agents,
                metadata,
              } = item

              if (item?.suggestions) {
                setWidgetState('suggestions', item?.suggestions ?? [])
              }

              const messageObj = {
                uid: message_uid,
                role,
                output_text,
                output_form,
                agent_choice,
                isLoading: false,
                sources,
                context,
                agents,
                suggestions: suggestions || [],
                files: files || [],
                metadata,
              }
              const messageIndex = state.value.messages.findIndex(
                (message) => message.uid === message_uid
              )

              if (messageIndex === -1) {
                state.value.messages.push({
                  ...messageObj,
                  audio: { loading: false, playing: false, element: null },
                })
              } else {
                state.value.messages[messageIndex] = {
                  ...state.value.messages[messageIndex],
                  ...messageObj,
                  audio: state.value.messages[messageIndex].audio || {
                    loading: false,
                    playing: false,
                    element: null,
                  },
                }
              }

              if (!streaming) {
                if (!item?.suggestions || !Object.keys(item?.suggestions).length) {
                  WindowHelper.postMessage('assistant_answer', {
                    widget_uid: state.value.widget?.uid,
                    chat_uid: state.value.chat?.uid,
                    account_uid: state.value.account?.uid,
                    message_uid,
                    input,
                    output_text,
                    output_form,
                    agent_choice,
                    embed_url: getters.getEmbedUrl(),
                    share_url: getters.getShareUrl(),
                  })
                }

                if (
                  (agents && agents.includes('request_human')) ||
                  agentChoice === 'request_human'
                ) {
                  if (!requestHumanLock) {
                    requestHumanLock = true

                    WindowHelper.postMessage('click_talk_to_human', {
                      widget_uid: state.value.widget?.uid,
                      chat_uid: state.value.chat?.uid,
                      account_uid: state.value.account?.uid,
                    })
                  }
                }

                setWidgetState('signalController', null)
              }
            }
          }
        ),
      ])

      typingIndicatorPromise = Promise.resolve()
      removeTypingIndicator()

      if (response?.signalController) {
        setWidgetState('signalController', response?.signalController)
      }

      // Keep processing the queue
      if (role === 'user') {
        setWidgetState('suggestions', [])
        await processMessagesQueue()
      }
    } catch (error: any) {
      if (error?.status === 426 || error?.message === 'Upgrade Required') {
        setWidgetState('isUpgradeRequired', true)
      } else {
        notyf.error(error?.message)
      }

      throw error
    }
  }

  async function insertTypingIndicator(
    role: TypeRole,
    sleep: number = TYPING_INDICATOR_VISIBILITY
  ) {
    removeTypingIndicator()

    state.value.messages.push({
      role,
      isLoading: true,
      audio: {
        loading: false,
        playing: false,
        element: null,
      },
    })

    if (sleep) {
      await SleepHelper.sleep(sleep)
    }
  }

  function removeTypingIndicator() {
    setWidgetState(
      'messages',
      state.value.messages.filter((message) => !message.isLoading)
    )
  }

  function findPreviousMessage(index: number) {
    const lastIndex = Number(index) - 1

    if (state.value.messages.length >= 2) {
      if (state.value.messages[lastIndex]) {
        return state.value.messages[lastIndex]?.output_text
      }
    }

    return ''
  }

  /**
   * Audio
   */
  async function onSynthesize(message: IMessage) {
    try {
      if (!message?.uid) {
        return
      }

      message.audio = {
        loading: true,
        playing: false,
        element: null,
      }

      WindowHelper.postMessage('click_synthesize', {
        widget_uid: state.value.widget?.uid,
        chat_uid: state.value.chat?.uid,
        account_uid: state.value.account?.uid,
        message_uid: message?.uid,
      })

      const audioElement = new Audio()
      const synthesizeResponse = await HttpHelper.get(`/embeds/audio/synthesize/${message?.uid}`, {
        withoutApp: true,
      })

      message.audio.loading = false

      audioElement.src = synthesizeResponse

      // @ts-ignore
      message.files = [{ signed_url: synthesizeResponse, type: 'audio' }]
      message.audio.playing = true
      message.audio.element = audioElement

      audioElement.play().catch(function (error) {
        console.error('Play - error', error.message)
      })

      audioElement.onended = () => {
        message.audio.playing = false
        message.audio.element = null
      }

      return audioElement
    } catch (error) {
      console.error(error)

      message.audio = {
        loading: false,
        playing: false,
        element: null,
      }
    }
  }

  /**
   * Feedback
   */
  async function createFeedback(params: {
    messageUid: string
    userMessage: string
    aiMessage: string
    userFeedback: string
    status: string
  }) {
    const { messageUid, userMessage, aiMessage, userFeedback, status } = params

    await HttpHelper.post(
      `/embeds/feedbacks`,
      null,
      {
        chat_uid: state.value.chat?.uid,
        message_uid: messageUid,
        user_message: userMessage,
        ai_message: aiMessage,
        user_feedback: userFeedback,
        status,
      },
      {
        withoutApp: true,
      }
    )
  }

  /**
   * Misc
   */
  async function appendMetadata(params: { model: string; uid: string; metadata: object }) {
    const { model, uid, metadata } = params

    await HttpHelper.post(
      '/embeds/append-metadata',
      uid,
      {
        model,
        metadata,
      },
      { withoutApp: true }
    )
  }

  async function onMarkAsResolved() {
    const response = await HttpHelper.get(`/embeds/chats/resolve/${state.value.chat?.uid}`, {
      withoutApp: true,
    })

    setWidgetState('isResolved', response?.is_resolve)

    WindowHelper.postMessage('click_mark_as_resolve', {
      widget_uid: state.value.widget?.uid,
      chat_uid: state.value.chat?.uid,
      account_uid: state.value.account?.uid,
    })
  }

  const onReset = async () => {
    if (state.value.widget?.uid) {
      await createChat()
      await autoStartAgents()
    }

    WindowHelper.postMessage('click_reset', {
      widget_uid: state.value.widget?.uid,
      chat_uid: state.value.chat?.uid,
      account_uid: state.value.account?.uid,
    })
  }

  const onStop = async () => {
    state.value.signalController?.abort()
    setWidgetState('signalController', null)

    WindowHelper.postMessage('click_stop', {
      widget_uid: state.value.widget?.uid,
      chat_uid: state.value.chat?.uid,
      account_uid: state.value.account?.uid,
    })
  }

  return {
    ...getters,
    state: state.value,
    setWidgetState,
    setDynamicUser,
    setSelectedConversationUid,
    addConversation,
    login,
    register,
    loadWidget,
    loadChat,
    loadChatsFromAccount,
    createChat,
    createMessage,
    createFeedback,
    unlockWidget,
    findPreviousMessage,
    resetChat,
    autoStartAgents,
    onAgentClick,
    processMessagesQueue,
    deleteChatFromAccount,
    createFiles,
    appendMetadata,
    onSynthesize,
    onMarkAsResolved,
    insertTypingIndicator,
    onReset,
    onStop,
    resetWidget,
    removeAllCookies,
  } as const
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useWidget, import.meta.hot))
}
