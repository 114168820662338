<script setup lang="ts">
import { ref } from 'vue'
import { IFilter, ITab } from '/@src/interfaces'
import Analytics from '/@src/pages/chatbots/analytics.vue'
import Contacts from '/@src/pages/chatbots/contacts.vue'
import History from '/@src/pages/chatbots/history.vue'
import { ICONS } from '/@src/resources/files/constant'

const props = defineProps({
  title: {
    type: String,
    default: () => '',
    required: false,
  },
  widget: {
    type: Object,
    default: () => {},
    required: true,
  },
  filters: {
    type: Array as () => IFilter[],
    default: () => [],
    required: true,
  },
})

// Reactive data
const tabs = ref<ITab[]>([
  {
    label: 'History',
    value: 'history',
    icon: ICONS.pages.history,
    component: History,
  },
  {
    label: 'Contacts',
    value: 'contacts',
    icon: ICONS.pages.contacts,
    component: Contacts,
  },
  {
    label: 'Analytics',
    value: 'analytics',
    icon: ICONS.pages.analytics,
    component: Analytics,
  },
])
const selectedTab = ref(tabs.value[0]?.value)
</script>

<template>
  <VTabs
    v-model:selected="selectedTab"
    :tabs="tabs"
    :vertical="true"
    :data="{
      widget,
      filters,
    }"
  >
    <template #tab>
      <!-- History -->
      <div v-if="selectedTab === 'history'">
        <History />
      </div>

      <!-- Contacts -->
      <div v-if="selectedTab === 'contacts'">
        <Contacts />
      </div>

      <!-- Analytics -->
      <div v-if="selectedTab === 'analytics'">
        <Analytics />
      </div>
    </template>
  </VTabs>
</template>
