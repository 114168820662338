<script setup lang="ts">
import { v4 as uuid } from 'uuid'
import { computed, onMounted, PropType, reactive, ref, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { getNode } from '@formkit/core'
import { useNotyf } from '/@src/composable/useNotyf'
import { ChatbotHelper, HttpHelper } from '/@src/helpers'
import { IFilter } from '/@src/interfaces'
import countries from '/@src/resources/countries.json'
import { DALL_E_QUALITY, GO_BUTTONS, ICONS, METHODS } from '/@src/resources/files/constant'
import locales from '/@src/resources/locales.json'
import { useUserSession } from '/@src/stores'

const emit = defineEmits(['tabsStatus'])

const props = defineProps({
  title: {
    type: String,
    default: () => '',
    required: false,
  },
  filters: {
    type: Array as PropType<IFilter[]>,
    default: () => [],
    required: false,
  },
  plans: {
    type: Object,
    default: () => {},
    required: true,
  },
})

// Composable
const notyf = useNotyf()
const { t } = useI18n()
const userSession = useUserSession()
const route = useRoute()

// Const data
const formId = 'formAgents'
const widgetUid: string = Array.isArray(route?.params?.uid)
  ? route.params.uid[0]
  : route?.params?.uid
const tabs = [
  {
    category: 'Customer Support',
    items: [
      { label: 'Chat with Agent', value: 'request_human', icon: 'hugeicons:conversation' },
      {
        label: 'Mark as Resolved',
        value: 'mark_as_resolved',
        icon: 'solar:chat-square-check-outline',
      },
      { label: 'Transcribe', value: 'transcribe', icon: 'hugeicons:ai-mail' },
    ],
  },
  {
    category: 'Sales & Leads',
    items: [{ label: 'Capture Contacts', value: 'capture_lead', icon: 'mage:contact-book' }],
  },
  {
    category: 'AI Features',
    items: [
      { label: 'Smart Suggestions', value: 'smart_suggestions', icon: 'iconoir:suggestion' },
      { label: 'Guided Conversations', value: 'sequences', icon: 'hugeicons:workflow-square-03' },
      { label: 'Q&A Buttons', value: 'question_answer', icon: 'carbon:question-answering' },
    ],
  },
  {
    category: 'API',
    items: [
      {
        label: 'Custom API',
        value: 'dynamics',
        icon: 'carbon:ibm-cloud-virtual-server-vpc',
      },
    ],
  },
  {
    category: 'Internet & Search',
    items: [
      { label: 'Web Browser', value: 'web_browser', icon: 'hugeicons:browser' },
      { label: 'Google Search', value: 'google_search', icon: 'mingcute:google-line' },
      { label: 'Wikipedia Search', value: 'wikipedia_search', icon: 'tabler:brand-wikipedia' },
      { label: 'Perplexity Search', value: 'perplexity_search', icon: 'simple-icons:perplexity' },
    ],
  },
  {
    category: 'Tools',
    items: [
      {
        label: 'DALL·E 3',
        value: 'image_generator',
        icon: 'ep:picture-rounded',
      },
      { label: 'Wolfram|Alpha', value: 'wolframalpha', icon: 'cib:wolfram' },
      { label: 'Calculator', value: 'calculator', icon: 'solar:calculator-broken' },
      { label: 'IFTTT', value: 'ifttt', icon: 'clarity:plugin-line' },
    ],
  },
  {
    category: 'Other',
    items: [
      { label: 'Go Buttons', value: 'buttons', icon: 'teenyicons:button-outline' },
      {
        label: 'Accounts',
        value: 'accounts',
        icon: 'hugeicons:account-setting-03',
      },
    ],
  },
]

// Reactive data
const isLoading = ref(false)
const formData: any = ref({})
const formState: any = reactive({})
const agentsLimitation = ref<string[]>([])
const selectedTab = ref(tabs[0].items[0].value)

// Computed
const isFormDisabled = computed(() => {
  const isInvalid = !formState.valid

  if (isLoading.value || isInvalid) {
    return true
  }

  return false
})

// Lifecycle hooks
onMounted(async () => {
  try {
    Object.assign(formState, toRefs(getNode(formId)?.context?.state || {}))

    isLoading.value = true
    agentsLimitation.value = (userSession.getPlanLimitations('agents') as string[]) || []

    if (widgetUid) {
      const { computed_agents } = await HttpHelper.get(`/widgets/${widgetUid}`)

      formData.value = { ...(computed_agents || {}) }
    }
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
})

// Functions
const triggerSubmit = () => {
  getNode(formId)?.submit()
}

const computedGoButton = (type: string) => {
  switch (type) {
    case 'whatsapp':
      return {
        type: 'text',
        label: 'WhatsApp Phone Number',
        placeholder: '1XXXYXXXXXX',
        validation: 'required:trim',
      }

    case 'messenger':
      return {
        type: 'text',
        label: 'Messenger User ID',
        placeholder: '0000000000000000000',
        validation: 'required:trim',
      }

    case 'telegram':
      return {
        type: 'text',
        label: 'Telegram Username',
        placeholder: 'insertchat',
        validation: 'required:trim',
      }

    case 'embed':
      return {
        type: 'text',
        label: 'Embed URL',
        placeholder: 'https://discord.com/widget?id=1092458448194637875',
        validation: 'required:trim',
      }

    default:
      return {
        type: 'url',
        label: 'URL',
        placeholder: 'https://insertchat.com',
        validation: 'required:trim|url',
      }
  }
}

const onSubmit = async (fields: any) => {
  try {
    if (isLoading.value) {
      return
    }

    isLoading.value = true

    await HttpHelper.post('/widgets', widgetUid, { agents: fields })

    // CookiesHelper.remove(`chat_${widgetUid}`)
    // CookiesHelper.remove(`contact_${widgetUid}`)

    ChatbotHelper.refreshChatbot(widgetUid)

    emit('tabsStatus', {
      capture_lead: fields?.capture_lead?.status,
      accounts: fields?.accounts?.status,
    })

    notyf.success(t('notifications.success'))
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
}
</script>

<template>
  <FormKit
    :id="formId"
    v-model="formData"
    type="form"
    :config="{ validationVisibility: 'live' }"
    :disabled="isLoading || !agentsLimitation?.length"
    :actions="false"
    :classes="{
      form: 'h-100',
    }"
    :preserve="true"
    :preserve-errors="true"
    @submit="onSubmit"
  >
    <VLoader v-if="tabs && !tabs.length" class="h-100" />

    <VTabs v-else v-model:selected="selectedTab" :tabs="tabs" :vertical="true">
      <template #tab>
        <!-- Google Search -->
        <div v-if="selectedTab === 'google_search'">
          <CustomForm title="Google Search" subtitle="">
            <template #buttons>
              <VButton
                :disabled="isFormDisabled"
                :loading="isLoading"
                type="submit"
                color="primary"
                icon="ic:round-check"
                @click="triggerSubmit"
              >
                Save Changes
              </VButton>
            </template>

            <template #body>
              <FormKit
                name="google_search"
                type="group"
                :disabled="isLoading || !agentsLimitation.includes('google_search')"
              >
                <MarketingMessage
                  v-if="!agentsLimitation.includes('google_search')"
                  :plan="plans.professional"
                >
                  Upgrade your subscription to unlock this feature!
                </MarketingMessage>

                <VMessage color="info" class="w-100">
                  This feature operates solely as an AI agent, enabling the AI to utilize Google
                  search and will cost 1 credit per run.
                </VMessage>

                <div class="columns is-multiline">
                  <AgentStatusesSetting
                    :is-disabled="isLoading || !agentsLimitation.includes('google_search')"
                    :status-enabled="['status']"
                    :column-size="12"
                  />

                  <template v-if="formData?.google_search?.status">
                    <div class="column">
                      <FormKit
                        name="gl"
                        type="dropdown"
                        label="Country"
                        :options="countries"
                        :disabled="isLoading"
                        placeholder="Default"
                        :close-on-select="true"
                        value="us"
                        validation="required:trim"
                      >
                        <template #option="{ option }">
                          <div class="formkit-option">
                            <div class="text-container">
                              {{ option.label }}
                            </div>
                          </div>
                        </template>
                      </FormKit>
                    </div>

                    <div class="column">
                      <FormKit
                        name="hl"
                        type="dropdown"
                        label="Locale"
                        :options="locales"
                        :disabled="isLoading"
                        placeholder="Default"
                        :close-on-select="true"
                        value="en"
                        validation="required:trim"
                      >
                        <template #option="{ option }">
                          <div class="formkit-option">
                            <div class="text-container">
                              {{ option.label }}
                            </div>
                          </div>
                        </template>
                      </FormKit>
                    </div>
                  </template>
                </div>
              </FormKit>
            </template>
          </CustomForm>
        </div>

        <!-- Dall-E -->
        <div v-if="selectedTab === 'image_generator'">
          <CustomForm title="DALL·E 3" subtitle="">
            <template #buttons>
              <VButton
                :disabled="isFormDisabled"
                :loading="isLoading"
                type="submit"
                color="primary"
                icon="ic:round-check"
                @click="triggerSubmit"
              >
                Save Changes
              </VButton>
            </template>

            <template #body>
              <FormKit
                name="image_generator"
                type="group"
                :disabled="isLoading || !agentsLimitation.includes('image_generator')"
              >
                <MarketingMessage
                  v-if="!agentsLimitation.includes('image_generator')"
                  :plan="plans.professional"
                >
                  Upgrade your subscription to unlock this feature!
                </MarketingMessage>

                <VMessage color="info" class="w-100">
                  This feature operates solely as an AI agent, enabling the AI to generate images
                  using Dall-E 3 and will cost 3 credits per run.
                </VMessage>

                <div class="columns is-multiline">
                  <AgentStatusesSetting
                    :is-disabled="isLoading || !agentsLimitation.includes('image_generator')"
                    :status-enabled="['status']"
                    :column-size="12"
                  />

                  <div v-if="formData?.image_generator?.status" class="column is-12">
                    <FormKit
                      name="style"
                      type="dropdown"
                      label="Style"
                      :options="DALL_E_QUALITY"
                      :disabled="isLoading"
                      placeholder="Default"
                      :close-on-select="true"
                      value="vivid"
                      validation="required:trim"
                    >
                      <template #option="{ option }">
                        <div class="formkit-option">
                          <div class="text-container">
                            {{ option.label }}
                          </div>
                        </div>
                      </template>
                    </FormKit>
                  </div>
                </div>
              </FormKit>
            </template>
          </CustomForm>
        </div>

        <!-- WolframAlpha -->
        <div v-if="selectedTab === 'wolframalpha'">
          <CustomForm title="Wolfram|Alpha" subtitle="">
            <template #buttons>
              <VButton
                :disabled="isFormDisabled"
                :loading="isLoading"
                type="submit"
                color="primary"
                icon="ic:round-check"
                @click="triggerSubmit"
              >
                Save Changes
              </VButton>
            </template>

            <template #body>
              <FormKit
                name="wolframalpha"
                type="group"
                :disabled="isLoading || !agentsLimitation.includes('wolframalpha')"
              >
                <MarketingMessage
                  v-if="!agentsLimitation.includes('wolframalpha')"
                  :plan="plans.agency"
                >
                  Upgrade your subscription to unlock this feature!
                </MarketingMessage>

                <VMessage color="info" class="w-100">
                  This feature operates solely as an AI agent, enabling the AI to use WolframAlpha a
                  powerful answer engine and will cost 1 credit per run.
                </VMessage>

                <div class="columns is-multiline">
                  <AgentStatusesSetting
                    :is-disabled="isLoading || !agentsLimitation.includes('wolframalpha')"
                    :status-enabled="['status']"
                    :column-size="12"
                  />

                  <div v-if="formData?.wolframalpha?.status" class="column is-12">
                    <FormKit
                      name="app_id"
                      type="text"
                      label="App ID"
                      :disabled="isLoading || !agentsLimitation.includes('wolframalpha')"
                      placeholder="Your WolframAlpha app ID"
                      validation="required:trim"
                    />
                  </div>
                </div>
              </FormKit>
            </template>
          </CustomForm>
        </div>

        <!-- Perplexity Search -->
        <div v-if="selectedTab === 'perplexity_search'">
          <CustomForm title="Perplexity Search" subtitle="">
            <template #buttons>
              <VButton
                :disabled="isFormDisabled"
                :loading="isLoading"
                type="submit"
                color="primary"
                icon="ic:round-check"
                @click="triggerSubmit"
              >
                Save Changes
              </VButton>
            </template>

            <template #body>
              <FormKit
                name="wikipedia_search"
                type="group"
                :disabled="isLoading || !agentsLimitation.includes('perplexity_search')"
              >
                <MarketingMessage
                  v-if="!agentsLimitation.includes('perplexity_search')"
                  :plan="plans.professional"
                >
                  Upgrade your subscription to unlock this feature!
                </MarketingMessage>

                <VMessage color="info" class="w-100">
                  This feature operates solely as an AI agent, enabling the AI to utilize Perplexity
                  search and will cost 1 credit per run.
                </VMessage>

                <div class="columns is-multiline">
                  <AgentStatusesSetting
                    :is-disabled="isLoading || !agentsLimitation.includes('perplexity_search')"
                    :status-enabled="['status']"
                    :column-size="12"
                  />
                </div>
              </FormKit>
            </template>
          </CustomForm>
        </div>

        <!-- Wikipedia Search -->
        <div v-if="selectedTab === 'wikipedia_search'">
          <CustomForm title="Wikipedia Search" subtitle="">
            <template #buttons>
              <VButton
                :disabled="isFormDisabled"
                :loading="isLoading"
                type="submit"
                color="primary"
                icon="ic:round-check"
                @click="triggerSubmit"
              >
                Save Changes
              </VButton>
            </template>

            <template #body>
              <FormKit
                name="wikipedia_search"
                type="group"
                :disabled="isLoading || !agentsLimitation.includes('wikipedia_search')"
              >
                <MarketingMessage
                  v-if="!agentsLimitation.includes('wikipedia_search')"
                  :plan="plans.professional"
                >
                  Upgrade your subscription to unlock this feature!
                </MarketingMessage>

                <VMessage color="info" class="w-100">
                  This feature operates solely as an AI agent, enabling the AI to utilize Wikipedia
                  search and will cost 1 credit per run.
                </VMessage>

                <div class="columns is-multiline">
                  <AgentStatusesSetting
                    :is-disabled="isLoading || !agentsLimitation.includes('wikipedia_search')"
                    :status-enabled="['status']"
                    :column-size="12"
                  />
                </div>
              </FormKit>
            </template>
          </CustomForm>
        </div>

        <!-- Web Browser -->
        <div v-if="selectedTab === 'web_browser'">
          <CustomForm title="Web Browser" subtitle="">
            <template #buttons>
              <VButton
                :disabled="isFormDisabled"
                :loading="isLoading"
                type="submit"
                color="primary"
                icon="ic:round-check"
                @click="triggerSubmit"
              >
                Save Changes
              </VButton>
            </template>

            <template #body>
              <FormKit
                name="web_browser"
                type="group"
                :disabled="isLoading || !agentsLimitation.includes('web_browser')"
              >
                <MarketingMessage
                  v-if="!agentsLimitation.includes('web_browser')"
                  :plan="plans.professional"
                >
                  Upgrade your subscription to unlock this feature!
                </MarketingMessage>

                <VMessage color="info" class="w-100">
                  This feature operates solely as an AI agent, enabling the AI to utilize a web
                  browser and will cost 1 credit per run.
                </VMessage>

                <div class="columns is-multiline">
                  <AgentStatusesSetting
                    :is-disabled="isLoading || !agentsLimitation.includes('web_browser')"
                    :status-enabled="['status']"
                    :column-size="12"
                  />
                </div>
              </FormKit>
            </template>
          </CustomForm>
        </div>

        <!-- Calculator -->
        <div v-if="selectedTab === 'calculator'">
          <CustomForm title="Calculator" subtitle="">
            <template #buttons>
              <VButton
                :disabled="isFormDisabled"
                :loading="isLoading"
                type="submit"
                color="primary"
                icon="ic:round-check"
                @click="triggerSubmit"
              >
                Save Changes
              </VButton>
            </template>

            <template #body>
              <FormKit
                name="calculator"
                type="group"
                :disabled="isLoading || !agentsLimitation.includes('calculator')"
              >
                <MarketingMessage
                  v-if="!agentsLimitation.includes('calculator')"
                  :plan="plans.professional"
                >
                  Upgrade your subscription to unlock this feature!
                </MarketingMessage>

                <VMessage color="info" class="w-100">
                  This feature operates solely as an AI agent, enabling the AI to do calculation and
                  will cost 1 credit per run.
                </VMessage>

                <div class="columns is-multiline">
                  <AgentStatusesSetting
                    :is-disabled="isLoading || !agentsLimitation.includes('calculator')"
                    :status-enabled="['status']"
                    :column-size="12"
                  />
                </div>
              </FormKit>
            </template>
          </CustomForm>
        </div>

        <!-- IFTTT -->
        <div v-if="selectedTab === 'ifttt'">
          <CustomForm title="IFTTT" subtitle="">
            <template #buttons>
              <VButton
                :disabled="isFormDisabled"
                :loading="isLoading"
                type="submit"
                color="primary"
                icon="ic:round-check"
                @click="triggerSubmit"
              >
                Save Changes
              </VButton>
            </template>

            <template #body>
              <FormKit
                name="ifttt"
                type="group"
                :disabled="isLoading || !agentsLimitation.includes('ifttt')"
              >
                <MarketingMessage v-if="!agentsLimitation.includes('ifttt')" :plan="plans.agency">
                  Upgrade your subscription to unlock this feature!
                </MarketingMessage>

                <VMessage color="info" class="w-100">
                  This feature operates solely as an AI agent, enabling the AI to utilize IFTTT (If
                  This Then That) services, which offer a comprehensive catalog of applets and will
                  cost 1 credit per run.
                </VMessage>

                <div class="columns is-multiline">
                  <div class="column is-12">Coming soon!</div>
                </div>
              </FormKit>
            </template>
          </CustomForm>
        </div>

        <!-- Custom API Calls -->
        <div v-if="selectedTab === 'dynamics'">
          <CustomForm title="Custom API Calls" subtitle="">
            <template #buttons>
              <VButton
                :disabled="isFormDisabled"
                :loading="isLoading"
                type="submit"
                color="primary"
                icon="ic:round-check"
                @click="triggerSubmit"
              >
                Save Changes
              </VButton>
            </template>

            <template #body>
              <MarketingMessage
                v-if="!agentsLimitation.includes('dynamics')"
                :plan="plans.professional"
              >
                Upgrade your subscription to unlock this feature!
              </MarketingMessage>

              <VMessage color="info" class="w-100">
                This feature operates solely as an AI agent, enabling you to create custom API calls
                to retrieve data and enable your AI to utilize it effectively and will cost 1 credit
                per run.
              </VMessage>

              <FormKit
                v-slot="{ index }"
                name="dynamics"
                type="repeater"
                :up-control="false"
                :down-control="true"
                :add-button="true"
                :insert-control="false"
                :disabled="isLoading || !agentsLimitation.includes('dynamics')"
                min="1"
                :sections-schema="{
                  insertControl: {
                    attrs: {
                      disabled: '$value.length >= $max',
                      onClick: '$fns.createCopy($index)',
                      type: 'button',
                    },
                  },
                }"
              >
                <CustomForm :title="`HTTP ${Number(index) + 1}`">
                  <template #body>
                    <div class="columns is-multiline">
                      <AgentStatusesSetting
                        :is-disabled="isLoading || !agentsLimitation.includes('dynamics')"
                        :status-enabled="['status']"
                        :column-size="12"
                      />

                      <div class="column is-6">
                        <FormKit
                          name="name"
                          type="text"
                          label="Name"
                          validation="required:trim|contains_lowercase|agent_name"
                          :disabled="isLoading"
                          placeholder=""
                          :validation-messages="{
                            agent_name: 'Only lowercase letters and dashes are allowed.',
                          }"
                        />
                      </div>
                      <div class="column is-6">
                        <FormKit
                          name="description"
                          type="text"
                          label="Description"
                          validation="required:trim"
                          :disabled="isLoading"
                          placeholder=""
                        />
                      </div>
                      <div class="column is-2">
                        <FormKit
                          name="method"
                          type="select"
                          label="Request method"
                          validation="required:trim"
                          :disabled="isLoading"
                          :options="METHODS"
                          placeholder=""
                        />
                      </div>
                      <div class="column is-10">
                        <FormKit
                          name="url"
                          type="text"
                          label="URL to call"
                          validation="required:trim"
                          :disabled="isLoading"
                          placeholder=""
                        />
                      </div>

                      <!-- Headers -->
                      <div class="column is-12">
                        <CustomCollapse with-chevron :item-open="false" color="#f6f6f7">
                          <template #collapse-item-summary><b>Headers</b></template>
                          <template #collapse-item-content>
                            <FormKit
                              name="headers"
                              type="repeater"
                              :disabled="isLoading"
                              :up-control="false"
                              :down-control="true"
                              :add-button="false"
                              :insert-control="true"
                            >
                              <div class="columns">
                                <div class="column is-6">
                                  <FormKit
                                    type="text"
                                    label="Key"
                                    name="key"
                                    validation="trim"
                                    placeholder=""
                                    :disabled="isLoading"
                                  />
                                </div>
                                <div class="column is-6">
                                  <FormKit
                                    type="text"
                                    label="Value"
                                    name="key"
                                    validation="trim"
                                    placeholder=""
                                    :disabled="isLoading"
                                  />
                                </div>
                              </div>
                            </FormKit>
                          </template>
                        </CustomCollapse>
                      </div>

                      <!-- Body -->
                      <div class="column is-12">
                        <CustomCollapse with-chevron :item-open="false" color="#f6f6f7">
                          <template #collapse-item-summary><b>Body</b></template>
                          <template #collapse-item-content>
                            <FormKit
                              name="body"
                              type="repeater"
                              :disabled="isLoading"
                              :up-control="false"
                              :down-control="true"
                              :add-button="false"
                              :insert-control="true"
                            >
                              <div class="columns">
                                <div class="column is-6">
                                  <FormKit
                                    type="text"
                                    label="Key"
                                    name="key"
                                    validation="trim"
                                    placeholder=""
                                    :disabled="isLoading"
                                  />
                                </div>
                                <div class="column is-6">
                                  <FormKit
                                    type="text"
                                    label="Value"
                                    name="key"
                                    validation="trim"
                                    placeholder=""
                                    :disabled="isLoading"
                                  />
                                </div>
                              </div>
                            </FormKit>
                          </template>
                        </CustomCollapse>
                      </div>
                    </div>
                  </template>
                </CustomForm>
              </FormKit>
            </template>
          </CustomForm>
        </div>

        <!-- Continue as e-mail -->
        <div v-if="selectedTab === 'transcribe'">
          <CustomForm title="Transcribe" subtitle="">
            <template #buttons>
              <VButton
                :disabled="isFormDisabled"
                :loading="isLoading"
                type="submit"
                color="primary"
                icon="ic:round-check"
                @click="triggerSubmit"
              >
                Save Changes
              </VButton>
            </template>

            <template #body>
              <FormKit
                name="transcribe"
                type="group"
                :disabled="isLoading || !agentsLimitation.includes('transcribe')"
              >
                <MarketingMessage
                  v-if="!agentsLimitation.includes('transcribe')"
                  :plan="plans.basic"
                >
                  Upgrade your subscription to unlock this feature!
                </MarketingMessage>

                <VMessage color="info" class="w-100">
                  This feature operates both as an AI agent and an agent button, enabling the AI to
                  transcribe or continue by e-mail by chatting or you can set it up as an agent
                  button to trigger with a user click and will cost 1 credit per run.
                </VMessage>

                <CustomForm>
                  <template #body>
                    <div class="columns is-multiline">
                      <AgentStatusesSetting
                        :is-disabled="isLoading || !agentsLimitation.includes('transcribe')"
                        :status-enabled="['status', 'is_button']"
                        :column-size="6"
                      />

                      <template v-if="formData.transcribe.is_button">
                        <AgentBtnSettings
                          :is-disabled="isLoading || !agentsLimitation.includes('transcribe')"
                        />

                        <div class="column is-6">
                          <FormKit
                            name="text_button"
                            type="text"
                            label="Text (Agent button)"
                            validation="required:trim"
                            :disabled="isLoading || !agentsLimitation.includes('transcribe')"
                          />
                        </div>
                        <div class="column is-6">
                          <FormKit
                            name="text_button_submit"
                            type="text"
                            label="Text (Submit Button)"
                            validation="required:trim"
                            :disabled="isLoading || !agentsLimitation.includes('transcribe')"
                          />
                        </div>
                      </template>
                    </div>
                  </template>
                </CustomForm>

                <CustomForm>
                  <template #body>
                    <div class="columns is-multiline">
                      <div class="column is-12">
                        <FormKit
                          name="prompt"
                          type="text"
                          label="User Prompt"
                          validation="required:trim"
                          :disabled="isLoading || !agentsLimitation.includes('transcribe')"
                        />
                      </div>
                      <div class="column is-12">
                        <FormKit
                          name="text_intro"
                          type="text"
                          label="Intro text"
                          validation="required:trim"
                          :disabled="isLoading || !agentsLimitation.includes('transcribe')"
                        />
                      </div>
                      <div class="column is-12">
                        <FormKit
                          name="text_thanks"
                          type="text"
                          label="Outro text"
                          validation="required:trim"
                          :disabled="isLoading || !agentsLimitation.includes('transcribe')"
                        />
                      </div>
                    </div>
                  </template>
                </CustomForm>

                <CustomForm>
                  <template #body>
                    <div class="columns is-multiline">
                      <div class="column is-12">
                        <FormKit
                          name="email_title"
                          type="text"
                          label="E-mail title"
                          validation="required:trim"
                          :disabled="isLoading || !agentsLimitation.includes('transcribe')"
                        />
                      </div>

                      <div class="column is-12">
                        <FormKit
                          type="taglist"
                          name="emails"
                          label="Included as a recipient of the e-mail sent"
                          placeholder="Enter email addresses"
                          :options="[userSession?.user?.email]"
                          :disabled="isLoading || !agentsLimitation.includes('transcribe')"
                          :allow-new-values="true"
                          :open-on-click="true"
                          max="10"
                          min="0"
                        />
                      </div>
                    </div>
                  </template>
                </CustomForm>

                <CustomCollapse with-chevron color="#f6f6f7">
                  <template #collapse-item-summary>
                    <h4 class="mt-0 mb-0 is-inline">
                      <b>Form</b>
                    </h4>
                  </template>
                  <template #collapse-item-content>
                    <FormKit
                      name="inputs"
                      type="list"
                      :disabled="isLoading || !agentsLimitation.includes('transcribe')"
                    >
                      <div class="table-container">
                        <table class="table w-100">
                          <tr>
                            <th>Label</th>
                            <th>Type</th>
                            <th>Status</th>
                            <th>Required</th>
                          </tr>

                          <tr v-for="input of formData.transcribe.inputs" :key="input.name">
                            <FormKit
                              :name="input.name"
                              type="group"
                              :disabled="isLoading || !agentsLimitation.includes('transcribe')"
                            >
                              <td>
                                <FormKit
                                  name="label"
                                  type="text"
                                  validation="required:trim"
                                  :classes="{ outer: 'm-0' }"
                                  style="width: 100px"
                                  :disabled="isLoading || !agentsLimitation.includes('transcribe')"
                                />
                              </td>
                              <td>{{ input.type }}</td>
                              <td>
                                <FormKit
                                  label=""
                                  name="status"
                                  type="toggle"
                                  :off-value="false"
                                  :on-value="true"
                                  label-position="secondary"
                                  value-label-display="inner"
                                  off-value-label="OFF"
                                  on-value-label="ON"
                                  validation="required:trim"
                                  :disabled="isLoading || !agentsLimitation.includes('transcribe')"
                                />
                              </td>
                              <td>
                                <FormKit
                                  label=""
                                  name="required"
                                  type="toggle"
                                  :off-value="false"
                                  :on-value="true"
                                  label-position="secondary"
                                  value-label-display="inner"
                                  off-value-label="OFF"
                                  on-value-label="ON"
                                  validation="required:trim"
                                  :disabled="isLoading || !agentsLimitation.includes('transcribe')"
                                />
                              </td>
                            </FormKit>
                          </tr>
                        </table>
                      </div>
                    </FormKit>
                  </template>
                </CustomCollapse>
              </FormKit>
            </template>
          </CustomForm>
        </div>

        <!-- Mark as resolve -->
        <div v-if="selectedTab === 'mark_as_resolved'">
          <CustomForm title="Mark a Conversation as Resolved" subtitle="">
            <template #buttons>
              <VButton
                :disabled="isFormDisabled"
                :loading="isLoading"
                type="submit"
                color="primary"
                icon="ic:round-check"
                @click="triggerSubmit"
              >
                Save Changes
              </VButton>
            </template>

            <template #body>
              <FormKit
                name="mark_as_resolved"
                type="group"
                :disabled="isLoading || !agentsLimitation.includes('mark_as_resolved')"
              >
                <MarketingMessage
                  v-if="!agentsLimitation.includes('mark_as_resolved')"
                  :plan="plans.basic"
                >
                  Upgrade your subscription to unlock this feature!
                </MarketingMessage>

                <VMessage color="info" class="w-100">
                  This feature operates both as an AI agent and an agent button, enabling the AI to
                  mark the conversation as solved by chatting or you can set it up as an agent
                  button to trigger with a user click and will cost 1 credit per run.
                </VMessage>

                <CustomForm>
                  <template #body>
                    <div class="columns is-multiline">
                      <AgentStatusesSetting
                        :is-disabled="isLoading || !agentsLimitation.includes('mark_as_resolved')"
                        :status-enabled="['status', 'is_button']"
                        :column-size="6"
                      />

                      <template v-if="formData.mark_as_resolved.is_button">
                        <AgentBtnSettings
                          :is-disabled="isLoading || !agentsLimitation.includes('mark_as_resolved')"
                        />

                        <div class="column is-12">
                          <FormKit
                            name="text_button"
                            type="text"
                            label="Text (Agent button)"
                            validation="required:trim"
                            :disabled="isLoading || !agentsLimitation.includes('mark_as_resolved')"
                          />
                        </div>
                      </template>
                    </div>
                  </template>
                </CustomForm>

                <CustomForm>
                  <template #body>
                    <div class="columns is-multiline">
                      <div class="column is-12">
                        <FormKit
                          name="prompt"
                          type="text"
                          label="User Prompt"
                          validation="required:trim"
                          :disabled="isLoading || !agentsLimitation.includes('mark_as_resolved')"
                        />
                      </div>
                      <div class="column is-12">
                        <FormKit
                          name="text_thanks"
                          type="text"
                          label="Outro Text"
                          validation="required:trim"
                          :disabled="isLoading || !agentsLimitation.includes('mark_as_resolved')"
                        />
                      </div>
                    </div>
                  </template>
                </CustomForm>
              </FormKit>
            </template>
          </CustomForm>
        </div>

        <!-- Talk to a human -->
        <div v-if="selectedTab === 'request_human'">
          <CustomForm title="Chat with Agent" subtitle="">
            <template #buttons>
              <VButton
                :disabled="isFormDisabled"
                :loading="isLoading"
                type="submit"
                color="primary"
                icon="ic:round-check"
                @click="triggerSubmit"
              >
                Save Changes
              </VButton>
            </template>

            <template #body>
              <FormKit
                name="request_human"
                type="group"
                :disabled="isLoading || !agentsLimitation.includes('request_human')"
              >
                <MarketingMessage
                  v-if="!agentsLimitation.includes('request_human')"
                  :plan="plans.standard"
                >
                  Upgrade your subscription to unlock this feature!
                </MarketingMessage>

                <VMessage color="info" class="w-100">
                  Upon page load, your traditional live chatbot will remain hidden and will only
                  appear to your users when they request to speak with a human agent or when they
                  click on the designated agent button, provided that this feature is enabled.<br />
                  To integrate this functionality, simply add any supported traditional live chatbot
                  alongside our AI chatbot to your website, app, or store. The configuration is
                  automated.<br /><br />
                  Supported traditional live chatbots include: Intercom, Tidio, Crisp, Kustomer,
                  HelpCrunch, Olark, HubSpot, LiveChat, ClickDesk, Drift, Tawk, LiveAgent, Trengo,
                  Gorgias, Freshworks, and Front.<br /><br />
                  This feature operates both as an AI agent and an agent button, enabling the AI to
                  connect you to a human agent by chatting, which will open the live chat, or you
                  can set it up as an agent button to trigger with a user click and will cost 1
                  credit per run.
                </VMessage>

                <CustomForm>
                  <template #body>
                    <div class="columns is-multiline">
                      <AgentStatusesSetting
                        :is-disabled="isLoading || !agentsLimitation.includes('request_human')"
                        :status-enabled="['status', 'is_button']"
                        :column-size="6"
                      />

                      <template v-if="formData.request_human.is_button">
                        <AgentBtnSettings
                          v-if="formData.request_human.is_button"
                          :is-disabled="isLoading || !agentsLimitation.includes('request_human')"
                        />

                        <div class="column is-12">
                          <FormKit
                            name="text_button"
                            type="text"
                            label="Text (Agent button)"
                            validation="required:trim"
                            :disabled="isLoading || !agentsLimitation.includes('request_human')"
                          />
                        </div>
                      </template>
                    </div>
                  </template>
                </CustomForm>

                <CustomForm>
                  <template #body>
                    <div class="columns is-multiline">
                      <div class="column is-12">
                        <FormKit
                          name="prompt"
                          type="text"
                          label="User Prompt"
                          validation="required:trim"
                          :disabled="isLoading || !agentsLimitation.includes('request_human')"
                        />
                      </div>
                      <div class="column is-12">
                        <FormKit
                          name="text_thanks"
                          type="text"
                          label="Outro text"
                          validation="required:trim"
                          :disabled="isLoading || !agentsLimitation.includes('transcribe')"
                        />
                      </div>
                    </div>
                  </template>
                </CustomForm>
              </FormKit>
            </template>
          </CustomForm>
        </div>

        <!-- Lead information collection -->
        <div v-if="selectedTab === 'capture_lead'">
          <CustomForm title="Capture Contacts" subtitle="">
            <template #buttons>
              <VButton
                :disabled="isFormDisabled"
                :loading="isLoading"
                type="submit"
                color="primary"
                icon="ic:round-check"
                @click="triggerSubmit"
              >
                Save Changes
              </VButton>
            </template>

            <template #body>
              <FormKit
                name="capture_lead"
                type="group"
                :disabled="isLoading || !agentsLimitation.includes('capture_lead')"
              >
                <MarketingMessage
                  v-if="!agentsLimitation.includes('capture_lead')"
                  :plan="plans.basic"
                >
                  Upgrade your subscription to unlock this feature!
                </MarketingMessage>

                <VMessage color="info" class="w-100">
                  This feature operates both as an AI agent and an agent button, enabling the AI to
                  collect the contact information by chatting or you can set it up as an agent
                  button to trigger with a user click and will cost 1 credit per run.
                </VMessage>

                <CustomForm>
                  <template #body>
                    <div class="columns is-multiline">
                      <AgentStatusesSetting
                        :is-disabled="isLoading || !agentsLimitation.includes('capture_lead')"
                        :status-enabled="['status', 'is_button', 'is_open']"
                        :column-size="4"
                      />

                      <template v-if="formData.capture_lead.is_button">
                        <AgentBtnSettings
                          v-if="formData.capture_lead.is_button"
                          :is-disabled="isLoading || !agentsLimitation.includes('capture_lead')"
                        />

                        <div class="column is-6">
                          <FormKit
                            name="text_button"
                            type="text"
                            label="Text (Agent button)"
                            validation="required:trim"
                            :disabled="isLoading || !agentsLimitation.includes('capture_lead')"
                          />
                        </div>
                        <div class="column is-6">
                          <FormKit
                            name="text_button_submit"
                            type="text"
                            label="Text (Submit Button)"
                            validation="required:trim"
                            :disabled="isLoading || !agentsLimitation.includes('capture_lead')"
                          />
                        </div>
                      </template>
                    </div>
                  </template>
                </CustomForm>

                <CustomForm>
                  <template #body>
                    <div class="columns is-multiline">
                      <div class="column is-12">
                        <FormKit
                          name="prompt"
                          type="text"
                          label="User Prompt"
                          validation="required:trim"
                          :disabled="isLoading || !agentsLimitation.includes('capture_lead')"
                        />
                      </div>
                      <div class="column is-12">
                        <FormKit
                          name="text_intro"
                          type="text"
                          label="Intro text"
                          validation="required:trim"
                          :disabled="isLoading || !agentsLimitation.includes('capture_lead')"
                        />
                      </div>
                      <div class="column is-12">
                        <FormKit
                          name="text_thanks"
                          type="text"
                          label="Outro text"
                          validation="required:trim"
                          :disabled="isLoading || !agentsLimitation.includes('capture_lead')"
                        />
                      </div>
                    </div>
                  </template>
                </CustomForm>

                <CustomForm>
                  <template #body>
                    <div class="columns is-multiline">
                      <div class="column is-12">
                        <FormKit
                          name="email_title"
                          type="text"
                          label="E-mail title"
                          validation="required:trim"
                          :disabled="isLoading || !agentsLimitation.includes('capture_lead')"
                        />
                      </div>
                      <div class="column is-12">
                        <FormKit
                          type="taglist"
                          name="emails"
                          label="Receive an e-mail when a new lead is created"
                          placeholder="Enter email addresses"
                          :options="[userSession?.user?.email]"
                          :disabled="isLoading || !agentsLimitation.includes('capture_lead')"
                          :allow-new-values="true"
                          :open-on-click="true"
                          max="10"
                          min="0"
                        />
                      </div>
                    </div>
                  </template>
                </CustomForm>

                <CustomCollapse with-chevron color="#f6f6f7">
                  <template #collapse-item-summary>
                    <h4 class="mt-0 mb-0 is-inline">
                      <b>Form</b>
                    </h4>
                  </template>
                  <template #collapse-item-content>
                    <FormKit
                      name="inputs"
                      type="list"
                      :disabled="isLoading || !agentsLimitation.includes('capture_lead')"
                    >
                      <div class="table-container">
                        <table class="table w-100">
                          <tr>
                            <th>Label</th>
                            <th>Type</th>
                            <th>Status</th>
                            <th>Required</th>
                          </tr>

                          <tr v-for="input of formData.capture_lead.inputs" :key="input.name">
                            <FormKit
                              :name="input.name"
                              type="group"
                              :disabled="isLoading || !agentsLimitation.includes('capture_lead')"
                            >
                              <td>
                                <FormKit
                                  name="label"
                                  type="text"
                                  :classes="{ outer: 'w-50 m-0' }"
                                  :disabled="
                                    isLoading || !agentsLimitation.includes('capture_lead')
                                  "
                                  validation="required:trim"
                                />
                              </td>
                              <td>{{ input.type }}</td>
                              <td>
                                <FormKit
                                  label=""
                                  name="status"
                                  type="toggle"
                                  :off-value="false"
                                  :on-value="true"
                                  label-position="secondary"
                                  value-label-display="inner"
                                  off-value-label="OFF"
                                  on-value-label="ON"
                                  validation="required:trim"
                                  :disabled="
                                    isLoading || !agentsLimitation.includes('capture_lead')
                                  "
                                />
                              </td>
                              <td>
                                <FormKit
                                  label=""
                                  name="required"
                                  type="toggle"
                                  :off-value="false"
                                  :on-value="true"
                                  label-position="secondary"
                                  value-label-display="inner"
                                  off-value-label="OFF"
                                  on-value-label="ON"
                                  validation="required:trim"
                                  :disabled="
                                    isLoading || !agentsLimitation.includes('capture_lead')
                                  "
                                />
                              </td>
                            </FormKit>
                          </tr>
                        </table>
                      </div>
                    </FormKit>
                  </template>
                </CustomCollapse>
              </FormKit>
            </template>
          </CustomForm>
        </div>

        <!-- Q&A -->
        <div v-if="selectedTab === 'question_answer'">
          <CustomForm title="Question & Answer Buttons" subtitle="">
            <template #buttons>
              <VButton
                :disabled="isFormDisabled"
                :loading="isLoading"
                type="submit"
                color="primary"
                icon="ic:round-check"
                @click="triggerSubmit"
              >
                Save Changes
              </VButton>
            </template>

            <template #body>
              <MarketingMessage
                v-if="!agentsLimitation.includes('question_answer')"
                :plan="plans.basic"
              >
                Upgrade your subscription to unlock this feature!
              </MarketingMessage>

              <VMessage color="info" class="w-100">
                This feature operates solely as an agent button. You can pre-define customized Q&A
                by crafting your own responses or allowing the AI to generate answers and will cost
                1 credit per run.
              </VMessage>

              <FormKit
                v-slot="{ value, index }"
                type="repeater"
                name="question_answer"
                :up-control="false"
                :down-control="true"
                :add-button="true"
                :insert-control="false"
                :disabled="isLoading || !agentsLimitation.includes('question_answer')"
                min="0"
                :sections-schema="{
                  insertControl: {
                    attrs: {
                      disabled: '$value.length >= $max',
                      onClick: '$fns.createCopy($index)',
                      type: 'button',
                    },
                  },
                }"
              >
                <CustomForm :title="`Q&A ${Number(index) + 1}`">
                  <template #body>
                    <div class="columns is-multiline">
                      <AgentStatusesSetting
                        :is-disabled="isLoading || !agentsLimitation.includes('question_answer')"
                        :status-enabled="['status']"
                        :column-size="12"
                      />

                      <AgentBtnSettings
                        :is-disabled="isLoading || !agentsLimitation.includes('question_answer')"
                      />
                    </div>

                    <div class="column is-12">
                      <FormKit
                        name="text_button"
                        type="text"
                        label="Question (Button)"
                        validation="required:trim"
                        :disabled="isLoading || !agentsLimitation.includes('question_answer')"
                      />
                    </div>
                    <div class="column is-12">
                      <FormKit
                        name="prompt"
                        type="text"
                        label="User Prompt"
                        validation="required:trim"
                        :disabled="isLoading || !agentsLimitation.includes('question_answer')"
                      />
                    </div>

                    <div class="column is-12">
                      <FormKit
                        label="The AI will provide the answer to the question."
                        name="ai_answer"
                        type="toggle"
                        :off-value="false"
                        :on-value="true"
                        label-position="secondary"
                        value-label-display="inner"
                        off-value-label="OFF"
                        on-value-label="ON"
                        validation="required:trim"
                        :value="true"
                        :disabled="isLoading || !agentsLimitation.includes('question_answer')"
                      />
                    </div>
                    <div v-if="!value?.ai_answer" class="column is-12">
                      <FormKit
                        name="answer"
                        type="textarea"
                        label="Answer"
                        validation="required:trim"
                        rows="1"
                        :disabled="isLoading || !agentsLimitation.includes('question_answer')"
                      />
                    </div>
                  </template>
                </CustomForm>
              </FormKit>
            </template>
          </CustomForm>
        </div>

        <!-- Go Buttons -->
        <div v-if="selectedTab === 'buttons'">
          <CustomForm title="Go Buttons" subtitle="">
            <template #buttons>
              <VButton
                :disabled="isFormDisabled"
                :loading="isLoading"
                type="submit"
                color="primary"
                icon="ic:round-check"
                @click="triggerSubmit"
              >
                Save Changes
              </VButton>
            </template>

            <template #body>
              <MarketingMessage v-if="!agentsLimitation.includes('buttons')" :plan="plans.basic">
                Upgrade your subscription to unlock this feature!
              </MarketingMessage>

              <VMessage color="info" class="w-100">
                This feature operates solely as an agent button. You can create custom buttons for
                various functions, such as linking to URLs or initiating chats on WhatsApp,
                Telegram, or Messenger and will cost 1 credit per run.
              </VMessage>

              <FormKit
                v-slot="{ value, index }"
                type="repeater"
                name="buttons"
                :up-control="false"
                :down-control="true"
                :add-button="true"
                :insert-control="false"
                :disabled="isLoading || !agentsLimitation.includes('buttons')"
                min="0"
                :sections-schema="{
                  insertControl: {
                    attrs: {
                      disabled: '$value.length >= $max',
                      onClick: '$fns.createCopy($index)',
                      type: 'button',
                    },
                  },
                }"
              >
                <CustomForm :title="`Button ${Number(index) + 1}`">
                  <template #body>
                    <div class="columns is-multiline">
                      <AgentStatusesSetting
                        :is-disabled="isLoading || !agentsLimitation.includes('buttons')"
                        :status-enabled="['status']"
                        :column-size="12"
                      />

                      <AgentBtnSettings
                        :is-disabled="isLoading || !agentsLimitation.includes('buttons')"
                      />

                      <div class="column is-12">
                        <FormKit
                          name="type"
                          type="select"
                          label="Type"
                          validation="required:trim"
                          :disabled="isLoading || !agentsLimitation.includes('buttons')"
                          value="url"
                          :options="GO_BUTTONS"
                          @change="
                            () => {
                              formData.buttons[index].url = ''
                            }
                          "
                        />
                      </div>

                      <div class="column is-6">
                        <FormKit
                          name="text_button"
                          type="text"
                          label="Text"
                          validation="required:trim"
                          :disabled="isLoading || !agentsLimitation.includes('buttons')"
                        />
                      </div>

                      <div class="column is-6">
                        <FormKit
                          name="url"
                          :type="computedGoButton(value?.type)?.type"
                          :label="computedGoButton(value?.type)?.label"
                          :placeholder="computedGoButton(value?.type)?.placeholder"
                          :validation="computedGoButton(value?.type)?.validation"
                          :disabled="isLoading || !agentsLimitation.includes('buttons')"
                        />
                      </div>

                      <div
                        v-if="['whatsapp', 'telegram', 'messenger'].includes(value)"
                        class="column is-12"
                      >
                        <FormKit
                          name="starter_text"
                          type="text"
                          label="Starter text"
                          validation="required:trim"
                          :disabled="isLoading || !agentsLimitation.includes('buttons')"
                        />
                      </div>
                    </div>
                  </template>
                </CustomForm>
              </FormKit>
            </template>
          </CustomForm>
        </div>

        <!-- Sequences -->
        <div v-if="selectedTab === 'sequences'">
          <CustomForm title="Guided Conversations" subtitle="">
            <template #buttons>
              <VButton
                :disabled="isFormDisabled"
                :loading="isLoading"
                type="submit"
                color="primary"
                icon="ic:round-check"
                @click="triggerSubmit"
              >
                Save Changes
              </VButton>
            </template>

            <template #body>
              <MarketingMessage
                v-if="!agentsLimitation.includes('sequences')"
                :plan="plans.standard"
              >
                Upgrade your subscription to unlock this feature!
              </MarketingMessage>

              <VMessage color="info" class="w-100">
                This feature operates solely as an agent button. You can pre-define the AI's
                messages to first gather information or to set the course of the conversation and
                will cost 1 credit per run.
              </VMessage>

              <FormKit
                v-slot="{ value, index }"
                type="repeater"
                name="sequences"
                :up-control="false"
                :down-control="true"
                :add-button="true"
                :insert-control="false"
                :disabled="isLoading || !agentsLimitation.includes('sequences')"
                min="0"
                :sections-schema="{
                  insertControl: {
                    attrs: {
                      disabled: '$value.length >= $max',
                      onClick: '$fns.createCopy($index)',
                      type: 'button',
                    },
                  },
                }"
              >
                <CustomForm :title="`Sequence ${Number(index) + 1}`">
                  <template #body>
                    <FormKit
                      name="uid"
                      type="hidden"
                      label="uid"
                      validation="required:trim"
                      :value="uuid()"
                    />

                    <div class="columns is-multiline">
                      <AgentStatusesSetting
                        :is-disabled="isLoading || !agentsLimitation.includes('sequences')"
                        :status-enabled="['status', 'is_button', 'is_open']"
                        :column-size="4"
                      />

                      <template v-if="value.is_button">
                        <AgentBtnSettings
                          v-if="value.is_button"
                          :is-disabled="isLoading || !agentsLimitation.includes('sequences')"
                        />

                        <div class="column is-12">
                          <FormKit
                            name="text_button"
                            type="text"
                            label="Sequence name (Button)"
                            validation="required:trim"
                            :disabled="isLoading || !agentsLimitation.includes('sequences')"
                          />
                        </div>
                      </template>

                      <div class="column is-12">
                        <FormKit
                          type="repeater"
                          name="messages"
                          :up-control="false"
                          :down-control="true"
                          :add-button="false"
                          :insert-control="true"
                          :disabled="isLoading || !agentsLimitation.includes('sequences')"
                          min="1"
                        >
                          <div class="columns is-multiline">
                            <div class="column is-12">
                              <FormKit
                                name="message"
                                type="textarea"
                                label="AI message"
                                validation="required:trim"
                                :disabled="isLoading || !agentsLimitation.includes('sequences')"
                                rows="1"
                              />
                            </div>
                            <div class="column is-12">
                              <CustomCollapse with-chevron color="#f6f6f7">
                                <template #collapse-item-summary>
                                  <h4 class="mt-0 mb-0 is-inline">
                                    <b>Suggested Answers</b>
                                  </h4>
                                </template>
                                <template #collapse-item-content>
                                  <FormKit
                                    v-slot="{ index: index2 }"
                                    type="repeater"
                                    name="suggestions"
                                    :up-control="false"
                                    :down-control="true"
                                    :add-button="true"
                                    :insert-control="false"
                                    :disabled="isLoading || !agentsLimitation.includes('sequences')"
                                    min="0"
                                  >
                                    <FormKit
                                      type="text"
                                      name="message"
                                      :label="`Suggestion ${Number(index2) + 1}`"
                                      placeholder="Suggestion"
                                      :disabled="
                                        isLoading || !agentsLimitation.includes('sequences')
                                      "
                                    />
                                  </FormKit>
                                </template>
                              </CustomCollapse>
                            </div>
                          </div>
                        </FormKit>
                      </div>
                    </div>
                  </template>
                </CustomForm>
              </FormKit>
            </template>
          </CustomForm>
        </div>

        <!-- Smart Suggestions -->
        <div v-if="selectedTab === 'smart_suggestions'">
          <CustomForm title="Smart Suggestions" subtitle="">
            <template #buttons>
              <VButton
                :disabled="isFormDisabled"
                :loading="isLoading"
                type="submit"
                color="primary"
                icon="ic:round-check"
                @click="triggerSubmit"
              >
                Save Changes
              </VButton>
            </template>

            <template #body>
              <FormKit
                name="smart_suggestions"
                type="group"
                :disabled="isLoading || !agentsLimitation.includes('smart_suggestions')"
              >
                <MarketingMessage
                  v-if="!agentsLimitation.includes('smart_suggestions')"
                  :plan="plans.standard"
                >
                  Upgrade your subscription to unlock this feature!
                </MarketingMessage>

                <VMessage color="info" class="w-100">
                  Smart Suggestions will increase engagement with your AI chatbot by 70% and will
                  cost between 1 credit per suggestion.
                </VMessage>

                <div class="columns is-multiline">
                  <AgentStatusesSetting
                    :is-disabled="isLoading || !agentsLimitation.includes('smart_suggestions')"
                    :status-enabled="['status']"
                    :column-size="6"
                  />
                </div>
              </FormKit>
            </template>
          </CustomForm>
        </div>

        <!-- Accounts -->
        <div v-if="selectedTab === 'accounts'">
          <CustomForm title="Private Accounts" subtitle="">
            <template #buttons>
              <VButton
                :disabled="isFormDisabled"
                :loading="isLoading"
                type="submit"
                color="primary"
                icon="ic:round-check"
                @click="triggerSubmit"
              >
                Save Changes
              </VButton>
            </template>

            <template #body>
              <FormKit
                name="accounts"
                type="group"
                :disabled="isLoading || !agentsLimitation.includes('accounts')"
              >
                <MarketingMessage
                  v-if="!agentsLimitation.includes('accounts')"
                  :plan="plans.professional"
                >
                  Upgrade your subscription to unlock this feature!
                </MarketingMessage>

                <VMessage color="info" class="w-100">
                  This feature operates solely as an agent button. It allows users to create private
                  accounts where they see their conversations and also be limited in their query
                  usage and will cost 1 credit per run.
                </VMessage>

                <CustomForm>
                  <template #body>
                    <div class="columns is-multiline">
                      <AgentStatusesSetting
                        :is-disabled="isLoading || !agentsLimitation.includes('accounts')"
                        :status-enabled="['status', 'is_button', 'is_open']"
                        :column-size="4"
                      />

                      <template v-if="formData.accounts.is_button">
                        <AgentBtnSettings
                          :is-disabled="isLoading || !agentsLimitation.includes('accounts')"
                        />

                        <div class="column is-12">
                          <FormKit
                            name="text_button"
                            type="text"
                            label="Text (Agent button)"
                            validation="required:trim"
                            :disabled="isLoading || !agentsLimitation.includes('accounts')"
                          />
                        </div>
                      </template>
                    </div>
                  </template>
                </CustomForm>

                <CustomForm>
                  <template #body>
                    <div class="columns is-multiline">
                      <div class="column is-6">
                        <FormKit
                          name="text_button_login"
                          type="text"
                          label="Text (Login Button)"
                          validation="required:trim"
                          :disabled="isLoading || !agentsLimitation.includes('accounts')"
                        />
                      </div>
                      <div class="column is-6">
                        <FormKit
                          name="text_button_register"
                          type="text"
                          label="Text (Register Button)"
                          validation="required:trim"
                          :disabled="isLoading || !agentsLimitation.includes('accounts')"
                        />
                      </div>
                      <div class="column is-6">
                        <FormKit
                          name="text_thanks"
                          type="text"
                          label="Outro text"
                          validation="required:trim"
                          :disabled="isLoading || !agentsLimitation.includes('accounts')"
                        />
                      </div>
                      <div class="column is-6">
                        <FormKit
                          name="email_login_title"
                          type="text"
                          label="Security Token E-mail Title"
                          validation="required:trim"
                          :disabled="isLoading || !agentsLimitation.includes('accounts')"
                        />
                      </div>
                      <div class="column is-6">
                        <FormKit
                          name="text_security_token"
                          type="text"
                          label="Security token label"
                          validation="required:trim"
                          :disabled="isLoading || !agentsLimitation.includes('accounts')"
                        />
                      </div>
                      <div class="column is-6">
                        <FormKit
                          name="text_email_sent"
                          type="text"
                          label="Security token sent"
                          validation="required:trim"
                          :disabled="isLoading || !agentsLimitation.includes('accounts')"
                        />
                      </div>
                      <div class="column is-6">
                        <FormKit
                          name="text_registered"
                          type="text"
                          label="Go to login"
                          validation="required:trim"
                          :disabled="isLoading || !agentsLimitation.includes('accounts')"
                        />
                      </div>
                      <div class="column is-6">
                        <FormKit
                          name="text_not_registered"
                          type="text"
                          label="Go to registration"
                          validation="required:trim"
                          :disabled="isLoading || !agentsLimitation.includes('accounts')"
                        />
                      </div>
                    </div>
                  </template>
                </CustomForm>

                <CustomForm>
                  <template #body>
                    <div class="columns is-multiline">
                      <div class="column is-12">
                        <FormKit
                          type="taglist"
                          name="emails"
                          label="Receive an e-mail when a new account is created"
                          placeholder="Enter email addresses"
                          :options="[userSession?.user?.email]"
                          :disabled="isLoading || !agentsLimitation.includes('accounts')"
                          :allow-new-values="true"
                          :open-on-click="true"
                          max="10"
                          min="0"
                        />
                      </div>
                    </div>
                  </template>
                </CustomForm>

                <CustomForm>
                  <template #body>
                    <div class="columns is-multiline">
                      <div class="column is-12">
                        <FormKit
                          name="available_monthly_queries"
                          type="number"
                          label="Monthly credit limit per account (Resets on the 1st of each month)"
                          min="1"
                          step="1"
                          value="1000"
                          validation="required:trim|number"
                          :disabled="isLoading || !agentsLimitation.includes('accounts')"
                        />
                      </div>
                    </div>
                  </template>
                </CustomForm>

                <CustomCollapse with-chevron color="#f6f6f7">
                  <template #collapse-item-summary>
                    <h4 class="mt-0 mb-0 is-inline">
                      <b>Form</b>
                    </h4>
                  </template>
                  <template #collapse-item-content>
                    <FormKit
                      name="inputs"
                      type="list"
                      :disabled="isLoading || !agentsLimitation.includes('accounts')"
                    >
                      <div class="table-container">
                        <table class="table w-100">
                          <tr>
                            <th>Label</th>
                            <th>Type</th>
                            <th>Status</th>
                            <th>Required</th>
                          </tr>

                          <tr v-for="input of formData.accounts.inputs" :key="input.name">
                            <FormKit
                              :name="input.name"
                              type="group"
                              :disabled="isLoading || !agentsLimitation.includes('accounts')"
                            >
                              <td>
                                <FormKit
                                  name="label"
                                  type="text"
                                  :classes="{ outer: 'w-50 m-0' }"
                                  :disabled="isLoading || !agentsLimitation.includes('accounts')"
                                  validation="required:trim"
                                />
                              </td>
                              <td>{{ input.type }}</td>
                              <td>
                                <FormKit
                                  label=""
                                  name="status"
                                  type="toggle"
                                  :off-value="false"
                                  :on-value="true"
                                  label-position="secondary"
                                  value-label-display="inner"
                                  off-value-label="OFF"
                                  on-value-label="ON"
                                  validation="required:trim"
                                  :disabled="isLoading || !agentsLimitation.includes('accounts')"
                                />
                              </td>
                              <td>
                                <FormKit
                                  label=""
                                  name="required"
                                  type="toggle"
                                  :off-value="false"
                                  :on-value="true"
                                  label-position="secondary"
                                  value-label-display="inner"
                                  off-value-label="OFF"
                                  on-value-label="ON"
                                  validation="required:trim"
                                  :disabled="isLoading || !agentsLimitation.includes('accounts')"
                                />
                              </td>
                            </FormKit>
                          </tr>
                        </table>
                      </div>
                    </FormKit>
                  </template>
                </CustomCollapse>
              </FormKit>
            </template>
          </CustomForm>
        </div>
      </template>
    </VTabs>
  </FormKit>
</template>
