<script setup lang="ts">
import { ref } from 'vue'
import { ITab } from '/@src/interfaces'
import Install from '/@src/pages/chatbots/install.vue'
import Preview from '/@src/pages/chatbots/preview.vue'
import { ICONS } from '/@src/resources/files/constant'

const props = defineProps({
  title: {
    type: String,
    default: () => '',
    required: false,
  },
  widget: {
    type: Object,
    default: () => {},
    required: true,
  },
})

// Reactive data
const tabs = ref<ITab[]>([
  {
    label: 'Preview',
    value: 'preview',
    icon: ICONS.pages.preview,
  },
  {
    label: 'Install',
    value: 'install',
    icon: ICONS.pages.install,
  },
])
const selectedTab = ref(tabs.value[0]?.value)
</script>

<template>
  <VTabs v-model:selected="selectedTab" :tabs="tabs" :vertical="true">
    <template #tab>
      <!-- Preview -->
      <div v-if="selectedTab === 'preview'">
        <Preview :widget="props.widget" />
      </div>

      <!-- Install -->
      <div v-if="selectedTab === 'install'">
        <Install :widget="props.widget" />
      </div>
    </template>
  </VTabs>
</template>
