<script setup lang="ts">
import { useHead } from '@vueuse/head'
import { ref } from 'vue'
import Profile from '/@src/pages/settings/profile.vue'
import Team from '/@src/pages/settings/team.vue'
import Whitelabel from '/@src/pages/settings/whitelabel.vue'
import { ICONS } from '/@src/resources/files/constant'
import { useViewWrapper } from '/@src/stores'

// Reactive data
const tabs = ref([
  { label: 'Profile', value: 'profile', icon: ICONS.pages.account, component: Profile },
  {
    label: 'White-Label',
    value: 'whitelabel',
    icon: ICONS.pages.whitelabel,
    component: Whitelabel,
  },
  { label: 'Team', value: 'team', icon: ICONS.pages.users, component: Team },
])
const title = ref('Settings')
const selectedTab = ref(tabs.value[0]?.value)

// Composable
const viewWrapper = useViewWrapper()
viewWrapper.setPageTitle(title.value)
viewWrapper.setPageIcon(ICONS.pages.chats)
useHead({ title: title.value })
</script>

<template>
  <VCard class="h-100 p-0">
    <VTabs v-model:selected="selectedTab" :tabs="tabs" :vertical="true" :data="{}">
      <template #title>
        <VBlock>
          <h1 class="title is-5">Menu</h1>
        </VBlock>
      </template>
    </VTabs>
  </VCard>
</template>
