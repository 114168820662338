<script setup lang="ts">
import { computed, onMounted, reactive, ref, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
import { getNode } from '@formkit/core'
import { useNotyf } from '/@src/composable/useNotyf'
import { EventHelper, HttpHelper } from '/@src/helpers'

const emit = defineEmits(['update:modalFormProps', 'submit', 'close', 'ignore'])
const props = defineProps({
  modalFormProps: {
    type: Object,
    default: () => {},
    required: true,
  },
})

// eslint-disable-next-line vue/no-setup-props-destructure
const { widgetUid, updateUid, open } = props.modalFormProps

// Const data
const formId = 'formWidgetAccount'

// Reactive data
const isLoading = ref(false)
const formData: any = ref({})
const formState: any = reactive({})

// Computed
const title = computed(() => {
  return updateUid ? 'Update account' : 'Add account'
})

const isFormDisabled = computed(() => {
  const isInvalid = !formState.valid

  if (isLoading.value || isInvalid) {
    return true
  }

  return false
})

// Composable
const notyf = useNotyf()
const { t } = useI18n()

// Lifecycle hooks
onMounted(async () => {
  try {
    Object.assign(formState, toRefs(getNode(formId)?.context?.state || {}))

    isLoading.value = true

    if (updateUid) {
      const response = await HttpHelper.get(`/accounts/${updateUid}`)

      formData.value = { ...response }
    }
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
})

// Functions
const triggerSubmit = () => {
  getNode(formId)?.submit()
}

const onSubmit = async (fields: any) => {
  try {
    if (isLoading.value) {
      return
    }

    EventHelper.push('created_account')

    isLoading.value = true

    const {
      first_name,
      last_name,
      email,
      phone,
      address,
      website,
      gender,
      company,
      job_title,
      job_role,
      message,
      available_monthly_queries,
    } = fields

    const response = await HttpHelper.post('/accounts', updateUid, {
      widget_uid: widgetUid,
      first_name,
      last_name,
      email,
      phone,
      address,
      website,
      gender,
      company,
      job_title,
      job_role,
      message,
      available_monthly_queries,
    })

    emit('submit', response)

    notyf.success(t('notifications.success'))

    // Close modal with refresh
    closeModal(true)
  } catch (error: any) {
    console.error(error)

    emit('submit', error)
  } finally {
    isLoading.value = false
  }
}

const closeModal = (refresh = false) => {
  emit('update:modalFormProps', { open: false, refresh })

  if (!refresh) {
    emit('close')
  }
}
</script>

<template>
  <CustomModal
    size="big"
    :open="open"
    :title="title"
    :override-modal="false"
    :noscroll="false"
    :noclose="true"
    @close="closeModal"
  >
    <FormKit
      :id="formId"
      v-model="formData"
      type="form"
      :disabled="isLoading"
      :actions="false"
      @submit="onSubmit"
    >
      <VLoader v-if="isLoading" :translucent="true" :opaque="true" />

      <div class="columns is-multiline">
        <div class="column is-6">
          <FormKit
            type="text"
            label="First name"
            name="first_name"
            validation="required:trim"
            placeholder=""
          />
        </div>
        <div class="column is-6">
          <FormKit
            type="text"
            label="Last name"
            name="last_name"
            validation="trim"
            placeholder=""
          />
        </div>
        <div class="column is-6">
          <FormKit
            type="select"
            label="Gender"
            name="gender"
            validation="trim"
            placeholder=""
            :options="{
              male: 'Male',
              female: 'Female',
            }"
          />
        </div>
        <div class="column is-6">
          <FormKit type="text" label="Address" name="address" validation="trim" placeholder="" />
        </div>
        <div class="column is-6">
          <FormKit
            type="text"
            label="E-mail"
            name="email"
            validation="required:trim|email"
            placeholder=""
          />
        </div>
        <div class="column is-6">
          <FormKit type="text" label="Phone" name="phone" validation="trim" placeholder="" />
        </div>

        <div class="column is-6">
          <FormKit type="url" label="Website" name="website" validation="trim|url" placeholder="" />
        </div>

        <div class="column is-6">
          <FormKit type="text" label="Company" name="company" validation="trim" placeholder="" />
        </div>
        <div class="column is-6">
          <FormKit
            type="text"
            label="Job title"
            name="job_title"
            validation="trim"
            placeholder=""
          />
        </div>
        <div class="column is-6">
          <FormKit type="text" label="Job role" name="job_role" validation="trim" placeholder="" />
        </div>
        <div class="column is-12">
          <FormKit
            type="textarea"
            label="Message"
            name="message"
            validation="trim"
            placeholder=""
          />
        </div>
        <div class="column is-6">
          <FormKit
            name="available_monthly_queries"
            type="number"
            label="Available credits for the month"
            min="1"
            step="1"
            validation="required:trim|number"
            placeholder=""
            help="Resets on the 1st of each month"
          />
        </div>
        <div class="column is-6">
          <FormKit
            type="text"
            label="Last reset date"
            name="available_monthly_queries_reset_at"
            validation="trim"
            placeholder=""
            :disabled="true"
          />
        </div>
      </div>
    </FormKit>

    <template #action>
      <div class="form-footer">
        <div class="left"></div>

        <div class="right">
          <VButton
            :disabled="isFormDisabled"
            :loading="isLoading"
            type="submit"
            color="primary"
            icon="ic:round-check"
            @click="triggerSubmit"
          >
            Save Changes
          </VButton>
        </div>
      </div>
    </template>
  </CustomModal>
</template>
