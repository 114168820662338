<script setup lang="ts">
import { onBeforeMount, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { StripeHelper } from '/@src/helpers'
import { useUserSession } from '/@src/stores'

// Composable
const route: any = useRoute()
const userSession = useUserSession()
const { t } = useI18n()

// Reactive data
const modalMarketingProps: any = ref({
  open: false,
  title: 'Upgrade your messaging capacity now and never miss important conversations',
  translationKey: 'errors.subscription/messages-limit-reached',
})

// Lifecycle hooks
onBeforeMount(() => {
  if (
    !['subscriptions'].includes(route?.name) &&
    (!userSession?.usage?.widgets?.leftover ||
      (userSession?.usage?.messages?.leftover !== undefined &&
        userSession?.usage?.messages?.leftover <= 0))
  ) {
    modalMarketingProps.value.open = true
  }
})
</script>

<template>
  <div
    v-if="
      StripeHelper.isSubscriptionActive(userSession?.subscription) &&
      typeof userSession?.usage?.messages !== 'undefined'
    "
    class="column p-0 driverjs-widget-message-credits"
  >
    <RouterLink :to="{ path: '/subscriptions' }">
      <VTag v-if="userSession?.usage?.messages?.leftover > 0" color="success">
        {{
          t('widgets.messages_leftover.leftover', {
            count: userSession?.usage?.messages?.leftover,
            total: userSession?.usage?.messages?.available,
          })
        }}
      </VTag>
      <VTag v-else-if="userSession?.usage?.messages?.leftover <= 0" color="danger">
        <MarketingModal v-model="modalMarketingProps" />

        {{ t('widgets.messages_leftover.no_leftover') }}
      </VTag>
    </RouterLink>
  </div>
</template>
