import { IQueryParameters } from '/@src/interfaces'

export default class UrlHelper {
  public static getQueryParams = (url: string) => {
    const queryParams = {}
    const queryString = url.split('?')[1]

    if (queryString) {
      const paramPairs = queryString.split('&')

      paramPairs.forEach((paramPair) => {
        const [key, value] = paramPair.split('=')

        if (key && value) {
          // @ts-ignore
          queryParams[key] = decodeURIComponent(value)
        }
      })
    }

    return queryParams
  }

  public static getWidgetQueryParams = (): IQueryParameters => {
    const queryParams: any = UrlHelper.getQueryParams(window.location.href)

    // Cast types
    const debug: boolean = queryParams?.debug === 'true'
    const widgetUid: string = queryParams?.uid
    const isBubble: boolean = queryParams?.is_bubble === 'true'
    const welcomeMessage: string | null = queryParams?.welcome_message || null
    const share: string | null = queryParams?.share || null
    const isAdmin: boolean = queryParams?.is_admin === 'true'
    const focus: boolean = queryParams?.focus === 'true'
    const botType: string = queryParams?.bot_type || 'default'
    const botHeight: string = queryParams?.bot_height || '100%'
    const botBgColor: string = queryParams?.bot_bg_color || null
    const botAutofocus: boolean = queryParams?.bot_autofocus === 'true'
    const botOverrideOpener: string = queryParams?.bot_override_opener || ''
    const userId: string = queryParams?.user_id || ''
    const userEmail: string = queryParams?.user_email || ''
    const userFirstname: string = queryParams?.user_firstname || ''
    const userLastname: string = queryParams?.user_lastname || ''
    const userTags: any = JSON.parse(queryParams?.user_tags || '[]')
    const userMetadata: any = JSON.parse(queryParams?.user_metadata || '{}')

    return {
      debug,
      widgetUid,
      isBubble,
      welcomeMessage,
      share,
      isAdmin,
      focus,
      botType,
      botHeight,
      botBgColor,
      botAutofocus,
      botOverrideOpener,
      userId,
      userEmail,
      userFirstname,
      userLastname,
      userTags,
      userMetadata,
    }
  }
}
