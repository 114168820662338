<script setup lang="ts">
import { computed, onBeforeMount, onMounted, Ref, ref } from 'vue'
import { PwaHelper, StringHelper, WindowHelper } from '/@src/helpers'
import { IButton } from '/@src/interfaces'
import { useWidget } from '/@src/stores'

// Composable
const widgetStore = useWidget()

// Reactive data
const agents = ref()
const agentsLimitation: Ref<string[]> = ref([])
const isPWAInstalled = ref(false)
const chatAgentsContainerHeight = ref(0)

// Computed
const flattenedAgentsArray = computed(() => {
  const originalAgents: object = { ...agents.value }
  const enabledAgents: IButton[] = []

  if (originalAgents) {
    for (const [key, agent] of Object.entries(originalAgents)) {
      if (agentsLimitation.value.includes(key)) {
        if (Array.isArray(agent)) {
          agent.forEach((item) => {
            if (
              item?.status &&
              (key === 'buttons' || key === 'question_answer' || item?.is_button === true)
            ) {
              enabledAgents.push({ name: key, ...item })
            }
          })
        } else {
          // Process single agents
          if (
            agent?.status &&
            (key === 'buttons' || key === 'question_answer' || agent?.is_button === true)
          ) {
            enabledAgents.push({ name: key, ...agent })
          }
        }
      }
    }
  }

  if (Object.keys(widgetStore.state.messages).length <= 1) {
    const markAsResolveAgent = enabledAgents.findIndex(
      (agent: any) => agent && agent?.name === 'mark_as_resolved'
    )

    const continueAsEmailAgent = enabledAgents.findIndex(
      (agent: any) => agent && agent?.name === 'transcribe'
    )

    markAsResolveAgent !== -1 && enabledAgents.splice(markAsResolveAgent, 1)
    continueAsEmailAgent !== -1 && enabledAgents.splice(continueAsEmailAgent, 1)
  }

  return enabledAgents
})

// Lifecycle hooks
onBeforeMount(() => {
  try {
    agentsLimitation.value = widgetStore.getPlanLimitations('agents')
    agents.value = widgetStore.getWidgetAgents()
  } catch (error) {}
})

onMounted(async () => {
  try {
    isPWAInstalled.value = await PwaHelper.isPWAInstalled()
  } catch (error) {}
})
</script>

<template>
  <div
    v-if="
      !widgetStore.getWidget()?.is_vocal_only &&
      agentsLimitation &&
      flattenedAgentsArray &&
      Object.keys(flattenedAgentsArray).length &&
      !widgetStore.state.isChatBoxDisabled
    "
    ref="chatAgentsContainerHeight"
    class="chat-agents-container"
  >
    <div v-dragscroll class="chat-agents ml-1 mr-1 mt-3 driverjs-chatbot-actions">
      <div class="scroll-columns">
        <VButton
          v-for="(agent, index) of flattenedAgentsArray"
          :key="index"
          :style="widgetStore.getDynamicStylesByObject(agent)"
          class="scroll-column is-narrow no-border chat-radius-rounded"
          @click="widgetStore.onAgentClick(agent)"
        >
          <p
            :style="{
              color: widgetStore.getDynamicStylesByObject(agent)?.color,
              fontSize: widgetStore.getDynamicStylesByObject(agent)?.fontSize,
            }"
          >
            {{ StringHelper.capitalizeFirstLetter(agent?.text_button) }}
          </p>
        </VButton>
      </div>
    </div>
  </div>
</template>
