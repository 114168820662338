<script setup lang="ts">
import { useDebounceFn } from '@vueuse/core'
import { computed, ref, Ref } from 'vue'
import { onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { ITab } from '/@src/interfaces'

export type VTabsType = 'boxed' | 'toggle' | 'rounded'
export type VTabsAlign = 'centered' | 'right'
export interface VTabsItem {
  label: string
  value: string
  icon?: string
  tag?: string
  svg?: string
  href?: string
  target?: '_self' | '_blank'
  component: any
}
export interface VTabsCategory {
  category: string
  items: VTabsItem[]
}
export interface VTabsProps {
  class?: string
  tabs: (VTabsItem | VTabsCategory)[]
  type?: VTabsType
  align?: VTabsAlign
  slider?: boolean
  slow?: boolean
  disabled?: boolean
  vertical?: boolean
  data: {
    type: Object
    default: () => {}
    required: false
  }
}

const emit = defineEmits<{
  (e: 'update:selected', value: string): void
}>()

const route = useRoute()
const props = withDefaults(defineProps<VTabsProps>(), {
  type: undefined,
  align: undefined,
})
const qsTab: string = String(route.query.tab) ?? undefined
const selectedTab: Ref<VTabsItem> = ref(
  qsTab && qsTab !== 'undefined'
    ? (props.tabs.find((tab) => 'value' in tab && tab.value === qsTab) as VTabsItem)
    : (props.tabs[0] as VTabsItem)
)
const isCollapsed = ref(true)
let isTransitioning = false

// Computed
const sliderClass = computed(() => {
  if (!props.slider) return ''
  if (props.type === 'rounded') {
    return props.tabs.length === 3 ? 'is-triple-slider' : props.tabs.length === 2 ? 'is-slider' : ''
  }
  if (!props.type) {
    return props.tabs.length === 3
      ? 'is-squared is-triple-slider'
      : props.tabs.length === 2
        ? 'is-squared is-slider'
        : ''
  }
  return ''
})

const isCategorized = computed(() => {
  return props.tabs.some((tab) => 'category' in tab)
})

onMounted(() => {
  if (selectedTab?.value?.value) {
    emit('update:selected', selectedTab?.value?.value)
  }
})

function toggle(tab: ITab) {
  if (tab?.href) {
    window.open(tab.href, tab.target ?? '_self')

    return
  }

  if (props.disabled) {
    return
  }

  selectedTab.value = tab

  emit('update:selected', tab?.value)
}

const debouncedhandleMouseEnter = useDebounceFn(() => {
  if (!isTransitioning) {
    isTransitioning = true
    isCollapsed.value = false
    setTimeout(() => {
      isTransitioning = false
    }, 300) // Match the transition duration
  }
}, 100)

const debouncedhandleMouseLeave = useDebounceFn(() => {
  if (!isTransitioning) {
    isTransitioning = true
    isCollapsed.value = true
    setTimeout(() => {
      isTransitioning = false
    }, 300) // Match the transition duration
  }
}, 100)
</script>

<template>
  <div class="columns h-100 is-multiline">
    <div v-if="$slots['shared-top']" class="column is-12">
      <slot name="shared-top"> </slot>
    </div>

    <div
      class="column is-narrow tabs-inner"
      :class="{ 'is-collapsed': isCollapsed }"
      @mouseenter="debouncedhandleMouseEnter"
      @mouseleave="debouncedhandleMouseLeave"
    >
      <div
        class="tabs"
        :class="[
          props.align === 'centered' && 'is-centered mx-auto',
          props.align === 'right' && 'is-right',
          props.type === 'rounded' && !props.slider && 'is-toggle is-toggle-rounded',
          props.type === 'toggle' && 'is-toggle',
          props.type === 'boxed' && 'is-boxed',
          props.vertical ? 'tabs-vertical mb-0' : 'mb-5',
        ]"
      >
        <ul class="custom-scrollbar">
          <template v-if="isCategorized">
            <template v-for="(category, catIndex) in tabs" :key="catIndex">
              <b :class="{ 'mt-5': catIndex !== 0 }">{{ category.category }}</b>
              <span class="w-100 mt-1 mb-1 separator-horizontal"></span>

              <li
                v-for="(tab, key) in category.items"
                :key="key"
                :class="[selectedTab?.value === tab.value && 'is-active', `driverjs-${tab.value}`]"
                @click="toggle(tab)"
              >
                <a tabindex="0" :disabled="props.disabled">
                  <img
                    v-if="tab.svg"
                    :src="tab.svg"
                    alt=""
                    :style="{
                      height: '1.25em',
                      width: '1.25em',
                    }"
                    class="mr-3"
                  />
                  <VIcon
                    v-if="tab.icon"
                    class="icon"
                    :icon="tab.icon"
                    :class="{ 'tab-active': true }"
                    :isClickable="true"
                  />
                  <span class="tab-label">
                    {{ tab.label }}
                    <span v-if="tab.tag" class="custom-tag"> {{ tab.tag }} </span>
                  </span>
                </a>
              </li>
            </template>
          </template>
          <template v-else>
            <li
              v-for="(tab, key) in tabs"
              :key="key"
              :class="[selectedTab?.value === tab.value && 'is-active', `driverjs-${tab.value}`]"
              @click="toggle(tab)"
            >
              <a tabindex="0" :disabled="props.disabled">
                <img
                  v-if="tab.svg"
                  :src="tab.svg"
                  alt=""
                  :style="{
                    height: '1.25em',
                    width: '1.25em',
                  }"
                  class="mr-3"
                  :class="{ 'tab-active': true }"
                />
                <VIcon
                  v-if="tab.icon"
                  class="icon"
                  :icon="tab.icon"
                  :class="{ 'tab-active': true }"
                  :isClickable="true"
                />
                <span class="tab-label">
                  {{ tab.label }}
                  <span v-if="tab.tag" class="custom-tag"> {{ tab.tag }} </span>
                </span>
              </a>
            </li>
          </template>

          <li v-if="sliderClass" class="tab-naver"></li>
        </ul>
      </div>
    </div>

    <div class="column is-narrow separator-vertical p-0 m-0"></div>

    <div class="column" :class="selectedTab?.class">
      <Suspense v-if="selectedTab?.component">
        <component
          :is="selectedTab?.component"
          v-bind="{ ...selectedTab?.component?.props, ...props.data, title: selectedTab?.label }"
          :key="selectedTab?.value"
        />
      </Suspense>

      <slot
        v-else
        :key="selectedTab?.value"
        name="tab"
        :active-value="selectedTab"
        v-bind="props.data"
      >
      </slot>
    </div>

    <template v-if="$slots['tab-2']">
      <div class="column is-narrow separator-vertical p-0 m-0"></div>

      <slot name="tab-2"> </slot>
    </template>

    <div v-if="$slots['shared-bottom']" class="column is-12">
      <slot name="shared-bottom"> </slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
::-webkit-scrollbar {
  height: 3px !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgb(0 0 0 / 20%);
}

/*
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.1);
  overflow-x: auto;
}
*/

.custom-scrollbar {
  overflow: hidden;
}

.tabs-inner {
  transition:
    width 0.3s ease,
    opacity 0.3s ease;
  width: 250px;
  // min-width: 250px;
  // border-right: 1px solid var(--border);

  transition: width 0.3s ease;

  &.is-collapsed {
    width: 70px;
    opacity: 0.9;

    .tab-label {
      margin-left: 4px;
      display: none;
    }

    .is-active {
      svg {
        color: var(--primary);
      }

      &.is-active::after {
        display: none !important;
      }
    }

    .tabs .icon:first-child {
      margin: 0;
    }

    li {
      padding: 0px;
    }

    a {
      padding: 7px;
      height: 45px;
      // width: 45px;
    }

    svg {
      vertical-align: 2.25em !important;
      width: 2.25em !important;
      height: 2.25em !important;
    }
  }
}

.tabs {
  ul {
    border: 1px solid transparent !important;

    li {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      border-radius: 8px;
      transition: all 0.3s;
      margin-bottom: 5px;
      border: 1px solid transparent !important;

      &:last-child {
        margin-bottom: 0 !important;
      }

      &:hover {
        border-radius: 8px;
        background: var(--fade-grey-light-3);
      }

      &.is-active {
        box-shadow: var(--light-box-shadow) !important;
        border: 1px solid var(--border) !important;
        background: transparent !important;

        &.is-active::after {
          content: '\f061'; /* FontAwesome check icon */
          font-family: 'Font Awesome 5 Free';
          font-weight: 900;
          color: var(--primary);
          margin: 0 10px 0 0;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex: 1;
        }
      }

      a {
        color: var(--dark-text);
        border: none !important;
        outline: none !important;
      }

      a:hover,
      a:focus {
        color: var(--primary);
      }

      /*
      svg {
        color: var(--primary-grey);
      }
      */
    }
  }
}

.tabs-vertical {
  display: flex;
  flex-direction: column;

  .custom-scrollbar {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .tabs-inner {
    width: 100%;
    min-width: 100%;
    border-bottom: 1px solid var(--border);

    &.is-collapsed {
      width: 100%;

      .tab-label {
        display: block;
      }
    }
  }

  .tabs ul li.is-active::after {
    display: none;
  }
}
</style>
