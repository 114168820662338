<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useUserSession } from '/@src/stores'

const props = defineProps<{
  text: boolean
  light?: boolean
  width?: string
  height?: string
}>()

// Composable
const router = useRouter()
const userSession = useUserSession()

// Const data
// const CLIENT_URL = import.meta.env.VITE_CLIENT_URL
const S3_PUBLIC = import.meta.env.VITE_S3_PUBLIC

// Computed
const logo = computed(() => {
  const defaultLogo = props.text
    ? `${S3_PUBLIC}/public/logo_black.svg`
    : `${S3_PUBLIC}/public/favicon_black.svg`

  // if (window.location.origin !== CLIENT_URL) {
  return userSession?.logo ? userSession?.logo : defaultLogo
  // }

  // return defaultLogo
})
// Reactive data
const isLoading = ref(false)

router.beforeEach(() => {
  isLoading.value = true
})

router.afterEach(() => {
  setTimeout(() => {
    isLoading.value = false
  }, 200)
})
</script>

<template>
  <img
    :src="logo"
    class="logo"
    :class="[props.light && 'is-light']"
    :style="{ width: props.width, height: props.height }"
    alt="Logo"
    aria-label="Logo"
  />
</template>

<style lang="scss" scoped>
.logo {
  fill: var(--primary);
  display: block;
  height: 45px;
}

.right {
  fill: var(--primary-dark-7);
}

.bottom {
  fill: var(--primary);
}

.left {
  fill: var(--primary-light-15);
}

.is-roll,
svg:hover {
  .right {
    animation: is-roll-right 8s ease infinite;
    animation-fill-mode: forwards;
    animation-delay: 0s;
  }

  .left {
    animation: is-roll-left 8s ease infinite;
    animation-fill-mode: forwards;
    animation-delay: 0s;
  }

  .bottom {
    animation: is-roll-bottom 8s ease infinite;
    animation-fill-mode: forwards;
    animation-delay: 0s;
  }
}

.is-light {
  .right {
    fill: var(--white);
    animation: none;
  }

  .bottom {
    fill: #fffc;
    animation: none;
  }

  .left {
    fill: #fffa;
    animation: none;
  }
}

@keyframes is-roll-right {
  0% {
    fill: var(--primary-dark-7);
  }

  33% {
    fill: var(--primary-light-8);
  }

  66% {
    fill: var(--primary-light-40);
  }

  100% {
    fill: var(--primary-dark-7);
  }
}

@keyframes is-roll-bottom {
  0% {
    fill: var(--primary);
  }

  33% {
    fill: var(--primary-light-24); // darken(#1ee8c0, 7%);
  }

  66% {
    fill: var(--primary-light-20);
  }

  100% {
    fill: var(--primary);
  }
}

@keyframes is-roll-left {
  0% {
    fill: var(--primary-light-15);
  }

  33% {
    fill: var(--primary-light-12); // lighten(#1ee8c0, 15%);
  }

  66% {
    fill: var(--primary-light-15);
  }

  100% {
    fill: var(--primary-light-15);
  }
}
</style>
