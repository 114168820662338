export default class ObjectHelper {
  public static convertToFormData(dataObject: object) {
    const formData = new FormData()

    for (const [key, value] of Object.entries(dataObject)) {
      if (value) {
        if (value instanceof File) {
          formData.append(key, value, value.name)
        } else if (Array.isArray(value) && value.every((item) => item instanceof File)) {
          // For arrays of files
          value.forEach((file, index) => {
            formData.append(`${key}[${index}]`, file, file.name)
          })
        } else if (typeof value === 'object' && value !== null) {
          // If it's a nested object, convert it into a JSON string
          formData.append(key, JSON.stringify(value))
        } else {
          formData.append(key, value)
        }
      }
    }

    return formData
  }

  public static groupBy = (array: Array<any>, key: string): object => {
    const results: { [key: string]: any } = {}

    for (const element of array) {
      const keyValue = element[key]

      if (!results[keyValue]) {
        results[keyValue] = []
      }

      results[keyValue].push(element)
    }

    return results
  }

  public static searchKey(
    obj: object,
    searchKey: string,
    searchString: string,
    returnKey: string
  ): any {
    // @ts-ignore
    if (obj && obj?.[searchKey] === searchString) {
      // @ts-ignore
      return obj?.[returnKey]
    }

    if (obj) {
      for (const [k, v] of Object.entries(obj)) {
        if (typeof v === 'object') {
          const value = ObjectHelper.searchKey(
            // @ts-ignore
            obj?.[k],
            searchKey,
            searchString,
            returnKey
          )

          if (value) {
            return value
          }
        }
      }
    }

    return false
  }

  public static getNestedObject(nestedObj: any, pathArr: string[]) {
    return pathArr.reduce(
      (obj, key) => (obj && obj[key] !== 'undefined' ? obj[key] : undefined),
      nestedObj
    )
  }

  public static formatOptions(options: any, labelKey: string, valueKey?: string) {
    const _options: any[] = []

    _options.push({
      label: 'Choisir une valeur',
      value: null,
    })

    if (options && options.length > 0) {
      for (const option of options) {
        const label = ObjectHelper.getNestedObject(option, labelKey.split('.'))

        _options.push({ label: String(label), value: valueKey ? option?.[valueKey] : option })
      }
    }

    return _options
  }

  public static formatRepeater(array: object[], keys?: string[]) {
    if (array && Array.isArray(array) && array.length) {
      return array.map((item: any) => {
        let value = {}

        if (keys && keys.length) {
          for (const key of keys) {
            if (item.hasOwnProperty(key)) {
              value = {
                [key]: item[key],
              }
            }
          }
        } else {
          value = item
        }

        return value
      })
    }

    return [{}]
  }

  public static extractKeyValue(array: object[], key: string): any[] {
    const values: string[] = []

    // @ts-ignore
    if (array && Array.isArray(array) && array.length) {
      array.forEach((item: any) => {
        const value = ObjectHelper.getNestedObject(item, key.split('.'))

        if (value) {
          values.push(value)
        }
      })
    }

    return [...new Set(values)]
  }

  public static findByString(array: object[], searchKey: string, searchString: string): any {
    if (array && array.length && searchKey && searchString) {
      return array.find((item: any) => item?.[searchKey] === searchString)
    }

    return null
  }

  public static filterByArray(array: object[], searchKey: string, searchArray: string[]): any {
    if (array && array.length && searchKey && searchArray) {
      return array.filter((item: any) => {
        if (searchArray.includes(item?.[searchKey])) {
          return true
        }

        return false
      })
    }

    return []
  }

  public static intersection(array1: object[], array2: object[], key: string): any[] {
    // @ts-ignore
    return array1.filter((item1) =>
      array2.some((item2) => {
        // @ts-ignore
        const value1 = item1 !== null && typeof item1 === 'string' ? item1 : item1?.[key]
        // @ts-ignore
        const value2 = item2 !== null && typeof item2 === 'string' ? item2 : item2?.[key]

        return value1 === value2
      })
    )
  }

  public static doesRepeaterHaveValue(array: object[], key: string) {
    if (array && Array.isArray(array)) {
      return !!array.find((item: any) => {
        if (item.hasOwnProperty(key) && item[key] !== null) {
          return true
        }
      })
    }

    return false
  }

  public static removeNullKeys(obj: object) {
    const newObj: any = {}

    for (const [key, value] of Object.entries(obj)) {
      if (typeof value !== 'undefined' && value !== null) {
        newObj[key] = value
      }
    }

    return newObj
  }

  public static isJSON(str: string) {
    if (!str) {
      false
    }

    try {
      const json = JSON.parse(str.trim())
      return typeof json === 'object'
    } catch (e) {
      return false
    }
  }
}
