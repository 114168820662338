<script setup lang="ts">
import { onBeforeMount, PropType, ref, watch } from 'vue'
import ApexChart from 'vue3-apexcharts'
import { useRoute } from 'vue-router'
import { DateHelper, HttpHelper } from '/@src/helpers'
import { IFilter } from '/@src/interfaces'
import { ICONS } from '/@src/resources/files/constant'
import { useUserSession } from '/@src/stores'

const props = defineProps({
  title: {
    type: String,
    default: () => '',
    required: false,
  },
  widget: {
    type: Object,
    default: () => {},
    required: true,
  },
  filters: {
    type: Array as PropType<IFilter[]>,
    default: () => [],
    required: false,
  },
})

// Composable
const userSession = useUserSession()
const route = useRoute()

// Const data
const widgetUid: string = Array.isArray(route?.params?.uid)
  ? route.params.uid[0]
  : route?.params?.uid
const currentDate = DateHelper.getNow()
const years = [2024, 2023]
const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
const selectedYear = ref()
const selectedMonth = ref()
const statsLimitation = ref()
const stats = ref()

// Reactive data
const isLoading = ref(true)
const chartOptions = ref()
const isLoadingChart = ref(false)

// Lifecycle hooks
onBeforeMount(() => {
  try {
    statsLimitation.value = userSession?.getPlanLimitations('statistics')
    selectedYear.value = currentDate.year
    selectedMonth.value = months[currentDate.month - 1]
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
})

// Functions
const init = async (year: number, monthString: string) => {
  try {
    selectedYear.value = year
    selectedMonth.value = monthString

    stats.value = await HttpHelper.get(
      `/stats/monthly/${widgetUid}?year=${selectedYear.value}&month=${
        months.indexOf(selectedMonth.value) + 1
      }`
    )

    chartOptions.value = {
      series: [stats.value?.series?.count_chats, stats.value?.series?.sum_queries],
      colors: ['#5e44ff', '#0e111b'],
      chart: {
        width: '100%',
        height: 500,
        type: 'line',
        toolbar: {
          show: true,
        },
        foreColor: '#999',
        stacked: true,
        dropShadow: {
          enabled: true,
          enabledSeries: [0],
          top: -2,
          left: 2,
          blur: 5,
          opacity: 0.06,
        },
      },
      tooltip: {
        x: {
          format: 'dd MMM yyyy',
        },
      },
      fill: {
        type: 'solid',
        fillOpacity: 0.7,
      },
      stroke: {
        width: [0, 4],
      },
      title: {
        show: false,
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      legend: {
        show: true,
        position: 'top',
        horizontalAlign: 'center',
      },
      labels: stats.value?.categories,
      xaxis: {
        tickAmount: 6,
      },
      yaxis: {
        ...stats.value?.titles,
        labels: {
          formatter: (value: number): string => {
            return Math.round(parseFloat(value.toString())).toString()
          },
        },
      },
    }
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
}

// Watchers
watch(
  [selectedYear, selectedMonth],
  async () => {
    if (!selectedYear.value || !selectedMonth.value) {
      return
    }

    if (chartOptions.value && Object.keys(chartOptions.value).length) {
      isLoadingChart.value = true
    } else {
      isLoading.value = true
    }

    await init(selectedYear.value, selectedMonth.value)

    if (chartOptions.value && Object.keys(chartOptions.value).length) {
      isLoadingChart.value = false
    } else {
      isLoading.value = false
    }
  },
  { immediate: true }
)
</script>

<template>
  <VLoader v-if="isLoading" text="Calculating..." class="h-100" />

  <div v-else class="stats-dashboard">
    <!-- Totals -->
    <h1 class="title is-5">Totals</h1>
    <div class="business-dashboard total-dashboard">
      <div class="total-header">
        <div class="header-item">
          <div class="item-inner">
            <VIcon :icon="ICONS.pages.inbox" />
            <span class="dark-inverted">{{ stats?.total?.chats_count }}</span>
            <p>Chats</p>
          </div>
        </div>
        <div class="header-item">
          <div class="item-inner">
            <VIcon :icon="ICONS.misc.resolved" />
            <span class="dark-inverted">{{ stats?.total?.resolved_chats_count }}</span>
            <p>Resolved chats</p>
          </div>
        </div>
        <div class="header-item">
          <div class="item-inner">
            <VIcon :icon="ICONS.misc.emailed" />
            <span class="dark-inverted">{{ stats?.total?.emailed_chats_count }}</span>
            <p>Emailed chats</p>
          </div>
        </div>
        <div class="header-item">
          <div class="item-inner">
            <VIcon :icon="ICONS.pages.training" />
            <span class="dark-inverted">{{ stats?.total?.words_count }}</span>
            <p>Knowledge words</p>
          </div>
        </div>
        <div class="header-item">
          <div class="item-inner">
            <VIcon :icon="ICONS.misc.urls" />
            <span class="dark-inverted">{{ stats?.total?.urls_count }}</span>
            <p>URLs</p>
          </div>
        </div>
        <div class="header-item">
          <div class="item-inner">
            <VIcon :icon="ICONS.misc.files" />
            <span class="dark-inverted">{{ stats?.total?.files_count }}</span>
            <p>Files</p>
          </div>
        </div>
        <div class="header-item">
          <div class="item-inner">
            <VIcon :icon="ICONS.pages.contacts" />
            <span class="dark-inverted">{{ stats?.total?.contacts_count }}</span>
            <p>Contacts</p>
          </div>
        </div>
        <div class="header-item">
          <div class="item-inner">
            <VIcon :icon="ICONS.pages.accounts" />
            <span class="dark-inverted">{{ stats?.total?.accounts_count }}</span>
            <p>Accounts</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Achievements -->
    <!--
    <CustomTippy
      title="On Fire"
      content="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
      icon="fas fa-fire"
      color="danger"
    />
    -->

    <VMessage color="info"> Statistics utilize the America/New_York timezone. </VMessage>

    <!-- Filters -->
    <div class="columns">
      <div class="column is-6">
        <h1 class="title is-3">Credits</h1>
      </div>
      <div class="column is-6">
        <div class="is-flex is-justify-content-flex-end is-align-items-center">
          <span class="mr-3"><b>Filter by</b></span>

          <FormKit
            v-model="selectedYear"
            type="select"
            :options="years"
            :classes="{ outer: 'mr-2' }"
          />
          <FormKit v-model="selectedMonth" type="select" :options="months" />
        </div>
      </div>
    </div>

    <VLoader v-if="isLoadingChart" text="Calculating..." class="h-100" card="smooth" />

    <template v-else>
      <div class="s-card">
        <ApexChart
          v-if="!isLoading && chartOptions"
          :height="chartOptions.chart.height"
          :type="chartOptions.chart.type"
          :series="chartOptions.series"
          :options="chartOptions"
        >
        </ApexChart>
      </div>
    </template>
  </div>
</template>

<style lang="scss">
.stats-dashboard {
  .formkit-outer {
    margin: 0;
  }

  .total-dashboard {
    .total-header {
      display: flex;
      padding: 20px;
      background: var(--white);
      border: 1px solid var(--border);
      border-radius: var(--radius-large);
      margin-bottom: 1.5rem;

      .header-item {
        width: 25%;
        border-right: 1px solid var(--border);

        &:last-child {
          border-right: none;
        }

        .item-inner {
          text-align: center;

          .lnil,
          .lnir {
            font-size: 1.8rem;
            margin-bottom: 6px;
            color: var(--primary);
          }

          span {
            display: block;
            font-family: var(--font);
            font-weight: 600;
            font-size: 1.6rem;
            color: var(--dark-text);
          }

          p {
            font-family: var(--font-alt);
            font-size: 0.95rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .stats-dashboard {
    .total-dashboard {
      .total-header {
        flex-wrap: wrap;

        .header-item {
          width: 50%;
          border-right: none;
          border: none;
          padding: 16px 0;
        }
      }

      .dashboard-card {
        &.is-tickets {
          padding: 30px;

          .ticket-list {
            .media-flex {
              .flex-meta {
                margin-bottom: 1rem;
              }
            }
          }
        }
      }
    }
  }
}
</style>
