<script setup lang="ts">
import {
  computed,
  nextTick,
  onBeforeMount,
  onMounted,
  reactive,
  Reactive,
  ref,
  Ref,
  toRefs,
  watch,
} from 'vue'
import { getNode } from '@formkit/core'
import { EventHelper, HttpHelper, StringHelper } from '/@src/helpers'
import { useUserSession } from '/@src/stores'

const emit = defineEmits<{
  (e: 'training'): void
  (e: 'synch'): void
}>()

const props = defineProps({
  widgetUid: { type: String, default: '', required: true },
  provider: { type: Object, default: () => ({}), required: true },
  trainingType: { type: String, default: 'urls', required: true },
  plans: { type: Object, default: () => {}, required: true },
})

const { widgetUid, trainingType, plans, provider } = toRefs(props)

// Const data
const formId = 'trainingUrlsForm'
const youtubeDomains = ['youtube.com', 'youtu.be']

// Composable
const userSession = useUserSession()

// Reactive data
const formState: Reactive<any> = reactive({})
const newMaterial: Ref<any> = ref([])
const isLoading = ref(false)
const training: Ref<any> = ref({
  label: '',
  fetch_every_hours: 0,
})
const sitemapDetected = ref(false)
const pdfDetected = ref(false)
const googleDocsDetected = ref(false)
const nonYoutubeUrlDetected = ref(false)
const autoFetchLimitation = ref()
const loaderProgress = ref(-1)
const loaderText = ref('')
const modalCrawlerProps: any = ref({
  open: false,
})
const modalBulkerProps: any = ref({
  open: false,
})

// Computed
const isFormDisabled = computed(() => {
  const isInvalid = !formState.valid

  if (isLoading.value || isInvalid) {
    return true
  }

  return false
})

// Lifecycle hooks
onBeforeMount(() => {
  try {
    isLoading.value = true
    autoFetchLimitation.value = userSession.getPlanLimitations('auto_fetch')

    if (provider.value && provider.value?.uid) {
      training.value = {
        label: provider.value?.label,
        fetch_every_hours: provider.value?.fetch_every_hours,
      }
    }
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }

  // Init
  onInit()
})

onMounted(() => {
  Object.assign(formState, toRefs(getNode(formId)?.context?.state || {}))
})

// Watch
watch(
  () => newMaterial.value,
  (urls) => {
    sitemapDetected.value =
      urls?.some((url: string) =>
        ['.xml', '.gz'].some((ext) => url && url.toLowerCase().endsWith(ext))
      ) ?? false

    pdfDetected.value =
      urls?.some((url: string) => ['.pdf'].some((ext) => url && url.toLowerCase().endsWith(ext))) ??
      false

    googleDocsDetected.value =
      urls?.some((url: string) => url && url.toLowerCase().includes('docs.google.com')) ?? false

    if (trainingType.value === 'youtube') {
      nonYoutubeUrlDetected.value =
        urls?.some(
          (url: string) =>
            url && !youtubeDomains.some((domain) => url.toLowerCase().includes(domain))
        ) ?? false
    }
  },
  { deep: true }
)

// Functions
const insertUrls = (urls: string[]) => {
  const cleanUrls = [...new Set([...newMaterial.value, ...urls].filter((url) => url.trim()))]

  newMaterial.value = cleanUrls && cleanUrls.length ? cleanUrls : ['']
}

const onInit = () => {
  newMaterial.value = ['']
}

const onInsert = (value: string) => {
  newMaterial.value.push(value)
}

const onDelete = async (item: string[], index: number) => {
  try {
    if (Object.keys(newMaterial.value)?.length === 1) {
      return
    }

    if (index > -1) {
      item.splice(index, 1)
      await nextTick()
    }
  } catch (error) {
    console.error(error)
  }
}

const triggerSubmit = () => {
  getNode(formId)?.submit()
}

const onSaveAutoRefresh = async () => {
  try {
    const providerResponse = await HttpHelper.post('/providers', provider.value?.uid ?? null, {
      widget_uid: widgetUid.value,
      label: training.value?.label,
      type: trainingType.value,
      fetch_every_hours: Number(training.value.fetch_every_hours) || 0,
    })

    if (!provider.value?.uid) {
      Object.assign(provider.value, providerResponse)
    }
  } catch (error) {
    console.error(error)
  }
}

const onSubmit = async () => {
  if (isLoading.value) {
    return
  }

  let data: string[] = Array.from(new Set(newMaterial.value?.filter(Boolean) || []))

  if (trainingType.value === 'youtube') {
    data = data.filter((url) =>
      youtubeDomains.some((domain) => url && url.toLowerCase().includes(domain))
    )
  }

  if (!data || data.length === 0) {
    isLoading.value = false

    return
  }

  isLoading.value = true
  loaderProgress.value = 0

  try {
    const providerResponse = await HttpHelper.post('/providers', provider.value?.uid ?? null, {
      widget_uid: widgetUid.value,
      label: training.value?.label,
      type: trainingType.value,
      fetch_every_hours: Number(training.value.fetch_every_hours) || 0,
    })

    if (!provider.value?.uid) {
      Object.assign(provider.value, providerResponse)

      emit('training')

      EventHelper.push('created_training')
    }

    if (provider.value?.uid && Object.keys(newMaterial?.value)?.length) {
      await HttpHelper.batch(
        '/providers/synch',
        provider.value?.uid,
        { training: data },
        {
          batchSize: 50,
          batchKey: '',
        },
        (numBatches: number, i: number) => {
          loaderText.value = `Processing batch ${i + 1}/${numBatches}`
          loaderProgress.value = Math.round((i * 100) / numBatches)
        }
      )

      emit('synch')
    }

    onInit()
    document?.getElementById('training-material-list')?.scrollIntoView({ behavior: 'smooth' })
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
}
</script>

<template>
  <CrawlerModal v-if="modalCrawlerProps?.open" v-model="modalCrawlerProps" @close="insertUrls" />
  <BulkerModal v-if="modalBulkerProps?.open" v-model="modalBulkerProps" @close="insertUrls" />

  <FormKit :id="formId" type="form" :disabled="isLoading" :actions="false" @submit="onSubmit">
    <!-- Auto Refresh Training -->
    <CustomCollapse with-chevron class="m-0">
      <template #collapse-item-summary>
        <h4 class="title is-6 m-0 has-text-centered">Auto Refresh Training</h4>
      </template>
      <template #collapse-item-content>
        <MarketingMessage v-if="!autoFetchLimitation" :plan="plans?.standard">
          Upgrade your subscription to unlock the “Auto Refresh” feature.
        </MarketingMessage>
        <VMessage v-else color="info">
          Each refresh will deduct between 1 to 3 credits for each URL from your renewable quota,
          use it wisely!
        </VMessage>

        <FormKit
          v-model.number="training.fetch_every_hours"
          type="range"
          :label="`Auto refresh every ${training.fetch_every_hours} hour(s)`"
          value="0"
          min="0"
          max="744"
          step="12"
          tooltip="true"
          help="Enabling this feature help you keep your knowledge data up to date. By setting a frequency for automatic data refreshing, you can ensure your knowledge data is regularly refreshed without manual intervention. You can adjust the frequency according to your needs."
          :disabled="!autoFetchLimitation"
          :classes="{
            outer: 'm-0',
          }"
        />

        <VButtons align="right">
          <VButton
            :disabled="isFormDisabled"
            :loading="isLoading"
            type="submit"
            color="primary"
            icon="ic:round-check"
            @click="onSaveAutoRefresh"
          >
            Save
          </VButton>
        </VButtons>
      </template>
    </CustomCollapse>

    <hr />

    <FormKit
      v-model="training.label"
      type="text"
      label="Label (Private)"
      validation="required:trim"
      placeholder="Training #1"
      validation-visibility="live"
    />

    <CustomForm title="Add Knowledge" subtitle="" class="mb-4">
      <template #body>
        <VButtons align="right">
          <VButton
            v-if="trainingType === 'urls'"
            color="info"
            :disabled="false"
            icon="ic:round-open-in-new"
            @click="modalCrawlerProps.open = true"
          >
            Extract URLs
          </VButton>

          <VButton
            color="info"
            :disabled="false"
            icon="ic:round-insert-link"
            @click="modalBulkerProps.open = true"
          >
            Bulk Importer
          </VButton>
        </VButtons>

        <VMessage v-if="trainingType === 'urls'" color="info" class="w-100">
          <b>We do not perform automatic website crawling.</b> Please add each URL you wish to train
          the chatbot on.<br /><br />You can utilize the
          <b>"Extract URLs"</b>
          above to obtain all the URLs within a page, the "Extract URLs" also support sitemaps.
        </VMessage>

        <VMessage v-if="trainingType === 'youtube'" color="info" class="w-100">
          <b>
            Subtitles and closed captions must be available on the videos, or the fetch will fail.
          </b>
        </VMessage>

        <div class="big-list">
          <template v-for="(_, index) in newMaterial" :key="index">
            <div class="columns is-multiline is-vcentered">
              <div class="column pt-0 pb-0">
                <FormKit
                  v-model="newMaterial[index]"
                  type="url"
                  validation="trim|url"
                  validation-visibility="live"
                  :placeholder="
                    trainingType === 'urls'
                      ? 'https://www.example.com'
                      : 'https://youtu.be/watch?v=2ViqdR5E2wU'
                  "
                  :classes="{
                    outer: 'm-0',
                  }"
                >
                  <template
                    v-if="trainingType === 'urls' && StringHelper.isValidUrl(newMaterial[index])"
                    #suffix
                  >
                    <VButton
                      color="info"
                      :disabled="false"
                      icon="ic:round-open-in-new"
                      :style="{ width: '170px', height: '30px', marginRight: '3px' }"
                      @click="
                        () => {
                          modalCrawlerProps.url = newMaterial[index]
                          modalCrawlerProps.open = true
                        }
                      "
                    >
                      Extract URLs
                    </VButton>
                  </template>
                </FormKit>
              </div>

              <div class="column is-narrow p-0">
                <FormKitControls
                  :delete-confirmation="false"
                  @delete="onDelete(newMaterial, index)"
                  @insert="() => onInsert('')"
                />
              </div>
            </div>
          </template>

          <VMessage v-if="sitemapDetected" color="danger" class="w-100 mt-4">
            <b>A sitemap was detected!</b> However, we do not conduct automatic sitemap crawling.
            You can make use of the <b>"URL Crawling & Extraction"</b> above to retrieve all the
            URLs within your sitemap.
          </VMessage>

          <VMessage v-if="pdfDetected" color="danger" class="w-100 mt-4">
            <b>A PDF was detected!</b> However, we do not conduct automatic fetching of PDFs using a
            URL. You can create a knowledge set of type "Files" and upload your documents there.
          </VMessage>

          <VMessage v-if="googleDocsDetected" color="danger" class="w-100 mt-4">
            <b>Google Docs URL detected!</b> Please note that we cannot automatically fetch content
            from Google Docs. You can create a knowledge set of type "Files" and upload your
            documents there.
          </VMessage>

          <VMessage
            v-if="trainingType === 'youtube' && nonYoutubeUrlDetected"
            color="danger"
            class="w-100 mt-4"
          >
            <b>Non-YouTube URL detected!</b> Please ensure that the URLs you provide are from
            YouTube.
          </VMessage>
        </div>

        <VButtons align="right" class="mt-4">
          <VButton
            :disabled="isFormDisabled"
            :loading="isLoading"
            type="submit"
            color="primary"
            icon="ic:round-check"
            @click="triggerSubmit"
          >
            Save & Train
          </VButton>
        </VButtons>
      </template>
    </CustomForm>
  </FormKit>
</template>
