<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { FileHelper, HttpHelper } from '/@src/helpers'

const props = defineProps({
  uid: {
    type: String,
    default: undefined,
    required: true,
  },
  label: {
    type: String,
    default: undefined,
    required: false,
  },
  url: {
    type: String,
    default: undefined,
    required: false,
  },
  extension: {
    type: String,
    default: undefined,
    required: false,
  },
  contentHeight: {
    type: String,
    default: '500px',
    required: false,
  },
  isImage: {
    type: Boolean,
    default: false,
    required: false,
  },
  isPDF: {
    type: Boolean,
    default: false,
    required: false,
  },
  hideAgents: {
    type: Boolean,
    default: false,
    required: false,
  },
})

// Const data
const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'ico']

// Reactive data
const isLoading = ref({ document: false, download: false, print: false })
const document: any = ref({})
const extname: any = ref('')
const signedUrl: any = ref(null)
const modalEmailProps = ref()

// Computed
const url = computed(() => props.url ?? signedUrl.value)

// API calls & Watchers
watch(
  [() => props.uid, () => props.url],
  async () => {
    try {
      if (props.uid) {
        isLoading.value.document = true

        const response = await HttpHelper.get(`/documents/${props.uid}`)

        document.value = response
        extname.value = response?.extname?.toLowerCase()
        signedUrl.value = response?.meta?.signed_url
      } else {
        extname.value = props.extension
        signedUrl.value = props.url
      }
    } catch (error) {
      console.error(error)
    } finally {
      isLoading.value.document = false
    }
  },
  { immediate: true }
)

// Functions
const openEmailFormModal = () => {
  modalEmailProps.value = {
    open: true,
    propertyUid: document.value?.property_uid,
    documents: [document.value],
    subject: document.value?.label,
    to: [document.value?.emails],
    cc: [document.value?.emails],
    cci: null,
  }
}

const onDownload = async () => {
  try {
    isLoading.value.download = true

    await FileHelper.download(signedUrl.value, document.value.label, extname.value)
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value.download = false
  }
}
</script>

<template>
  <div v-if="url" class="form-fieldset">
    <div v-if="props.label" class="fieldset-heading">
      <h4>{{ props.label }}</h4>
    </div>

    <div class="fieldset-body has-text-centered">
      <img
        v-if="props.isImage || (imageExtensions.includes(extname) && url)"
        class="align-center"
        :alt="props.label"
        :src="url"
        :style="{ 'maxHeight': props.contentHeight, 'object-fit': 'contain' }"
      />

      <embed
        v-else-if="props.isPDF || ((['pdf'].includes(extname) || url.includes('blob')) && url)"
        :src="`${url}#toolbar=0&navpanes=0&scrollbar=0`"
        :name="props.label"
        width="100%"
        :style="{ height: props.contentHeight }"
        type="application/pdf"
        frameborder="0"
        allowfullscreen
      />
    </div>
  </div>
</template>
