<script setup lang="ts">
import { computed, PropType, ref } from 'vue'
import { useNotyf } from '/@src/composable/useNotyf'
import { HttpHelper, StringHelper } from '/@src/helpers'
import { IAddon, IPlan } from '/@src/interfaces'
import { useUserSession } from '/@src/stores'

const emit = defineEmits(['subscribe', 'cancel'])

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  addon: {
    type: Object as PropType<IAddon>,
    required: true,
  },
  period: {
    type: String,
    required: true,
  },
  mode: {
    type: String,
    required: true,
  },
})

// Reactive data
const isLoading = ref(false)
const modalCheckoutProps: any = ref({ clientSecret: null, open: false, refresh: false })
const counter = ref({
  chatbots: {
    total: 1,
    min: 1,
    max: 100,
    step: 1,
    label: 'Number of chatbots to buy',
  },
  queries: {
    total: 5000,
    min: 5000,
    max: 1000000,
    step: 5000,
    label: 'Number of credits to buy',
  },
  urls: {
    total: 500,
    min: 500,
    max: 100000,
    step: 500,
    label: 'Number of knowledge URLs to buy',
  },
  files: {
    total: 500,
    min: 500,
    max: 100000,
    step: 500,
    label: 'Number of knowledge files to buy',
  },
})

// Const data
const bgColor = props.mode === 'business' ? '#00DA8F' : '#F82A5E'
const textColor = props.mode === 'business' ? '#0e111b' : '#fff'

// Composable
const userSession = useUserSession()
const notyf = useNotyf()

// Computed
const addonByPeriod = computed(() => {
  // @ts-ignore
  return props.addon[props.period]
})

const computedBackgroundColor = computed(() => {
  return isAddonActive(props.addon, props.period) ? '#f6f6f7' : props?.addon?.background
})

const computedCounter = computed(() => {
  // @ts-ignore
  return counter.value[props.name]
})

// Function
const isAddonActive = (addon: IAddon, period: string): IAddon | null => {
  // @ts-ignore
  const plansIds = addon?.[period]?.plans
  const response = userSession?.addons?.filter((currentAddon: any) =>
    plansIds.includes(currentAddon?.plan_id)
  )

  return response && response?.length === 0 ? null : response[0]
}

const onSubscribe = async (params: { plan: IPlan | IAddon; period: string; quantity: number }) => {
  emit('subscribe', params)
}

const onCancel = async (params: {
  name: string
  plan: IPlan | IAddon
  period: string
  quantity: number
}) => {
  try {
    const { name, plan } = params

    if (name && name === 'domain') {
      alert(
        'Reach out to our support team to initiate the cancellation of the Custom Domain add-on.'
      )

      return
    }

    isLoading.value = true

    // @ts-ignore
    await HttpHelper.get(`/subscriptions/cancel-plan/${plan?.[props.period]?.default_plan_id}`)

    notyf.success('Your add-on has been cancelled and you will not be charged again.')

    setTimeout(() => {
      redirect()
    }, 1000)
  } catch (error: any) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
}

const closeModal = async (refresh: boolean) => {
  try {
    if (refresh) {
      redirect()
    }
  } catch (error) {
    console.error(error)
  }
}

const redirect = () => {
  window.location.href = '/settings?page=subscriptions'
}

/*
const onChangeQuantity = async (params: { addon: IAddon; quantity: number }) => {
  const { addon, quantity } = params

  alert(
    'To modify the quantity of an existing add-on subscription, kindly contact our customer support team for assistance.'
  )

  const { url } = await HttpHelper.get(
    `/subscriptions/create-checkout-session/${addon?.monthly?.default_plan_id}/${quantity}?checkout=true`
  )

  window.location.href = url

  return
}
*/

const onManage = async (params: { plan: IAddon }) => {
  const { plan } = params

  const activeAddon = isAddonActive(plan, props.period)

  if (!activeAddon) {
    return
  }

  const { url } = await HttpHelper.get(`/subscriptions/create-portal-session`, {
    withoutApp: false,
  })

  url && window.open(url + `/subscriptions/${activeAddon?.id}/update`, '_blank')

  return
}
</script>

<template>
  <CheckoutModal
    v-if="modalCheckoutProps?.open && modalCheckoutProps.open === true"
    v-model:modalProps="modalCheckoutProps"
    @close="closeModal"
  />

  <VCard
    :style="{
      'background-color': computedBackgroundColor,
    }"
    class="is-fullheight is-flex is-flex-direction-column"
  >
    <div class="is-flex-grow-1 is-flex is-flex-direction-column is-justify-content-space-between">
      <div class="columns is-multiline pricing-table">
        <!-- Title -->
        <div class="column is-12 mt-3 mb-3 has-text-centered">
          <h1 class="title is-4">
            {{ props?.addon?.title }}
          </h1>
          <div class="subtitle is-6" style="height: 10px">{{ props?.addon?.subtitle }}</div>
        </div>

        <!-- Price -->
        <div class="column is-12 mb-3 has-text-centered">
          <div class="subtitle is-3 mb-3 is-narrow is-bolder">
            <span v-if="props.addon.is_slider" class="price">
              <span class="whole">
                ${{ computedCounter?.total * Number(addonByPeriod?.price) }}
              </span>
            </span>
            <span v-else class="price">
              <span class="whole"> ${{ addonByPeriod?.price?.split('.')?.[0] }} </span>
              <span class="decimal">{{ addonByPeriod?.price?.split('.')?.[1] }}</span>
            </span>
          </div>
          <div>{{ props.period === 'monthly' ? 'Monthly' : 'Yearly' }}</div>
        </div>

        <!-- Discount -->
        <!--
          <div class="column is-12 mb-3 p-0 has-text-centered" style="height: 20px">
            <VTag v-if="props.addon?.discount" color="danger">
              <b>Limited Discount: -{{ props.addon?.discount }}% </b>
            </VTag>
          </div>
        -->

        <!-- Features -->
        <div class="column is-flex is-justify-content-center is-12 mb-3" style="height: 120px">
          <ul>
            <VBlock
              v-for="(feature, index) of props.addon?.features"
              :key="index"
              class="mb-1 is-capitalize"
              :title="feature.title"
              lighter
              center
            >
              <template #icon>
                <VIcon
                  :is-iconify="true"
                  :icon="feature.icon ?? 'ic:round-check'"
                  :color="feature.color ?? bgColor"
                />
              </template>
            </VBlock>
          </ul>
        </div>

        <!-- Credits -->
        <div
          v-if="props.addon?.is_slider"
          class="column is-flex is-justify-content-center is-12 mb-3"
        >
          <FormKit
            v-model="computedCounter.total"
            :label="computedCounter?.label"
            type="slider"
            :min="computedCounter?.min"
            :max="computedCounter?.max"
            :step="computedCounter?.step"
            tooltip="true"
            :classes="{ outer: 'w-75' }"
          />
        </div>
      </div>

      <div class="is-12 has-text-centered mb-3">
        <div v-if="isAddonActive(props.addon, props.period)" class="columns mb-0">
          <div class="column">
            <VButton
              :style="{
                backgroundColor: `${bgColor} !important`,
                color: `${textColor} !important`,
              }"
              color="success"
              class="mb-0 w-100"
              @click="
                onCancel({
                  name: props.name,
                  plan: props.addon,
                  period: props.period,
                  quantity: computedCounter?.total || 1,
                })
              "
            >
              <small class="rem-100 is-capitalize is-weight-500"> Cancel Addon </small>
            </VButton>
          </div>

          <div v-if="['queries', 'urls', 'files', 'chatbots'].includes(props.name)" class="column">
            <!--
              @click="
                onSubscribe({
                  plan: props.addon,
                  period: props.period,
                  quantity: computedCounter.total,
                })
              "
            -->
            <VButton
              :style="{
                backgroundColor: `${bgColor} !important`,
                color: `${textColor} !important`,
              }"
              class="mb-0 w-100"
              color="success"
              @click="onManage({ plan: props.addon })"
            >
              <small class="rem-100 is-capitalize is-weight-500"> Adjust Quantity </small>
            </VButton>
          </div>
        </div>

        <VButton
          v-else
          color="success"
          class="w-100 mb-3"
          :style="{
            backgroundColor: `${bgColor} !important`,
            color: `${textColor} !important`,
          }"
          @click="
            onSubscribe({
              plan: props?.addon,
              period: props.period,
              quantity: computedCounter?.total ?? 1,
            })
          "
        >
          <small class="rem-100 is-capitalize is-weight-500">
            Buy {{ StringHelper.formatNumberOrPrice(computedCounter?.total) ?? '' }}
            {{ props?.addon?.title }}
          </small>
        </VButton>

        <!-- Footer -->
        <small class="rem-95 is-capitalize is-weight-500"> Cancel anytime - No hidden fees</small>
      </div>
    </div>
  </VCard>
</template>

<style lang="scss">
.tab-content {
  padding: 20px 0 !important;
}

.pricing-table {
  .price .decimal {
    font-size: 8px;
    vertical-align: super;
  }
}
</style>
