<script setup lang="ts">
import DOMPurify from 'dompurify'
import { computed, onBeforeMount, Ref, ref } from 'vue'
import { toRefs } from 'vue'
import { StringHelper, ViaPlaceholderHelper } from '/@src/helpers'
import { IMessage } from '/@src/interfaces'
import { useUserSession, useWidget } from '/@src/stores'

// Composable
const widgetStore = useWidget()

const props = defineProps({
  index: {
    type: Number,
    required: true,
  },
  message: {
    type: Object as () => Ref<IMessage>,
    required: true,
    default: () => {},
  },
  isLastMessage: {
    type: Boolean,
    default: false,
    required: true,
  },
})

// Reactive data
const { message } = toRefs(props)
const windowStylingSettings: any = ref({})
const userMessageStylingSettings: any = ref({})
const assistantMessageStylingSettings: any = ref({})
const userAvatarSettings: any = ref({})
const assistantAvatarSettings: any = ref({})
const agentsLimitation: Ref<string[]> = ref([])

// Composable
const userSession = useUserSession()

// Lifecycle hooks
onBeforeMount(() => {
  windowStylingSettings.value = widgetStore.getWindowStylingSettings()
  userMessageStylingSettings.value = widgetStore.getUserMessageStylingSettings()
  assistantMessageStylingSettings.value = widgetStore.getAssistantMessageStylingSettings()
  userAvatarSettings.value = widgetStore.getUserAvatarSettings()
  assistantAvatarSettings.value = widgetStore.getAssistantAvatarSettings()
  agentsLimitation.value = widgetStore.getPlanLimitations('agents')
})

// Computed
const computedContent = computed(() => {
  if (message.value?.role === 'assistant') {
    return StringHelper.convertMdToHtml(message.value?.output_text || '')
  } else {
    return DOMPurify.sanitize(StringHelper.convertMdToHtml(message.value?.output_text || ''))
  }
})

const computedLogo = computed(() => {
  switch (message.value?.role) {
    case 'user':
      return StringHelper.getImageUrl(userAvatarSettings.value?.url)

    case 'assistant':
      return StringHelper.getImageUrl(assistantAvatarSettings.value?.url)

    case 'admin':
      return StringHelper.getImageUrl(assistantAvatarSettings.value?.url)
  }

  return ''
})

const computedAvatarStatus = computed(() => {
  switch (message.value?.role) {
    case 'user':
      return userAvatarSettings.value?.status

    case 'assistant':
      return assistantAvatarSettings.value?.status
  }

  return true
})

const computedMessageWidth = computed(() => {
  const userWidth = userMessageStylingSettings.value?.width
  const assistantWidth = assistantMessageStylingSettings.value?.width

  return message.value?.role === 'user'
    ? {
        width: userWidth,
        maxWidth: userWidth,
      }
    : {
        width: assistantWidth,
        maxWidth: assistantWidth,
      }
})

const computedInnerMsgCss = computed(() => {
  const { feedback, role } = message.value ?? {}

  const userDynamicStyles = {
    ...widgetStore.getDynamicStylesByObject(userMessageStylingSettings.value),
    ...widgetStore.getDynamicStylesByObject(windowStylingSettings.value, ['font', 'size']),
  }
  const assistantDynamicStyles = {
    ...widgetStore.getDynamicStylesByObject(assistantMessageStylingSettings.value),
    ...widgetStore.getDynamicStylesByObject(windowStylingSettings.value, ['font', 'size']),
  }

  if (!feedback || (feedback && !Object.keys(feedback).length)) {
    return role === 'user' ? userDynamicStyles : assistantDynamicStyles
  } else {
    return feedback?.status && feedback.status === 'thumb_up'
      ? {
          ...userDynamicStyles,
          color: 'white !important',
          backgroundColor: 'green !important',
        }
      : {
          ...assistantDynamicStyles,
          color: 'white !important',
          backgroundColor: 'red !important',
        }
  }
})

const computedFlexDirection = computed(() => {
  return message.value?.role === 'user' ? 'is-flex-direction-row-reverse' : 'is-flex-direction-row'
})

const computedMetadataStatus = computed(() => {
  const { sources, agents, feedback, metadata, context } = message.value ?? {}

  const condition1 = sources && (sources?.urls?.length || sources?.files?.length)
  const condition2 = agents && agents?.length
  const condition3 = feedback && feedback?.user_feedback
  const condition4 = metadata && Object.keys(metadata).length
  const condition5 = !!context

  if (
    (widgetStore.getWidget()?.source_discloser && condition1) ||
    (userSession?.isLoggedIn && (condition2 || condition3 || condition4 || condition5))
  ) {
    return true
  }

  return false
})
</script>

<template>
  <div
    :class="[
      'columns',
      'is-multiline',
      'is-mobile',
      'is-flex',
      'is-vcentered',
      'p-3',
      computedFlexDirection,
    ]"
  >
    <div
      v-if="computedAvatarStatus"
      class="column is-narrow p-0"
      :class="message?.role === 'user' ? 'pl-3' : 'pr-3'"
    >
      <img
        class="avatar"
        :src="computedLogo"
        draggable="false"
        alt="Avatar"
        @error.once="(event) => ViaPlaceholderHelper.onceImageErrored(event, '40x40')"
      />
    </div>

    <div
      :class="['column', 'msg-inner', message?.role]"
      :style="{ ...computedInnerMsgCss, ...computedMessageWidth }"
    >
      <!-- Loading -->
      <Transition name="fade-slow" mode="out-in">
        <VTyping v-if="message?.isLoading" class="VTyping" :color="computedInnerMsgCss?.color" />
      </Transition>

      <!-- Files -->
      <div
        v-if="message?.files && message?.files.length"
        class="columns is-multiline is-mobile mb-0 is-vcentered Files"
      >
        <div v-for="(file, index2) of message?.files" :key="index2" class="column is-narrow">
          <img
            v-if="file.type === 'image'"
            :key="index2"
            class="is-image"
            :src="file.signed_url"
            alt="File"
          />

          <audio v-if="file.type === 'audio'" controls style="height: 36px">
            <source :src="file.signed_url" />
            Browser does not support audio element.
            <track src="" kind="captions" />
          </audio>
        </div>
      </div>

      <!-- Dynamic form -->
      <transition name="slide-fade" mode="out-in">
        <ChatDynamicForm
          v-if="
            message?.output_form && ['capture_lead', 'transcribe'].includes(message?.output_form)
          "
          :message="message"
          :form-name="message?.output_form"
        />

        <div
          v-else-if="message?.output_text ?? message?.html"
          class="msg"
          v-html="computedContent"
        ></div>
      </transition>

      <!-- Metadata -->
      <div
        v-if="computedMetadataStatus"
        class="sources pt-3 Sources"
        :style="{ color: computedInnerMsgCss?.color }"
      >
        <CustomCollapse with-chevron>
          <template #collapse-item-summary> Metadata </template>
          <template #collapse-item-content>
            <div v-if="widgetStore.getWidget()?.source_discloser && message?.sources" class="pb-3">
              <b>Sources</b>
              <!-- URLs -->
              <div v-for="(url, index3) of message?.sources?.urls" :key="index3">
                <a :href="url" target="_blank">{{ url }}</a>
              </div>

              <!-- Files -->
              <div v-for="(file, index4) of message?.sources?.files" :key="index4">
                <a :href="file?.signed_url" target="_blank">{{ file?.name }}</a>
              </div>
            </div>

            <template v-if="userSession?.isLoggedIn">
              <div v-if="message?.agents && message?.agents?.length" class="pb-3">
                <b>Agents (Viewable by admin)</b>
                <!-- Agents -->
                <div v-if="message?.agents && message?.agents?.length">
                  {{ message?.agents.join(', ') }} <br />
                </div>
              </div>

              <div v-if="message?.feedback?.user_feedback" class="pb-3">
                <b>Feedback (Viewable by admin)</b>
                <div>
                  {{ message?.feedback?.user_feedback }}
                </div>
              </div>

              <div v-if="message?.metadata" class="pb-3">
                <b>Details (Viewable by admin)</b>
                <div>{{ message?.metadata }}</div>
              </div>

              <div v-if="message?.context" class="pb-3">
                <b>Context (Viewable by admin)</b>
                <CustomCollapse with-chevron>
                  <template #collapse-item-summary>Snippet</template>
                  <template #collapse-item-content>
                    <pre>{{ message?.context }}</pre>
                  </template>
                </CustomCollapse>
              </div>
            </template>
          </template>
        </CustomCollapse>
      </div>

      <!-- Actions -->
      <div
        v-if="
          message?.role === 'assistant' &&
          !message?.isLoading &&
          !message?.output_form &&
          !message?.is_welcome_message
        "
        class="columns is-multiline is-mobile is-flex is-vcentered"
      >
        <div class="column is-narrow-desktop is-12-mobile has-text-right pb-0 pl-0 pr-0">
          <ChatMessageActions :index="props.index" :message="message" />
        </div>
      </div>
    </div>

    <div
      v-if="
        agentsLimitation.includes('smart_suggestions') &&
        message?.role === 'assistant' &&
        !message?.isLoading &&
        props.isLastMessage
      "
      class="column is-12 p-0 pt-3"
    >
      <ChatSuggestions />
    </div>
  </div>
</template>

<style>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition:
    opacity 0.5s ease-in-out,
    transform 0.5s ease-in-out;
}
.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateY(20px);
}
</style>
