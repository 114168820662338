<script setup lang="ts">
import { onBeforeMount, ref } from 'vue'
import { useNotyf } from '/@src/composable/useNotyf'
import { HttpHelper } from '/@src/helpers'

const props = defineProps({
  widget: {
    type: Object,
    default: () => {},
    required: true,
  },
})

// Reactive data
const isLoading = ref<boolean>(false)
const step = ref<number>(1)
const sessionData = ref<{ phone_number_id?: string; waba_id?: string }>({})

// Const data
const WHATSAPP_BASE_URL = import.meta.env.VITE_WHATSAPP_BASE_URL
const WHATSAPP_APP_ID = import.meta.env.VITE_WHATSAPP_APP_ID
const WHATSAPP_CONFIG_ID = import.meta.env.VITE_WHATSAPP_CONFIG_ID
const WHATSAPP_REDIRECT_URI = import.meta.env.VITE_WHATSAPP_REDIRECT_URI

// Composable
const notyf = useNotyf()

// Lifecycle hooks
onBeforeMount(() => {
  try {
    // Facebook SDK
    ;(function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      js = d.createElement(s)
      js.id = id
      // @ts-ignore
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      // @ts-ignore
      fjs.parentNode.insertBefore(js, fjs)
    })(document, 'script', 'facebook-jssdk')

    // Facebook init
    // @ts-ignore
    window.fbAsyncInit = function () {
      // @ts-ignore
      FB.init({
        appId: WHATSAPP_APP_ID,
        autoLogAppEvents: true,
        cookie: true,
        xfbml: true,
        version: 'v19.0',
      })

      // @ts-ignore
      FB.AppEvents.logPageView()
    }
  } catch (error) {
    console.error(error)
  }

  if (props?.widget?.is_whatsapp_signed_up) {
    step.value = 2
  }
})

// Function
const sessionInfoListener = (event: any) => {
  if (event.origin !== 'https://www.facebook.com' && event.origin !== 'https://web.facebook.com') {
    return
  }

  try {
    let data

    try {
      data = JSON.parse(event.data)
    } catch (error) {
      isLoading.value = false

      return
    }

    if (data) {
      if (data?.event !== 'FINISH') {
        isLoading.value = false
      }

      if (data.type === 'WA_EMBEDDED_SIGNUP') {
        if (data.event === 'FINISH') {
          const { phone_number_id, waba_id } = data.data

          if (phone_number_id && waba_id) {
            sessionData.value = { phone_number_id, waba_id }
          }
        } else {
          isLoading.value = false
        }
      }
    }
  } catch (error) {
    isLoading.value = false

    console.error(error)
  }
}

const setup = (widgetUid: string) => {
  try {
    // @ts-ignore
    FB.login(
      (response: any) => {
        if (response.authResponse) {
          isLoading.value = true

          HttpHelper.post(
            'whatsapp/setup',
            widgetUid,
            {
              code: response?.authResponse?.code,
              phone_number_id: sessionData.value?.phone_number_id,
              waba_id: sessionData.value?.waba_id,
            },
            { withoutApp: false },
            () => {
              step.value = 2
              isLoading.value = false
            }
          ).catch((error) => {
            console.error(error)

            step.value = 1
            isLoading.value = false
          })
        }
      },
      {
        config_id: WHATSAPP_CONFIG_ID,
        response_type: 'code',
        override_default_response_type: true,
        extras: {
          // Bypass phone number selection
          // featureType: 'only_waba_sharing',
          setup: {},
          sessionInfoVersion: 2,
        },
      }
    )
  } catch (error) {
    isLoading.value = false

    console.error(error)
  }
}

const deregister = async (widgetUid: string) => {
  try {
    isLoading.value = true

    await HttpHelper.post('whatsapp/deregister', widgetUid, {}, { withoutApp: false }, () => {
      step.value = 1
    })

    notyf.success(
      `Your phone number has been successfully deregistered. Please note that you still need to delete it from the Meta Business Platform if you intend to reuse it.<br /><br />To do so, got to the following page <a target="_blank" href="https://business.facebook.com/wa/manage/phone-numbers/">Phone numbers</a> then navigate to Settings > Business Settings > WhatsApp Accounts > WhatsApp Manager > Phone Numbers.`
    )
  } catch (error) {
    console.error(error)

    notyf.error('Unable to complete the action!')
  } finally {
    isLoading.value = false
  }
}

const permissions = () => {
  isLoading.value = true

  const state = encodeURIComponent(
    JSON.stringify({
      type: 'whatsapp',
    })
  )
  const scope = [
    'email',
    'public_profile',
    'whatsapp_business_management',
    'whatsapp_business_messaging',
    'business_management',
  ].join(',')

  // Construct the full URL
  const fullUrl = `${WHATSAPP_BASE_URL}/dialog/oauth?response_type=code&display=popup&client_id=${WHATSAPP_APP_ID}&redirect_uri=${WHATSAPP_REDIRECT_URI}&auth_type=rerequest&scope=${scope}&state=${state}`

  const windowFeatures =
    'menubar=no,location=no,resizable=no,scrollbars=no,status=no, height=800,width=600'

  // Open in a new window
  window.open(fullUrl, '_blank', windowFeatures)

  setTimeout(() => {
    isLoading.value = false
  }, 4000)
}

// Attach the event listener
window.addEventListener('message', sessionInfoListener)
</script>

<template>
  <div class="columns is-multiline">
    <div class="column is-12">
      <VMessage color="info">
        <ul class="ul-list-style">
          <li>
            Your InsertChat AI Assistant will respond to messages sent to your designated WhatsApp
            number.
          </li>
          <li>
            A phone number already linked to WhatsApp or WhatsApp Business can't be used with
            InsertChat AI.
          </li>
          <li>
            During Meta onboarding, please ensure that you provide all necessary information about
            your business, including its name, website, category, and description.
          </li>
          <li>If you encounter any issues, please don't hesitate to contact us.</li>
        </ul>
      </VMessage>
    </div>

    <div class="column is-12">
      <h3 class="title is-5">Step 1</h3>

      <VButton
        v-if="step === 1"
        :loading="isLoading"
        color="primary"
        class="w-100"
        @click="setup(props?.widget?.uid)"
      >
        Connect InsertChat AI to WhatsApp
      </VButton>

      <VButton
        v-else
        :loading="isLoading"
        color="danger"
        class="w-100"
        @click="deregister(props?.widget?.uid)"
      >
        Deregister InsertChat AI to Whatsapp
      </VButton>
    </div>

    <div class="column is-12">
      <h3 class="title is-5">Step 2</h3>

      <VButton
        :loading="isLoading"
        :disabled="step === 1"
        color="primary"
        class="w-100"
        @click="permissions"
      >
        Give InsertChat AI the required permissions
      </VButton>
    </div>
  </div>
</template>
