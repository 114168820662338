// https://icones.js.org

const ICONS = {
  pages: {
    dashboard: 'ic:round-dashboard',
    buildings: 'ri:building-2-fill',
    properties: 'ri:home-smile-2-fill',
    tenants: 'ic:round-diversity-1',
    leases: 'clarity:contract-solid',
    inventories: 'ic:round-assignment',
    receipts: 'ic:round-receipt',
    notices: 'ic:round-notifications-active',
    documents: 'ic:round-cloud',
    payments: 'ic:round-payments',
    photos: 'ic:round-camera-alt',
    contacts: 'mage:contact-book',
    accounts: 'material-symbols:shield-person-rounded',
    account: 'ic:round-settings',
    subscriptions: 'ic:round-card-membership',
    pricing: 'ic:round-pages',
    automations: 'ic:round-auto-mode',
    banks: 'ic:round-account-balance',
    logout: 'ic:round-log-out',
    tools: 'ic:round-smart-toy',
    chats: 'ic:round-chat',
    interface: 'streamline:paint-palette-solid',
    history: 'solar:inbox-archive-outline',
    inbox: 'mage:package-box-fill',
    settings: 'ic:round-settings-suggest',
    training: 'streamline:artificial-intelligence-spark-solid',
    api: 'ic:round-api',
    agents: 'mage:robot-happy-fill',
    analytics: 'hugeicons:analytics-up',
    library: 'ic:round-library-add-check',
    quizzes: 'ic:round-quiz',
    texts: 'ic:round-text-fields',
    users: 'ic:round-supervised-user-circle',
    whitelabel: 'ic:round-palette',
    integrations: 'ic:round-integration-instructions',
    whatsapp: 'ic:round-whatsapp',
    customer_support: 'ic:round-support-agent',
    invoices: 'ic:round-receipt',
    top_up: 'ic:round-repeat',
    business: 'ic:round-business-center',
    characters: 'ic:round-insert-emoticon',
    install: 'solar:chat-square-code-outline',
    wehhooks: 'mdi:webhook',
    preview: 'hugeicons:message-preview-01',
    playground: 'tabler:message-chatbot-filled',
    chat_bubble: 'heroicons:chat-bubble-bottom-center-text',
    images:
      'streamline:image-photo-polaroid-photos-polaroid-picture-camera-photography-photo-pictures',
    styling: 'hugeicons:color-picker',
    welcome: 'ph:presentation',
  },
  actions: {
    create: 'ic:round-add-circle',
    read: 'ic:round-visibility',
    update: 'ic:round-edit',
    delete: 'ic:round-delete',
    duplicate: 'ic:round-file-copy',
    destroy: 'ic:round-delete-forever',
    archive: 'ic:round-archive',
    download: 'ic:round-download',
    print: 'ic:round-print',
    email: 'ic:round-email',
    password: 'ic:round-password',
    monthly_rent: 'ic:round-calendar-month',
    check: 'ic:round-check-circle',
    sign: 'clarity:digital-signature-line',
    reset: 'ic:round-loop',
    share: 'ic:round-share',
    stop: 'ic:round-stop',
    thumb_up: 'ic:round-thumb-up',
    thumb_down: 'ic:round-thumb-down',
    copy: 'ic:round-file-copy',
    send: 'ic:round-send',
    record: 'ic:round-mic',
    play: 'ic:round-play-circle',
    pause: 'ic:round-pause-circle',
    magic: 'ic:round-auto-awesome',
  },
  forms: {
    information: 'ic:round-info',
    information_additional: 'ic:round-add-circle',
    terms: 'ic:round-local-police',
    services_equipments: 'ic:round-construction',
    finance: 'ic:round-payments',
    insurance: 'ic:round-security',
    rooms: 'ic:round-door-front',
    keys: 'ic:round-vpn-key',
    demo: 'ic:round-favorite',
    close: 'ic:round-close',
    backward: 'ic:round-arrow-back',
    forward: 'ic:round-arrow-forward',
    agency: 'ic:round-real-estate-agent',
    add_entry: 'ic:round-add',
    remove_entry: 'ic:round-remove',
    identity: 'ic:round-photo-camera-front',
    company: 'ic:round-business-center',
  },
  tables: {
    separator: '',
    keys: 'ic:round-vpn-key',
    open: 'ic:round-open-in-full',
    income: 'ic:round-trending-up',
    expense: 'ic:round-trending-down',
  },
  misc: {
    urls: 'ic:round-link',
    files: 'ic:round-file-present',
    resolved: 'ic:round-check-box',
    emailed: 'ic:round-email',
  },
}

const MOBILE_APP_CATEGORIES = [
  { value: 'books', label: 'Books' },
  { value: 'business', label: 'Business' },
  { value: 'education', label: 'Education' },
  { value: 'entertainment', label: 'Entertainment' },
  { value: 'finance', label: 'Finance' },
  { value: 'fitness', label: 'Fitness' },
  { value: 'food', label: 'Food' },
  { value: 'games', label: 'Games' },
  { value: 'government', label: 'Government' },
  { value: 'health', label: 'Health' },
  { value: 'kids', label: 'Kids' },
  { value: 'lifestyle', label: 'Lifestyle' },
  { value: 'magazines', label: 'Magazines' },
  { value: 'medical', label: 'Medical' },
  { value: 'music', label: 'Music' },
  { value: 'navigation', label: 'Navigation' },
  { value: 'news', label: 'News' },
  { value: 'personalization', label: 'Personalization' },
  { value: 'photo', label: 'Photo' },
  { value: 'politics', label: 'Politics' },
  { value: 'productivity', label: 'Productivity' },
  { value: 'security', label: 'Security' },
  { value: 'shopping', label: 'Shopping' },
  { value: 'social', label: 'Social' },
  { value: 'sports', label: 'Sports' },
  { value: 'travel', label: 'Travel' },
  { value: 'utilities', label: 'Utilities' },
  { value: 'weather', label: 'Weather' },
]

const VOICES = [
  {
    value: 'alloy',
    label: 'Alloy (Male or Female)',
    help: 'https://cdn.openai.com/API/docs/audio/alloy.wav',
  },
  {
    value: 'echo',
    label: 'Echo (Male)',
    help: 'https://cdn.openai.com/API/docs/audio/echo.wav',
  },
  {
    value: 'fable',
    label: 'Fable (Male or Female with English accent)',
    help: 'https://cdn.openai.com/API/docs/audio/fable.wav',
  },
  {
    value: 'onyx',
    label: 'Onyx (Male)',
    help: 'https://cdn.openai.com/API/docs/audio/onyx.wav',
  },
  {
    value: 'nova',
    label: 'Nova (Female)',
    help: 'https://cdn.openai.com/API/docs/audio/nova.wav',
  },
  {
    value: 'shimmer',
    label: 'Shimmer (Female)',
    help: 'https://cdn.openai.com/API/docs/audio/shimmer.wav',
  },
]

const ROLES = [
  {
    value: 'client',
    label: 'Client',
    help: 'A client has full access to the assigned chatbots but cannot add new users.',
  },
  {
    value: 'manager',
    label: 'Manager',
    help: 'A manager has full access to the account but cannot add new users.',
  },
  {
    value: 'owner',
    label: 'Owner',
    help: 'An owner has full access to the account and can add new users.',
  },
]

const GO_BUTTONS = [
  {
    value: 'url',
    label: 'URL',
    help: 'Open URL in a new tab',
  },
  {
    value: 'embed',
    label: 'Embed',
    help: 'Embed a page as an AI message',
  },
  {
    value: 'whatsapp',
    label: 'WhatsApp',
    help: 'Open WhatsApp',
  },
  {
    value: 'telegram',
    label: 'Telegram',
    help: 'Open Telegram',
  },
  {
    value: 'messenger',
    label: 'Messenger',
    help: 'Open Messenger',
  },
]

const DALL_E_QUALITY = [
  {
    value: 'vivid',
    label: 'Vivid',
    help: 'Vivid causes the model to lean towards generating hyper-real and dramatic images.',
  },
  {
    value: 'natural',
    label: 'Natural',
    help: 'Natural causes the model to produce more natural, less hyper-real looking images.',
  },
]

const MESSAGE_WIDTH_OPTIONS = [
  {
    label: 'Auto (Adapt to the length)',
    value: 'auto',
  },
  { label: '100% (Occupy the entire width)', value: '100%' },
  { label: '75% (Occupy 75% of the width)', value: '75%' },
  { label: '50% (Occupy 50% of the width)', value: '50%' },
  { label: '25% (Occupy 25% of the width)', value: '25%' },
  { label: '10% (Occupy 10% of the width)', value: '10%' },
]

const PURPOSES = {
  support: 'Customer Support',
  ecommerce: 'E-commerce',
  sales: 'Sales',
  other: 'Other (Custom)',
}

const TRAINING_FILTERS = {
  all: 'All',
  trained: 'Trained',
  training_processing: 'Training',
  training_duplicate: 'Duplicate',
  other: 'Other',
}

const TRAINING_TYPES = [
  'texts',
  'files',
  'urls',
  'quizzes',
  'products',
  'audios',
  'youtube',
  'sitemaps',
  's3',
  'databases',
  'functions',
]

const METHODS = { get: 'GET', post: 'POST', put: 'PUT', delete: 'DELETE' }

const PERSONAS = {
  support: `You're a support agent. Your primary function is to assist customers with any issues they may encounter with our products or services. You should listen actively, understand not only the explicit content of the customer's queries but also detect subtle cues indicating frustration or confusion. Respond with solutions that are not only effective but also communicated with warmth and understanding. If a customer becomes upset or angry, you remain calm and collected, using de-escalation techniques to soothe the customer. Your goal is to solve problems quickly, efficiently, and with a personal touch that leaves customers feeling heard and cared for.`,
  ecommerce: `You're an e-commerce shopping assistant. You provide hyper-personalized product suggestions by analyzing needs. When interacting with customers, you provide a seamless shopping experience by answering inquiries about products, processing orders, and addressing concerns such as shipping and returns. You act as a personal shopping assistant, offering advice or suggesting complementary products to enhance their shopping cart. You're there to assist with checkout, prevent cart abandonment with timely prompts, and use natural language processing to communicate deals, promotions, or loyalty program benefits in an engaging, helpful manner.`,
  sales: `You're a sales expert. Your integrates advanced persuasion techniques to tailor the conversation to the client needs and preferences. You're equipped to handle objections with solutions responses and solutions. You constantly seek to understand and forecast customer desires, proactively providing information and solutions about our products or services that align with those needs. Your interactions aim to build trust and rapport while affirmatively moving the conversation towards closing a sale. However, the integrity of the approach and the customer's best interest are always your top priorities, ensuring a transparent and ethical sales process.`,
}

const TYPING_INDICATOR_VISIBILITY = 1250

export {
  TRAINING_FILTERS,
  TRAINING_TYPES,
  TYPING_INDICATOR_VISIBILITY,
  GO_BUTTONS,
  ICONS,
  MOBILE_APP_CATEGORIES,
  ROLES,
  VOICES,
  METHODS,
  PURPOSES,
  PERSONAS,
  DALL_E_QUALITY,
  MESSAGE_WIDTH_OPTIONS,
}
