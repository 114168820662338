<script setup lang="ts">
import { computed, nextTick, onBeforeMount, onMounted, ref } from 'vue'
import { useNotyf } from '/@src/composable/useNotyf'
import { SttHelper, UrlHelper, WindowHelper } from '/@src/helpers'
import { IFile, IQueryParameters } from '/@src/interfaces/index'
import { ICONS } from '/@src/resources/files/constant'
import { useWidget } from '/@src/stores'

// Reactive data
const input: any = ref('')
const isLoading = ref(false)
const contentRef: any = ref(null)
const stt = new SttHelper()
const recodingPermission = ref(false)
const isRecording = ref(false)
const isFocused = ref(false)
const userMessageStylingSettings: any = ref({})

// Composable
const widgetStore = useWidget()
const notyf = useNotyf({
  duration: 1000,
  position: {
    x: 'center',
    y: 'bottom',
  },
})

// Computed
const computedFocus = computed(() => {
  const { focus, botAutofocus }: IQueryParameters = UrlHelper.getWidgetQueryParams()

  return focus ?? botAutofocus
})

// Lifecycle hooks
onBeforeMount(() => {
  userMessageStylingSettings.value = widgetStore.getUserMessageStylingSettings()
})

onMounted(async () => {
  try {
    nextTick(() => {
      if (computedFocus.value && contentRef.value) {
        // @ts-ignore
        contentRef.value?.focus()
      }
    })
  } catch (error) {
    console.error(error)
  }
})

// Functions
const askForPermission = async () => {
  try {
    try {
      await stt.askForPermission()

      recodingPermission.value = true
    } catch (error) {
      recodingPermission.value = false

      notyf.info({
        message: 'Microphone permission required!',
        icon: false,
      })
    }

    return recodingPermission.value
  } catch (error: any) {
    console.error(error)

    notyf.error(error?.message)
  }
}

const startRecording = async () => {
  try {
    if (!recodingPermission.value) {
      if (!(await askForPermission())) {
        return
      }
    }

    if (!isFocused.value || isRecording.value) {
      return
    }

    input.value = ''
    isRecording.value = true

    WindowHelper.postMessage('click_recording', {
      widget_uid: widgetStore.state.widget?.uid,
      chat_uid: widgetStore.state.chat?.uid,
      account_uid: widgetStore.state.account?.uid,
    })

    await stt.startRecording()
  } catch (error: any) {
    console.error(error)

    isRecording.value = false
    input.value = ''

    notyf.error(error?.message)
  }
}

const stopRecording = async () => {
  try {
    if (!recodingPermission.value) {
      return
    }

    if (!isRecording.value) {
      return
    }

    isRecording.value = false

    if (widgetStore.getWidget()?.app_uid) {
      const blob = await stt.stopRecording()

      isRecording.value = false

      if (!blob) {
        return
      }

      try {
        isLoading.value = true

        const filesResponse: IFile[] = await widgetStore.createFiles({
          type: 'audio',
          files: [{ file: new File([blob], 'message.webm'), blob_url: URL.createObjectURL(blob) }],
          transcribe: true,
        })

        await widgetStore.createMessage({
          role: 'user',
          input: filesResponse[0]?.transcription ?? '',
          files: filesResponse,
          showTypingIndicator: true,
        })
      } catch (error) {
        console.error(error)
      } finally {
        isLoading.value = false
      }
    }
  } catch (error: any) {
    console.error(error)

    isRecording.value = false
    input.value = ''

    notyf.error(error?.message)
  }
}
</script>

<template>
  <div
    class="button"
    :class="{ 'disabled': isLoading, 'is-vocal-only': widgetStore.getWidget()?.is_vocal_only }"
    :style="{
      ...widgetStore.computedIconStyle,
      width: '33px',
      height: '33px',
    }"
    @click="askForPermission"
    @mousedown="
      async () => {
        isFocused = true
        await startRecording()
      }
    "
    @mouseup="
      async () => {
        isFocused = false
        await stopRecording()
      }
    "
    @touchstart="
      async () => {
        isFocused = true
        await startRecording()
      }
    "
    @touchend="
      async () => {
        isFocused = false
        await stopRecording()
      }
    "
    @mouseleave="
      () => {
        isFocused = false
        isRecording = false
      }
    "
    @focusout="
      () => {
        isFocused = false
        isRecording = false
      }
    "
  >
    <VIcon
      :icon="ICONS.actions.record"
      class="chat-icons-color"
      :style="{
        color: isRecording === true ? 'var(--mic-recording) !important' : ``,
      }"
    />
  </div>

  <div v-if="widgetStore.getWidget()?.is_vocal_only" class="mt-2">
    <i> Push-to-talk </i>
  </div>
</template>
