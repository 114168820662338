<script setup lang="ts">
import { computed, ref } from 'vue'
import useClipboard from 'vue-clipboard3'
import { useNotyf } from '/@src/composable/useNotyf'
import { ChatbotHelper } from '/@src/helpers'

const props = defineProps({
  title: {
    type: String,
    default: () => '',
    required: false,
  },
  widget: {
    type: Object,
    default: () => {},
    required: true,
  },
})

// Composable
const { toClipboard } = useClipboard()
const notyf = useNotyf({
  duration: 1000,
  position: {
    x: 'center',
    y: 'bottom',
  },
})

// Const data
const domain = ChatbotHelper.getDomain()

// Computed
const embedUrl = computed(() => {
  return `${domain}/embed/${props?.widget?.uid}`
})

const recommendedViewport = computed(() => {
  return `<meta name="viewport" content="width=device-width, height=device-height initial-scale=1.0, maximum-scale=1.0" />`
})

const taggingScriptBubble = computed(() => {
  return `<script>
ICG_BOT_ID = '${props?.widget?.uid}';
ICG_BOT_TYPE = 'bubble';
ICG_BOT_HEIGHT = 750;
ICG_BOT_BG_COLOR = '#fff';
ICG_BOT_AUTOFOCUS = false;
ICG_BOT_OVERRIDE_OPENER = '';
ICG_USER_ID = '';
ICG_USER_EMAIL = '';
ICG_USER_FIRSTNAME = '';
ICG_USER_LASTNAME = '';
ICG_USER_TAGS = [];
ICG_USER_METADATA = {};
<\/script>`?.trim()
})

const taggingScriptWindow = computed(() => {
  return `<script>
ICG_BOT_ID = '${props?.widget?.uid}';
ICG_BOT_TYPE = 'window';
ICG_BOT_HEIGHT = 750;
ICG_BOT_BG_COLOR = '#fff';
ICG_BOT_AUTOFOCUS = false;
ICG_BOT_OVERRIDE_OPENER = '';
ICG_USER_ID = '';
ICG_USER_EMAIL = '';
ICG_USER_FIRSTNAME = '';
ICG_USER_LASTNAME = '';
ICG_USER_TAGS = [];
ICG_USER_METADATA = {};
<\/script>`?.trim()
})

const chatbotScriptBubble = computed(() => {
  const script = `<script type="text/javascript" src="${domain}/widgets/chatbot.js" async><\/script>`
  const tags = taggingScriptBubble.value

  return `${script}\n${tags}`
})

const chatbotScriptWindow = computed(() => {
  const script = `<script type="text/javascript" src="${domain}/widgets/chatbot.js" async><\/script>`
  const tags = taggingScriptWindow.value

  return `${script}\n${tags}`
})

const iframeDiv = computed(() => {
  return `<div id="my-ai-chatbot"></div>`
})

const iframeDivWithScale = computed(() => {
  return `<div id="my-ai-chatbot" style="transform: scale(0.7);"></div>`
})

// Functions
const copy = async (text: string) => {
  try {
    await toClipboard(text)

    notyf.success('Copied')
  } catch (error) {
    console.error(error)

    notyf.error('Error on copy')
  }
}
</script>

<template>
  <!-- Title -->
  <h1 class="title is-5">{{ title }}</h1>

  <div class="columns">
    <div class="column is-4">
      <VButton
        class="w-100"
        href="https://insertchat.notion.site/Wix-1de4b38220e1430890c46372b76aaaf1"
        target="_blank"
      >
        Wix tutorial
      </VButton>
    </div>
    <div class="column is-4">
      <VButton
        class="w-100"
        href="https://insertchat.notion.site/Google-Tag-Manager-6a792d15cf824b49a3574d344b081e02"
        target="_blank"
      >
        GTM tutorial
      </VButton>
    </div>
    <div class="column is-4">
      <VButton
        class="w-100"
        href="https://insertchat.notion.site/Google-Sites-d5fb1eda0e95497c8049850f1543adf4"
        target="_blank"
      >
        Google Sites tutorial
      </VButton>
    </div>
  </div>

  <CustomCollapse with-chevron :item-open="true" color="#f6f6f7">
    <template #collapse-item-summary><b>Install script for chat bubble</b></template>
    <template #collapse-item-content>
      <ul>
        <li>1) Insert the "Script" tag into your page's HTML head section.</li>
        <li>2) Empty your browser cache and refresh the page.</li>
        <li>
          3) To associate metadata with each chat session initiated by your users, you can
          incorporate values into the ICG_USER_ variables.
        </li>
      </ul>
      <br />

      <div class="formkit-outer">
        <div class="formkit-wrapper">
          <label class="formkit-label">Script tag</label>
          <div class="formkit-inner">
            <textarea
              id="chatbotScriptBubble"
              v-model="chatbotScriptBubble"
              rows="14"
              class="formkit-input"
              type="textarea"
            />
          </div>
        </div>
      </div>

      <VButton
        icon="ic:round-copy-all"
        @click="copy(chatbotScriptBubble)"
        @click.prevent="() => {}"
      >
        Copy to clipboard
      </VButton>
    </template>
  </CustomCollapse>

  <CustomCollapse with-chevron :item-open="false" color="#f6f6f7">
    <template #collapse-item-summary><b>Install script for window frame</b></template>
    <template #collapse-item-content>
      <ul>
        <li>1) Insert the "Script" tag into your page's HTML head section.</li>
        <li>2) Place the "Div" tag where you want the window to appear.</li>
        <li>3) Empty your browser cache and refresh the page.</li>
        <li>
          4) To associate metadata with each chat session initiated by your users, you can
          incorporate values into the ICG_USER_ variables.
        </li>
      </ul>
      <br />

      <div class="formkit-outer">
        <div class="formkit-wrapper">
          <label class="formkit-label">Div tag</label>
          <div class="formkit-inner">
            <textarea
              id="iframeDiv"
              v-model="iframeDiv"
              rows="1"
              class="formkit-input"
              type="textarea"
            />
          </div>
        </div>
      </div>

      <VButton icon="ic:round-copy-all" @click="copy(iframeDiv)" @click.prevent="() => {}">
        Copy to clipboard
      </VButton>

      <br />
      <br />

      <div class="formkit-outer">
        <div class="formkit-wrapper">
          <label class="formkit-label">Script tag</label>
          <div class="formkit-inner">
            <textarea
              id="chatbotScriptWindow"
              v-model="chatbotScriptWindow"
              rows="14"
              class="formkit-input"
              type="textarea"
            />
          </div>
        </div>
      </div>

      <VButton
        icon="ic:round-copy-all"
        @click="copy(chatbotScriptWindow)"
        @click.prevent="() => {}"
      >
        Copy to clipboard
      </VButton>
    </template>
  </CustomCollapse>

  <CustomCollapse with-chevron :item-open="false" color="#f6f6f7">
    <template #collapse-item-summary><b>Direct URL</b></template>
    <template #collapse-item-content>
      <div class="formkit-outer">
        <div class="formkit-wrapper">
          <label class="formkit-label">Direct URL</label>
          <div class="formkit-inner">
            <textarea
              id="directUrl"
              v-model="embedUrl"
              rows="1"
              class="formkit-input"
              type="textarea"
            />
          </div>
        </div>
      </div>

      <VButton icon="ic:round-copy-all" @click="copy(embedUrl)" @click.prevent="() => {}">
        Copy to clipboard
      </VButton>
    </template>
  </CustomCollapse>

  <br />

  <CustomCollapse with-chevron color="#EBF8FF">
    <template #collapse-item-summary>
      <h4 class="is-inline">
        <b>Recommended: Safari viewport tag to avoid zooming on iPhone</b>
      </h4>
    </template>
    <template #collapse-item-content>
      The primary use of the viewport tag is for responsiveness and to ensure that mobile users have
      a great experience on your site.

      <br /><br />

      Paste the viewport meta tag in the HTML head section.

      <br /><br />

      <div class="formkit-outer">
        <div class="formkit-wrapper">
          <label class="formkit-label">
            A typical mobile-optimized site contains something like the following
          </label>
          <div class="formkit-inner">
            <textarea
              id="recommendedViewport"
              v-model="recommendedViewport"
              rows="1"
              class="formkit-input"
              type="textarea"
            />
          </div>
        </div>
      </div>

      <VButton
        icon="ic:round-copy-all"
        @click="copy(recommendedViewport)"
        @click.prevent="() => {}"
      >
        Copy to clipboard
      </VButton>
    </template>
  </CustomCollapse>

  <!--
  <CustomCollapse with-chevron color="#EBF8FF">
    <template #collapse-item-summary>
      <h4 class="is-inline">
        <b>Q&A: Why is the scale of my window chatbot appear excessively large?</b>
      </h4>
    </template>
    <template #collapse-item-content>
      Some websites encounter scaling issues, and a potential solution is to apply our recommended
      viewport tag. If this doesn't resolve the problem, consider adjusting the scale of your AI
      chatbot directly using CSS with the div tag below.<br /><br />

      <div class="formkit-outer">
        <div class="formkit-wrapper">
          <label class="formkit-label">Div tag</label>
          <div class="formkit-inner">
            <textarea
              id="iframeDivWithScale"
              v-model="iframeDivWithScale"
              rows="1"
              class="formkit-input"
              type="textarea"
            />
          </div>
        </div>
      </div>

      <VButton icon="ic:round-copy-all" @click="copy(iframeDivWithScale)" @click.prevent="() => {}">
        Copy to clipboard
      </VButton>
    </template>
  </CustomCollapse>

  <CustomCollapse with-chevron color="#EBF8FF">
    <template #collapse-item-summary>
      <h4 class="is-inline">
        <b>Q&A: How to turn my AI chatbot into a mobile app?</b>
      </h4>
    </template>
    <template #collapse-item-content>
      <ul>
        <li>1) Purchase the "Mobile App" add-on from your "Subscriptions" page.</li>
        <li>
          2) Navigate to
          <a href="https://www.pwabuilder.com" target="_blank">pwabuilder.com</a>.
        </li>
        <li>
          3) Paste the following URL into the "Enter the URL to your PWA" input field.
          <b>{{ embedUrl }}</b>
        </li>
        <li>4) Allow the scan to complete.</li>
        <li>5) Click on the "Package For Stores" button.</li>
        <li>
          6) Select the desired store where you intend to publish your app by clicking "Generate
          Package".
        </li>
        <li>
          7) Download the package by clicking on "Download Package" to obtain a zipped file of your
          app.
        </li>
        <li>
          8) Proceed to the chosen store and refer to their official documentation for guidance on
          publishing your app.
        </li>
      </ul>
    </template>
  </CustomCollapse>
  -->
</template>
