<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { StringHelper } from '/@src/helpers'
import { ICONS } from '/@src/resources/files/constant'
import { useUserSession, useViewWrapper } from '/@src/stores'

export type NavbarTheme = 'default' | 'colored' | 'fade'
export type SubnavId = 'closed' | 'home' | 'layouts' | 'elements' | 'components' | 'search'

const props = withDefaults(
  defineProps<{
    theme?: NavbarTheme
    nowrap?: boolean
  }>(),
  {
    theme: 'default',
  }
)

// Composable
const userSession = useUserSession()
const viewWrapper = useViewWrapper()
const route = useRoute()

// Reactive data
const modalFormProps: any = ref({ open: false })
const isMobileSidebarOpen = ref(false)
const activeSubnav = ref<SubnavId>('closed')

// Computed
const computedRoute = computed(() => route)

// Watchers
watch(
  () => route.fullPath,
  () => {
    activeSubnav.value = 'closed'
    isMobileSidebarOpen.value = false
  }
)

// Functions
function toggleSubnav(subnav: SubnavId) {
  if (activeSubnav.value === subnav) {
    activeSubnav.value = 'closed'
  } else {
    activeSubnav.value = subnav
  }
}

const inviteAndEarn = () => {
  modalFormProps.value.open = true
}
</script>

<template>
  <FormInviteEarn
    v-if="modalFormProps?.open && modalFormProps.open === true"
    v-model:modalFormProps="modalFormProps"
  />

  <div class="navbar-layout">
    <div class="app-overlay"></div>

    <!-- Mobile Navbar -->
    <MobileNavbar
      :is-open="isMobileSidebarOpen"
      @toggle="isMobileSidebarOpen = !isMobileSidebarOpen"
    >
      <template #brand>
        <RouterLink :to="{ path: '/chatbots' }" class="navbar-item is-brand">
          <AnimatedLogo :text="true" height="38px" />
        </RouterLink>

        <div class="brand-end"></div>
      </template>
    </MobileNavbar>

    <!-- Mobile Sidebar -->
    <MobileSidebar
      :is-open="isMobileSidebarOpen"
      @toggle="isMobileSidebarOpen = !isMobileSidebarOpen"
    >
      <template #links>
        <li>
          <!-- Chatbots -->
          <RouterLink
            :to="{ path: '/chatbots' }"
            :class="[computedRoute.path === '/chatbots' ? 'is-active' : '']"
          >
            <VIcon :icon="ICONS.pages.chats" />
            <span class="label">Chatbots</span>
          </RouterLink>
        </li>
        <li>
          <!-- Settings -->
          <RouterLink
            :to="{ path: '/settings' }"
            :class="[computedRoute.path === '/settings' ? 'is-active' : '']"
          >
            <VIcon :icon="ICONS.pages.account" />
            <span class="label">Settings</span>
          </RouterLink>
        </li>
        <li v-if="userSession?.isEmployee()">
          <!-- Subscriptions -->
          <RouterLink
            :to="{ path: '/subscriptions' }"
            :class="[computedRoute.path === '/subscriptions' ? 'is-active' : '']"
          >
            <VIcon :icon="ICONS.pages.subscriptions" />
            <span class="label">Subscriptions</span>
          </RouterLink>
        </li>
        <li v-if="userSession?.isEmployee()">
          <!-- Support -->
          <RouterLink
            :to="{ path: '/support' }"
            :class="[computedRoute.path === '/support' ? 'is-active' : '']"
          >
            <VIcon :icon="ICONS.pages.customer_support" />
            <span class="label">Support</span>
          </RouterLink>
        </li>
      </template>

      <!--
      <template #bottom-links>
        <li>
          <a href="#">
            <i aria-hidden="true" class="iconify" data-icon="feather:settings"></i>
          </a>
        </li>
      </template>
      -->
    </MobileSidebar>

    <!-- Mobile subsidebar links -->
    <!--
    <Transition name="slide-x">
      <LayoutsMobileSubsidebar v-if="isMobileSidebarOpen && activeMobileSubsidebar === 'layouts'" />

      <MiscSubnav v-else-if="isMobileSidebarOpen && activeMobileSubsidebar === 'misc'" />
    </Transition>
    -->

    <!-- Desktop navbar -->
    <Navbar :theme="props.theme">
      <!-- Page title -->
      <template #title>
        <RouterLink :to="{ path: '/chatbots' }" class="brand">
          <AnimatedLogo :text="false" width="38px" height="38px" />
        </RouterLink>

        <div class="navbar-separator"></div>

        <h1 class="title is-4">{{ viewWrapper.pageTitle }}</h1>
      </template>

      <!-- Toolbar -->
      <template #toolbar>
        <div
          v-if="
            !['subscriptions-success', 'subscriptions-cancel'].includes(String(computedRoute.name))
          "
          class="columns is-multiline ml-auto is-hidden-mobile driverjs-widgets m-0 has-text-right"
        >
          <WidgetPlan class="mr-2" />
          <WidgetMessageCredits class="mr-2" />
        </div>

        <VDropdown right spaced class="user-dropdown profile-dropdown">
          <template #button="{ toggle }">
            <a class="is-trigger dropdown-trigger" aria-haspopup="true" @click="toggle">
              <VAvatar picture="/images/avatars/svg/user-1.svg" />
            </a>
          </template>

          <template #content>
            <div class="dropdown-head">
              <VAvatar size="medium" picture="/images/avatars/svg/user-1.svg" alt="Avatar" />

              <div class="meta">
                <span>{{ userSession.user?.designation }}</span>
                <span>{{ StringHelper.capitalizeFirstLetter(userSession?.user?.role) }}</span>
              </div>
            </div>

            <!-- Chatbots -->
            <RouterLink :to="{ path: '/chatbots' }" role="menuitem" class="dropdown-item is-media">
              <VIcon :icon="ICONS.pages.chats" class="is-inline mr-3" />
              <span class="is-inline label">Chatbots</span>
            </RouterLink>

            <!-- Settings -->
            <RouterLink :to="{ path: '/settings' }" role="menuitem" class="dropdown-item is-media">
              <VIcon :icon="ICONS.pages.account" class="is-inline mr-3" />
              <span class="is-inline label">Settings</span>
            </RouterLink>

            <!-- Subscriptions -->
            <RouterLink
              v-if="userSession?.isEmployee()"
              :to="{ path: '/subscriptions' }"
              role="menuitem"
              class="dropdown-item is-media"
            >
              <VIcon :icon="ICONS.pages.subscriptions" class="is-inline mr-3" />
              <span class="is-inline label">Subscriptions</span>
            </RouterLink>

            <!-- Support -->
            <RouterLink
              v-if="userSession?.isEmployee()"
              :to="{ path: '/support' }"
              role="menuitem"
              class="dropdown-item is-media"
            >
              <VIcon :icon="ICONS.pages.customer_support" class="is-inline mr-3" />
              <span class="is-inline label">Support</span>
            </RouterLink>

            <hr class="dropdown-divider" />

            <div class="dropdown-item is-button">
              <VButton
                :to="{ path: '/auth/logout' }"
                class="logout-button"
                icon="feather:log-out"
                color="primary"
                role="menuitem"
                raised
                fullwidth
              >
                Logout
              </VButton>
            </div>
          </template>
        </VDropdown>
      </template>

      <!-- Menu -->
      <template #links>
        <div class="centered-links">
          <!-- Chatbots -->
          <RouterLink
            :to="{ path: '/chatbots' }"
            :class="[
              computedRoute.path === '/chatbots' ? 'is-active' : '',
              'centered-link centered-link-toggle',
            ]"
          >
            <VIcon :icon="ICONS.pages.chats" color="#0e111b" />
            <span>Chatbots</span>
          </RouterLink>

          <!-- Subscriptions -->
          <RouterLink
            v-if="userSession?.isEmployee()"
            :to="{ path: '/subscriptions' }"
            :class="[
              computedRoute.path === '/subscriptions' ? 'is-active' : '',
              'centered-link centered-link-toggle',
            ]"
          >
            <VIcon :icon="ICONS.pages.subscriptions" />
            <span>Subscriptions</span>
          </RouterLink>

          <!-- Settings -->
          <RouterLink
            :to="{ path: '/settings' }"
            :class="[
              computedRoute.path === '/settings' ? 'is-active' : '',
              'centered-link centered-link-toggle',
            ]"
          >
            <VIcon :icon="ICONS.pages.account" color="#0e111b" />
            <span>Settings</span>
          </RouterLink>

          <!-- Support -->
          <RouterLink
            :to="{ path: '/support' }"
            :class="[
              computedRoute.path === '/support' ? 'is-active' : '',
              'centered-link centered-link-toggle',
            ]"
          >
            <VIcon :icon="ICONS.pages.customer_support" color="#0e111b" />
            <span>Support</span>
          </RouterLink>

          <!-- Misc -->
          <a
            :class="{
              'is-active': activeSubnav === 'misc' || computedRoute.path.startsWith('/navbar/misc'),
            }"
            class="centered-link centered-link-toggle"
            @click="toggleSubnav('misc')"
          >
            <VIcon icon="ic:round-grid-view" color="#0e111b" />
            <span>Misc</span>
          </a>
        </div>
      </template>

      <!-- Sub navbar -->
      <template #subnav>
        <div
          :class="[!(activeSubnav === 'closed' || activeSubnav === 'search') && 'is-active']"
          class="navbar-subnavbar is-flex is-vcentered"
        >
          <MiscSubnav :class="[activeSubnav === 'misc' && 'is-active']" />
        </div>
      </template>
    </Navbar>

    <!-- Page content -->
    <div class="view-wrapper has-top-nav">
      <div class="page-content-wrapper">
        <!--
          <template v-if="props.nowrap">
            <slot></slot>
          </template>
        -->
        <div class="page-content is-relative">
          <div class="is-navbar-md">
            <div class="page-title has-text-centered">
              <!-- Page title -->
              <div class="title-wrap">
                <VIcon
                  v-if="viewWrapper.pageIcon"
                  :icon="viewWrapper.pageIcon"
                  color="#121933"
                  class="mr-3"
                />

                <h1 class="title is-4 is-inline">{{ viewWrapper.pageTitle }}</h1>
              </div>

              <!--
                <Toolbar class="mobile-toolbar"></Toolbar>
              -->
            </div>

            <div class="page-content-inner">
              <VMessage color="info" v-if="userSession?.app?.license">
                Please don't forget to leave a review. Even a short one can be helpful. It's needed
                to keep the momentum going, and we will truly show our gratitude once the chaos
                settles -
                <a href="https://appsumo.com/products/insertchat/" target="_blank">
                  https://appsumo.com/products/insertchat/
                </a>
              </VMessage>

              <slot></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import '/@src/scss/main.scss';
</style>
