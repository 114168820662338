<script setup lang="ts">
import { computed, onBeforeMount, onMounted, reactive, Reactive, ref, Ref, toRefs } from 'vue'
import { getNode } from '@formkit/core'
import { useNotyf } from '/@src/composable/useNotyf'
import { EventHelper, FileHelper, HttpHelper } from '/@src/helpers'
import { IFileObject } from '/@src/interfaces'
import { useUserSession } from '/@src/stores'

const emit = defineEmits<{
  (e: 'training'): void
  (e: 'synch'): void
}>()

const props = defineProps({
  widgetUid: { type: String, default: '', required: true },
  provider: { type: Object, default: () => ({}), required: true },
  trainingType: { type: String, default: 'urls', required: true },
  plans: { type: Object, default: () => {}, required: true },
})

const { widgetUid, trainingType, provider } = toRefs(props)

// Const data
const formId = 'trainingFilesForm'
const { trainingExtensions, audioExtensions } = FileHelper

// Composable
const userSession = useUserSession()
const notyf = useNotyf()

// Reactive data
const formState: Reactive<any> = reactive({})
const newMaterial: Ref<any> = ref([])
const isLoading = ref(false)
const training: Ref<any> = ref({
  label: '',
})
const loaderProgress = ref(-1)
const loaderText = ref('')
const maxFileSizeLimitation = ref()

// Computed
const isFormDisabled = computed(() => {
  const isInvalid = !formState.valid

  if (isLoading.value || isInvalid) {
    return true
  }

  return false
})

// Lifecycle hooks
onBeforeMount(() => {
  try {
    isLoading.value = true
    maxFileSizeLimitation.value = userSession.getPlanLimitations('max_file_size')

    if (provider.value && provider.value?.uid) {
      training.value = {
        label: provider.value?.label,
      }
    }
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }

  // Init
  onInit
})

onMounted(() => {
  Object.assign(formState, toRefs(getNode(formId)?.context?.state || {}))
})

// Functions
const onInit = () => {
  newMaterial.value = []
}

const validateFiles = (files: IFileObject[]) => {
  const errors: string[] = []
  const sanitizedFiles: IFileObject[] = files?.filter((item: IFileObject) => {
    const file = item?.file
    const fileExtension = `.${file?.name?.split('.').pop()?.toLowerCase()}`

    if (!trainingExtensions.includes(fileExtension)) {
      errors.push(
        `Invalid file extension for ${file?.name}. Accepted formats: ${trainingExtensions.join(', ')}`
      )

      return false
    } else if (!file?.size || file?.size / 1024 > maxFileSizeLimitation.value) {
      errors.push(
        `Invalid file size for ${file?.name}. Maximum size allowed is ${
          maxFileSizeLimitation.value / 1024
        }MB.`
      )

      return false
    }

    return true
  })

  if (errors.length) {
    notyf.error(errors.join('\n'))
  }

  return errors.length ? null : sanitizedFiles
}

const onFileUpload = (event: any) => {
  const invalidFiles = Array.from(event.target.files as FileList)
    .filter((file: File) => file.size / 1024 > maxFileSizeLimitation.value)
    .map((file: File) => file.name)

  if (invalidFiles.length) {
    notyf.error(
      `Invalid file size for ${invalidFiles.join(
        ', '
      )}. Maximum size allowed for your current plan is ${maxFileSizeLimitation.value / 1024}MB.`
    )
  }
}

const triggerSubmit = () => {
  getNode(formId)?.submit()
}

const onSubmit = async () => {
  if (isLoading.value) {
    return
  }

  if (!newMaterial.value || (newMaterial.value && !newMaterial.value.length)) {
    isLoading.value = false

    return
  }

  isLoading.value = true
  loaderProgress.value = 0

  try {
    const data = new FormData()
    const files = validateFiles(newMaterial.value)

    if (files && files.length) {
      files.forEach((file) => {
        if (file?.file) {
          data?.append('files[]', file?.file, file?.name?.toLowerCase())
        }
      })
    }

    const providerResponse = await HttpHelper.post('/providers', provider.value?.uid ?? null, {
      widget_uid: widgetUid.value,
      label: training.value?.label,
      type: trainingType.value,
    })

    if (!provider.value?.uid) {
      Object.assign(provider.value, providerResponse)

      emit('training')

      EventHelper.push('created_training')
    }

    if (provider.value?.uid && Object.keys(newMaterial?.value)?.length) {
      await HttpHelper.batch(
        '/providers/synch',
        provider.value?.uid,
        data,
        {
          batchSize: 25,
          batchKey: '',
        },
        (numBatches: number, i: number) => {
          loaderText.value = `Processing batch ${i + 1}/${numBatches}`
          loaderProgress.value = Math.round((i * 100) / numBatches)
        }
      )

      emit('synch')
    }

    onInit()
    document?.getElementById('training-material-list')?.scrollIntoView({ behavior: 'smooth' })
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
}
</script>

<template>
  <FormKit :id="formId" type="form" :disabled="isLoading" :actions="false" @submit="onSubmit">
    <FormKit
      v-model="training.label"
      type="text"
      label="Label (Private)"
      validation="required:trim"
      placeholder="Knowledge #1"
      validation-visibility="live"
    />

    <CustomForm title="Add Knowledge" subtitle="" class="mb-4">
      <template #body>
        <FormKit
          v-model="newMaterial"
          type="file"
          :accept="
            trainingType === 'files' ? trainingExtensions.join(',') : audioExtensions.join(',')
          "
          :help="`Accepted formats: ${trainingType === 'files' ? trainingExtensions.join(', ') : audioExtensions.join(', ')} - Max size: ${maxFileSizeLimitation / 1024}MB per file`"
          multiple="true"
          style="line-height: 36px; padding-left: 7px"
          @change="onFileUpload"
        />

        <VButtons align="right" class="mt-4">
          <VButton
            :disabled="isFormDisabled"
            :loading="isLoading"
            type="submit"
            color="primary"
            icon="ic:round-check"
            @click="triggerSubmit"
          >
            Save & Train
          </VButton>
        </VButtons>
      </template>
    </CustomForm>
  </FormKit>
</template>
