<route lang="yaml">
meta:
  requiresAuth: true
</route>

<script setup lang="ts">
import { useHead } from '@vueuse/head'
import { computed, ref } from 'vue'
import { DateHelper } from '/@src/helpers'
import { ICONS } from '/@src/resources/files/constant'
import { useUserSession, useViewWrapper } from '/@src/stores'

// Const data
const CRISP_WEBSITE_ID = import.meta.env.VITE_CRISP_WEBSITE_ID
window.CRISP_RUNTIME_CONFIG = {
  lock_maximized: true,
  lock_full_view: true,
  cross_origin_cookies: true,
}

// Reactive data
const title = ref('Support')

// Composable
const viewWrapper = useViewWrapper()
viewWrapper.setPageTitle(title.value)
viewWrapper.setPageIcon(ICONS.pages.customer_support)
useHead({ title: title.value })
const userSession = useUserSession()

// Computed
const crispComputedSrc = computed(() => {
  return `https://go.crisp.chat/chat/embed/?website_id=${CRISP_WEBSITE_ID}&user_email=${userSession?.user?.email}&user_nickname=${userSession?.user?.designation}&crisp_sid=${userSession?.user?.uid}&token_id=${userSession?.user?.uid}&session_merge=true`
})
</script>

<template>
  <NavbarLayout>
    <div class="page-content-inner">
      <VCard>
        <VMessage color="danger">
          <b>Information</b><br />

          - You'll be talking to a live person, not an AI.<br />
          - We are in the Europe/Paris time zone. If we don't respond right away, don't worry! We
          will get back to you as soon as we can.<br />
          - Support is available from Monday to Friday, except for plans with a dedicated support
          manager (Business & Enterprise).<br />
          - We are currently receiving a lot of requests. Please expect a response time of 24-48
          hours, except for plans with a dedicated support manager (Business & Enterprise).<br />

          <br />

          <b>Rules</b><br />

          - Only communicate in English.<br />
          - Start by providing the chatbot's name.<br />
          - Describe your issue in detail.<br />
          - Include examples, screenshots, or videos if possible.
        </VMessage>

        <iframe title="Support" width="100%" height="750" :src="crispComputedSrc"></iframe>
      </VCard>
    </div>
  </NavbarLayout>
</template>
