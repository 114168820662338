<script setup lang="ts">
import messages from '@intlify/unplugin-vue-i18n/messages'
import { useHead } from '@vueuse/head'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { getNode } from '@formkit/core'
import { useNotyf } from '/@src/composable/useNotyf'
import { EventHelper, HttpHelper } from '/@src/helpers'
import { useUserSession, useViewWrapper } from '/@src/stores'

// Const data
const title = 'Profile'

// Reactive data
const form = ref({
  profile: {
    formId: 'formProfile',
    isLoading: false,
    data: {},
    state: {},
  },
  email: {
    formId: 'formChangeEmail',
    isLoading: false,
    data: {},
    state: {},
  },
  password: {
    formId: 'formChangePassword',
    isLoading: false,
    data: {},
    state: {},
  },
})

// Composable
const notyf = useNotyf()
const { t } = useI18n()
const userSession = useUserSession()
useViewWrapper().setPageTitle(title)
useHead({ title })

// Lifecycle hooks
onMounted(async () => {
  try {
    form.value.profile.isLoading = true

    const response = await HttpHelper.get(`/users/${userSession?.user?.uid}`)

    form.value.profile.data = { ...response }
    form.value.email.data = { email: response.email }
  } catch (error) {
    console.error(error)
  } finally {
    form.value.profile.isLoading = false
  }
})

// Functions
const triggerSubmit = (formId: string) => {
  getNode(formId)?.submit()
}

const onChangeProfile = async (fields: any) => {
  try {
    if (form.value.profile.isLoading) {
      return
    }

    EventHelper.push('changed_account_infos')

    form.value.profile.isLoading = true

    const { civility, first_name, last_name } = fields

    const response = await HttpHelper.post('/users/change-infos', null, {
      civility,
      first_name,
      last_name,
    })

    userSession?.setUser(response)

    notyf.success(t('notifications.success'))
  } catch (error) {
    console.error(error)
  } finally {
    form.value.profile.isLoading = false
  }
}

const onChangeEmail = async (fields: any) => {
  try {
    if (form.value.email.isLoading) {
      return
    }

    EventHelper.push('changed_account_email')

    form.value.email.isLoading = true

    const { email, new_email } = fields

    await HttpHelper.post('/users/change-email', null, { email, new_email })

    notyf.success(t('notifications.success'))

    setTimeout(async () => {
      await userSession?.logoutUser()
    }, 1000)
  } catch (error) {
    console.error(error)
  } finally {
    form.value.email.isLoading = false
  }
}

const onChangePassword = async (fields: any) => {
  try {
    if (form.value.password.isLoading) {
      return
    }

    EventHelper.push('changed_account_password')

    form.value.password.isLoading = true

    const { password, password_confirm } = fields

    await HttpHelper.post('/users/change-password', null, { password, password_confirm })

    notyf.success(t('notifications.success'))

    setTimeout(async () => {
      await userSession?.logoutUser()
    }, 1000)
  } catch (error) {
    console.error(error)
  } finally {
    form.value.password.isLoading = false
  }
}

const handlePasswordIconClick = (node: any) => {
  node.props.suffixIcon = node.props.suffixIcon === 'eye' ? 'eyeClosed' : 'eye'
  node.props.type = node.props.type === 'password' ? 'text' : 'password'
}
</script>

<template>
  <h1 class="title is-5">{{ title }}</h1>

  <CustomForm title="Change personal information" subtitle="" class="mb-5">
    <template #buttons>
      <VButton
        :disabled="form.profile.isLoading"
        :loading="form.profile.isLoading"
        type="submit"
        color="primary"
        icon="ic:round-check"
        @click="triggerSubmit('formProfile')"
      >
        Save Changes
      </VButton>
      <VButton
        :disabled="form.profile.isLoading"
        :loading="form.profile.isLoading"
        type="submit"
        color="primary"
        icon="ic:round-check"
        @click="triggerSubmit('formProfile')"
      >
        Save Changes
      </VButton>
    </template>

    <template #body>
      <FormKit
        :id="form.profile.formId"
        v-model="form.profile.data"
        type="form"
        :disabled="form.profile.isLoading"
        :actions="false"
        @submit="onChangeProfile"
      >
        <VLoader v-if="form.profile.isLoading" :translucent="true" :opaque="true" />

        <div class="columns is-multiline">
          <div class="column is-12">
            <FormKit
              type="select"
              name="civility"
              :label="t('page.account.index.form.inputs.civility.label')"
              :options="messages.en.page.account.index.form.inputs.civility.options"
            />
          </div>
          <div class="column is-12">
            <FormKit
              type="text"
              name="first_name"
              :label="t('page.account.index.form.inputs.first_name.label')"
              validation="required:trim"
            />
          </div>
          <div class="column is-12">
            <FormKit
              autocomplete="family-name"
              type="text"
              name="last_name"
              :label="t('page.account.index.form.inputs.last_name.label')"
            />
          </div>
        </div>
      </FormKit>
    </template>
  </CustomForm>

  <CustomForm title="Change e-mail" subtitle="" class="mb-5">
    <template #buttons>
      <VButton
        :disabled="form.email.isLoading"
        :loading="form.email.isLoading"
        type="submit"
        color="primary"
        icon="ic:round-check"
        @click="triggerSubmit('formChangeEmail')"
      >
        Change e-mail
      </VButton>
    </template>

    <template #body>
      <FormKit
        :id="form.email.formId"
        v-model="form.email.data"
        type="form"
        :disabled="form.email.isLoading"
        :actions="false"
        @submit="onChangeEmail"
      >
        <VLoader v-if="form.email.isLoading" :translucent="true" :opaque="true" />

        <FormKit
          type="email"
          name="email"
          validation="email"
          :label="t('page.account.email.form.inputs.email.label')"
          :disabled="true"
        />

        <FormKit
          name="new_email"
          type="email"
          validation="required:trim|email"
          :label="t('page.account.email.form.inputs.new_email.label')"
        />
      </FormKit>
    </template>
  </CustomForm>

  <CustomForm title="Change password" subtitle="" class="mb-5">
    <template #buttons>
      <VButton
        :disabled="form.password.isLoading"
        :loading="form.password.isLoading"
        type="submit"
        color="primary"
        icon="ic:round-check"
        @click="triggerSubmit('formChangePassword')"
      >
        Change password
      </VButton>
    </template>

    <template #body>
      <FormKit
        :id="form.password.formId"
        v-model="form.password.data"
        type="form"
        :disabled="form.password.isLoading"
        :actions="false"
        @submit="onChangePassword"
      >
        <VLoader v-if="form.password.isLoading" :translucent="true" :opaque="true" />

        <FormKit
          type="password"
          name="password"
          label="New password"
          validation="required:trim"
          suffix-icon="eyeClosed"
          suffix-icon-class="hover:text-blue-500"
          @suffix-icon-click="handlePasswordIconClick"
        />

        <FormKit
          type="password"
          name="password_confirm"
          label="Confirm new password"
          validation="required:trim|confirm"
          suffix-icon="eyeClosed"
          suffix-icon-class="hover:text-blue-500"
          @suffix-icon-click="handlePasswordIconClick"
        />
      </FormKit>
    </template>
  </CustomForm>
</template>
