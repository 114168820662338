<script setup lang="ts">
// import { useRoute } from 'vue-router'

// Composable
// const route = useRoute()
</script>

<template>
  <div class="navbar-navbar">
    <div class="navbar-navbar-inner">
      <div class="center">
        <div class="centered-links">
          <!--
          <RouterLink
            :to="{ path: '/chatbots' }"
            class="centered-link centered-link-toggle"
            :class="{
              'is-active': route.path.startsWith('/chatbots'),
            }"
          >
            <VIcon icon="ic:round-monetization-on" color="#0e111b" />
            <span>Chatbots</span>
          </RouterLink>
          -->

          <!-- API -->
          <a
            href="https://bit.ly/insertchat-api"
            target="_blank"
            class="centered-link centered-link-toggle"
          >
            <VIcon icon="ic:round-api" color="#0e111b" />
            <span>API</span>
          </a>

          <!-- API -->
          <a
            href="https://zapier.com/apps/ai-chatbots-by-insertchat-ca2078/integrations"
            target="_blank"
            class="centered-link centered-link-toggle"
          >
            <VIcon icon="ic:round-auto-awesome" color="#0e111b" />
            <span>Zapier</span>
          </a>

          <!-- Live Demo -->
          <a
            href="https://insertchat.com/demo"
            target="_blank"
            class="centered-link centered-link-toggle"
          >
            <VIcon icon="ic:round-try" color="#0e111b" />
            <span>Live Demo</span>
          </a>

          <!-- Discord -->
          <a
            href="https://discord.com/invite/ThnrstYEcK"
            target="_blank"
            class="centered-link centered-link-toggle"
          >
            <VIcon icon="ic:round-try" color="#0e111b" />
            <span>Join our Discord</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.navbar-navbar {
  position: relative;
  height: 100%;
  border-radius: var(--radius-large);
  z-index: 2147483647;
  border-bottom: none;
}
</style>
