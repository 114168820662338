import ClarityHelper from './clarity'
import CrispHelper from './crisp'
import GtmHelper from './gtm'
// import SendinblueHelper from './sendinblue'
import ObjectHelper from './object'
import PostHogHelper from './posthog'
import { IUser } from '/@src/interfaces'
// import ProfitwellHelper from './profitwell'
import { useUserSession } from '/@src/stores'

export default class EventHelper {
  public static config(params: {
    user: IUser
    app?: object
    subscription?: object
    plan?: object
    usage?: object
  }) {
    try {
      const { user } = params

      if (import.meta.env.MODE !== 'production') {
        return
      }

      // Disable for admin
      if (
        (user?.email && user?.email.includes('mehbi')) ||
        (user?.email && user?.email.includes('insertchat.com')) ||
        (user?.email && user?.email.includes('insertchatgpt.com'))
      ) {
        return
      }

      const _user = user ? ObjectHelper.removeNullKeys(user) : {}
      /*
    const _app = app ? ObjectHelper.removeNullKeys(app) : {}
    const _subscription = subscription ? ObjectHelper.removeNullKeys(subscription) : {}
    const _plan = plan ? ObjectHelper.removeNullKeys(plan) : {}
    const _usage = usage ? ObjectHelper.removeNullKeys(usage) : {}
    const data = {
      user: _user,
      app: _app,
      subscription: _subscription,
      plan: _plan,
      usage: _usage,
    }
    */

      // GTM
      try {
        GtmHelper.config(_user)
      } catch (error) {
        console.error('GTM:' + error)
      }

      // Crisp
      try {
        CrispHelper.config(_user)
      } catch (error) {
        console.error('Crisp:' + error)
      }

      // Clarity
      try {
        ClarityHelper.config(_user)
      } catch (error) {
        console.error('Clarity:' + error)
      }

      // PostHog
      try {
        PostHogHelper.config(_user)
      } catch (error) {
        console.error('PostHog:' + error)
      }
    } catch (error) {
      console.error(error)
    }
  }

  public static push = (event: string, data: object = {}): void => {
    try {
      if (import.meta.env.MODE !== 'production') {
        return
      }

      const _data = data ? ObjectHelper.removeNullKeys(data) : {}
      const userSession = useUserSession()

      // Disable for admin
      if (
        (userSession?.user?.email && userSession?.user?.email.includes('mehbi')) ||
        (userSession?.user?.email && userSession?.user?.email.includes('insertchat.com'))
      ) {
        return
      }

      // GTM
      try {
        GtmHelper.push(event, _data)
      } catch (error) {
        console.error('GTM:' + error)
      }

      // Crisp
      if (userSession?.isLoggedIn) {
        try {
          CrispHelper.push(event)
        } catch (error) {
          console.error('Crisp:' + error)
        }
      }

      // Clarity
      try {
        ClarityHelper.push(event)
      } catch (error) {
        console.error('Clarity:' + error)
      }

      // PostHog
      try {
        PostHogHelper.push(event, _data)
      } catch (error) {
        console.error('PostHog:' + error)
      }
    } catch (error) {
      console.error(error)
    }
  }

  public static sessionReset() {
    try {
      if (import.meta.env.MODE !== 'production') {
        return
      }

      // @ts-ignore
      // delete window?.sib
      // @ts-ignore
      // delete window?.sendinblue

      CrispHelper.sessionReset()
    } catch (error) {
      console.error(error)
    }
  }
}

// @ts-ignore
window.event_config = EventHelper.config

// @ts-ignore
window.event_push = EventHelper.push

// @ts-ignore
window.event_reset = EventHelper.sessionReset

// @ts-ignore
window.event_mode = import.meta.env.MODE
