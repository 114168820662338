<script setup lang="ts">
import { useHead } from '@vueuse/head'
import { computed, onMounted, reactive, ref, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
import { getNode } from '@formkit/core'
import { useNotyf } from '/@src/composable/useNotyf'
import { EventHelper, HttpHelper } from '/@src/helpers'
import { ICONS } from '/@src/resources/files/constant'
import { useApp, useUserSession, useViewWrapper } from '/@src/stores'

// Const data
const formId = 'formWhiteLabel'
const title = 'White-Label'

// Composable
const notyf = useNotyf()
const { t } = useI18n()
const userSession = useUserSession()
const viewWrapper = useViewWrapper()
const app = useApp()
viewWrapper.setPageTitle(title)
viewWrapper.setPageIcon(ICONS.pages.whitelabel)
useHead({ title })

// Reactive data
const isLoading = ref(false)
const formData: any = ref({})
const formState: any = reactive({})
const logoSignedUrl = ref('')
const faviconSignedUrl = ref('')
const addons: any = ref({})
const domainLimitation = ref()

// Computed
const isFormDisabled = computed(() => {
  const isInvalid = !formState.valid

  if (isLoading.value || isInvalid) {
    return true
  }

  return false
})

// Lifecycle hooks
onMounted(async () => {
  try {
    Object.assign(formState, toRefs(getNode(formId)?.context?.state || {}))

    await init()
  } catch (error) {
    console.error(error)
  }
})

// Functions
const init = async () => {
  try {
    isLoading.value = true

    domainLimitation.value = userSession.getPlanLimitations('domain')

    const [plansResponse, settingsResponse] = await Promise.all([
      app.loadPlans('all'),
      HttpHelper.get(`/apps/settings`),
    ])

    addons.value = plansResponse?.addons
    logoSignedUrl.value = settingsResponse?.logo_signed_url
    faviconSignedUrl.value = settingsResponse?.favicon_signed_url
    formData.value.page_title = settingsResponse?.page_title
    formData.value.company_name = settingsResponse?.company_name
    formData.value.sender_name = settingsResponse?.sender_name
    formData.value.sender_email = settingsResponse?.sender_email
    formData.value.sender_signature = settingsResponse?.sender_signature

    if (settingsResponse?.logo_signed_url) {
      userSession.setLogo(settingsResponse?.logo_signed_url)
    }

    if (settingsResponse?.favicon_signed_url) {
      userSession.setFavicon(settingsResponse?.favicon_signed_url)
    }

    if (settingsResponse?.page_title) {
      useHead({
        title: settingsResponse?.page_title,
      })
    }
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
}

const triggerSubmit = () => {
  getNode(formId)?.submit()
}

const onSubmit = async (fields: any) => {
  try {
    if (isLoading.value) {
      return
    }

    EventHelper.push('changed_account_logo')

    isLoading.value = true

    const { logo, favicon, company_name, page_title, sender_name, sender_email, sender_signature } =
      fields

    // If user uploaded a logo or favicon
    if (
      (logo && logo?.length && logo[0]?.file) ||
      (favicon && favicon?.length && favicon[0]?.file)
    ) {
      const data = new FormData()

      data.append('logo', logo[0]?.file)
      data.append('favicon', favicon[0]?.file)
      data.append('page_title', page_title)
      data.append('company_name', company_name)
      data.append('sender_name', sender_name)
      data.append('sender_email', sender_email)
      data.append('sender_signature', sender_signature)

      await HttpHelper.post('/apps/settings', null, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    } else {
      await HttpHelper.post('/apps/settings', null, {
        company_name,
        page_title,
        sender_name,
        sender_email,
        sender_signature,
      })
    }

    await init()

    notyf.success(t('notifications.success'))
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
}
</script>

<template>
  <h1 class="title is-5">{{ title }}</h1>

  <CustomForm title="Configuration" subtitle="">
    <template #buttons>
      <VButton
        :disabled="isFormDisabled || !domainLimitation"
        :loading="isLoading"
        type="submit"
        color="primary"
        icon="ic:round-check"
        @click="triggerSubmit"
      >
        Save Changes
      </VButton>
    </template>

    <template #body>
      <FormKit
        :id="formId"
        v-model="formData"
        type="form"
        :config="{ validationVisibility: 'live' }"
        :disabled="isLoading || !domainLimitation"
        :actions="false"
        @submit="onSubmit"
      >
        <VLoader v-if="isLoading" :translucent="true" :opaque="true" />

        <div class="columns is-multiline">
          <div v-if="!domainLimitation" class="column is-12">
            <MarketingMessage :plan="addons.branding" cta="Purchase Now">
              Purchase the “White-Label” addon to unlock this feature!
            </MarketingMessage>
          </div>

          <!-- App -->
          <div class="column is-12">
            <CustomForm title="App">
              <template #body>
                <div class="columns is-multiline">
                  <!-- Company name -->
                  <div class="column is-6">
                    <FormKit
                      type="text"
                      label="Company"
                      name="company_name"
                      validation="trim"
                      placeholder="Company name"
                      :disable="isLoading || !domainLimitation"
                    />
                  </div>

                  <!-- Page title -->
                  <div class="column is-6">
                    <FormKit
                      type="text"
                      label="Page title"
                      name="page_title"
                      validation="trim"
                      placeholder="Page title"
                      :disable="isLoading || !domainLimitation"
                    />
                  </div>
                </div>
              </template>
            </CustomForm>
          </div>

          <!-- Images -->
          <div class="column is-12">
            <CustomForm title="Images">
              <template #body>
                <div class="columns">
                  <!-- Logo -->
                  <div class="column is-6">
                    <FormKit
                      type="file"
                      name="logo"
                      label="Logo"
                      accept=".jpg,.jpeg,.png,.gif"
                      multiple="false"
                      :disable="isLoading || !domainLimitation"
                    />

                    <CustomEmbed
                      v-if="logoSignedUrl && logoSignedUrl !== ''"
                      :url="logoSignedUrl"
                      :is-image="true"
                      :hide-actions="true"
                      content-height="200px"
                    />
                  </div>
                  <!-- Favicon -->
                  <div class="column is-6">
                    <FormKit
                      type="file"
                      name="favicon"
                      label="Favicon"
                      accept=".jpg,.jpeg,.png,.gif,.ico"
                      multiple="false"
                      :disable="isLoading || !domainLimitation"
                    />

                    <CustomEmbed
                      v-if="faviconSignedUrl && faviconSignedUrl !== ''"
                      :url="faviconSignedUrl"
                      :is-image="true"
                      :hide-actions="true"
                      content-height="200px"
                    />
                  </div>
                </div>
              </template>
            </CustomForm>
          </div>

          <!-- Emailing -->
          <div class="column is-12">
            <CustomForm title="Global e-mailing">
              <template #body>
                <FormKit
                  type="text"
                  label="Sender name"
                  name="sender_name"
                  validation="trim"
                  :disable="isLoading || !domainLimitation"
                  placeholder="InsertChat"
                />

                <FormKit
                  type="email"
                  label="Sender e-mail"
                  name="sender_email"
                  validation="trim|email"
                  :disabled="isLoading || !domainLimitation"
                  placeholder="admin@insertchat.com"
                />

                <FormKit
                  type="textarea"
                  label="Sender signature"
                  name="sender_signature"
                  validation="trim"
                  :disabled="isLoading || !domainLimitation"
                  placeholder="Sent by InsertChat"
                />
              </template>
            </CustomForm>
          </div>
        </div>
      </FormKit>
    </template>
  </CustomForm>
</template>
