<script setup lang="ts">
import { ref } from 'vue'
import { ICONS } from '/@src/resources/files/constant'

const props = defineProps({
  message: {
    type: String,
    required: true,
    default: '',
  },
  cta: {
    type: String,
    required: true,
    default: 'Upgrade Now',
  },
  plan: {
    type: Object,
    required: true,
    default: () => {},
  },
  height: {
    type: Number,
    required: false,
    default: 38,
  },
})

// Reactive data
const modalCheckoutProps: any = ref({ clientSecret: null, open: false, refresh: false })

// Function
const openSubscribeModal = async () => {
  modalCheckoutProps.value = {
    open: true,
    plan: props.plan,
    period: 'monthly',
    quantity: 1,
  }
}

const closeModal = async (refresh: boolean) => {
  try {
    if (refresh) {
      window.location.reload()
    }
  } catch (error) {
    console.error(error)
  }
}
</script>

<template>
  <CheckoutModal
    v-if="modalCheckoutProps?.open && modalCheckoutProps.open === true"
    v-model:modalProps="modalCheckoutProps"
    @close="closeModal"
  />

  <VMessage color="warning">
    <div class="columns is-multiline is-desktop is-vcentered">
      <div class="column">
        <slot name="default"> </slot>
      </div>
      <div
        class="column is-narrow-desktop is-flex is-justify-content-flex-end is-align-self-center"
      >
        <VButton
          :style="`height:${height}px`"
          class="w-100"
          color="primary"
          :icon="ICONS.pages.subscriptions"
          @click="openSubscribeModal"
        >
          {{ props.cta }}
        </VButton>
      </div>
    </div>
  </VMessage>
</template>
