<script setup lang="ts">
import { StripeHelper } from '/@src/helpers'
import { useUserSession } from '/@src/stores'

// Composable
const userSession = useUserSession()
</script>

<template>
  <div class="column is-inline-block p-0 driverjs-widget-plan">
    <RouterLink :to="{ path: '/subscriptions' }">
      <VTag
        v-if="StripeHelper.isSubscriptionActive(userSession?.subscription) && userSession?.plan"
        color="info"
      >
        {{ `Subscription: ${userSession?.plan?.title}` }}
      </VTag>
      <VTag v-else color="danger">
        {{ 'No active subscription!' }}
      </VTag>
    </RouterLink>
  </div>
</template>
