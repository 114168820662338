<script setup lang="ts">
import { useEventBus } from '@vueuse/core'
import qs from 'qs'
import { computed, onMounted, onUnmounted, PropType, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { VFlexTableWrapperColumn } from '/@src/components/base/table/VFlexTableWrapper.vue'
import { useQueryParam } from '/@src/composable/useQueryParam'
import { useTableDataResolver } from '/@src/composable/useTableDataResolver'
import { ObjectHelper } from '/@src/helpers'
import { IFilter } from '/@src/interfaces'

const emit = defineEmits<{
  (e: 'update:refresh', value: boolean): void
  (e: 'update:metadata', value: any[]): void
}>()

const props = defineProps({
  title: {
    type: String,
    default: undefined,
    required: false,
  },
  url: {
    type: String,
    default: '',
    required: true,
  },
  expand: {
    type: Array,
    default: () => [],
    required: false,
  },
  filters: {
    type: Array as PropType<IFilter[]>,
    default: () => [],
    required: false,
  },
  refresh: {
    type: Boolean,
    default: false,
    required: true,
  },
  columns: {
    type: Object as PropType<Record<string, string | Partial<VFlexTableWrapperColumn>>>,
    default: undefined,
    required: true,
  },
  onRowClick: {
    type: Function as PropType<(row: any) => void>,
    default: undefined,
    required: false,
  },
  enableWrapperClass: {
    type: Boolean,
    default: true,
    required: false,
  },
  onEmptyTable: {
    type: Function,
    default: undefined,
    required: false,
  },
  overrideLoading: {
    type: Boolean,
    default: false,
    required: false,
  },
  refreshEveryMs: {
    type: Number,
    default: 0,
    required: false,
  },
})
const eventBus = useEventBus(props.url)

// Const data
// @ts-ignore
const expand = qs.parse(props.expand)
// @ts-ignore
const filters = qs.parse(props.filters)
const url = `${props.url}?${qs.stringify({ expand, filters }, { arrayFormat: 'indices' })}`
const propertyUid = ObjectHelper.findByString(props.filters, 'key', 'property_uid')?.value

// Reactive data
const data = ref([])
const total = ref(0)
const isLoading = ref(true)
const { searchTerm, sort, limit, page } = useQueryParam()
const refreshInterval = ref<number | null>(null)

// Composable
// const slots = useSlots()
const { t } = useI18n()

// Computed data
const filteredColumns = computed(() => {
  const columns = { ...props.columns }

  if (propertyUid) {
    delete columns.property
  }

  return columns
})

const computedData = computed(() => {
  return Array.isArray(data.value) ? { 0: data.value } : data.value
})

// Create a function to fetch data
const fetchData = async () => {
  try {
    if (data.value && data.value?.length === 0) {
      isLoading.value = true
      total.value = 1
    }

    const response = await useTableDataResolver({
      url,
      searchTerm: searchTerm.value,
      start: (page.value - 1) * limit.value,
      limit: limit.value,
      sort: sort.value,
    })

    // Only update if data is different or if data is empty
    if (JSON.stringify(response?.data) !== JSON.stringify(data.value)) {
      data.value = response?.data ?? []
      total.value = response?.total ?? 0

      if (props.onEmptyTable && !data.value.length) {
        props.onEmptyTable()
      }

      emit('update:metadata', response?.meta)
      emit('update:refresh', false)
    }
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
}

// Set up the interval
onMounted(() => {
  if (props.refreshEveryMs > 0) {
    refreshInterval.value = setInterval(fetchData, props.refreshEveryMs)
  }
})

// Clear the interval when the component is unmounted
onUnmounted(() => {
  if (refreshInterval.value) {
    clearInterval(refreshInterval.value)
  }
})

// API calls & Watchers
watch(
  [searchTerm, sort, limit, page, () => props.refresh],
  async ([, , , , newRefresh], [, , , , oldRefresh]) => {
    try {
      // Avoid the execution when refresh is moving from true to false
      if (oldRefresh === true && newRefresh === false) {
        return
      }

      await fetchData()
    } catch (error) {
      console.error(error)
    } finally {
      isLoading.value = false
    }
  },
  { immediate: true }
)

eventBus.on(() => {
  fetchData()
})
</script>

<template>
  <VLoader v-if="props.overrideLoading" :translucent="true" />

  <div v-if="$slots['buttons']" class="mb-5">
    <div class="columns is-vcentered">
      <div v-if="props.title" class="column is-6">
        <h1 class="title is-5">{{ props.title }}</h1>
      </div>

      <div :class="['column', props.title ? 'is-6' : 'is-12']">
        <slot name="buttons"> </slot>
      </div>
    </div>
  </div>

  <div v-if="$slots['message']" class="mb-5">
    <slot name="message"> </slot>
  </div>

  <slot name="pinned"> </slot>

  <div v-for="[key, value] of Object.entries(computedData)" :key="key">
    <!--
    <VFlexTableWrapper
			:data="value"
			:search-term="searchTerm"
			:limit="limit"
			:sort="sort"
			:page="page"
			:columns="filteredColumns"
			:total="total"
			:debounce-search="1000"
			:class="[enableWrapperClass ? 'flex-table-wrapper' : '', isNaN(key) && 'mb-5']"
		>
			<template #default>
    -->
    <div
      :class="[
        enableWrapperClass && 'flex-table-wrapper',
        isNaN(key) && 'mb-5',
        props.overrideLoading && 'is-opaque',
      ]"
    >
      <!--
      <VFlexTableToolbar v-if="!isNaN(key) && !filters.length">
        <template #left>
          <VFlex>
            <VField class="w-100">
              <VControl icon="feather:search">
                <input
                  v-model="searchTerm"
                  type="text"
                  class="input"
                  :placeholder="t('table.search.placeholder')"
                />
              </VControl>
            </VField>
          </VFlex>
        </template>
        <template #right>
          <VFlex>
            <VField>
              <VControl>
                <div class="select">
                  <select v-model="limit">
                    <option :value="25">
                      {{ t('table.search.results_per_page', { count: 25 }) }}
                    </option>
                    <option :value="50">
                      {{ t('table.search.results_per_page', { count: 50 }) }}
                    </option>
                    <option :value="100">
                      {{ t('table.search.results_per_page', { count: 100 }) }}
                    </option>
                    <option :value="200">
                      {{ t('table.search.results_per_page', { count: 200 }) }}
                    </option>
                  </select>
                </div>
              </VControl>
            </VField>
          </VFlex>
        </template>
      </VFlexTableToolbar>
      -->

      <h3 v-if="isNaN(key)" class="title is-5 is-bolder ml-2">{{ key }}</h3>

      <VFlexTable
        :data="value"
        :columns="filteredColumns"
        clickable
        rounded
        @row-click="onRowClick"
      >
        <template #body>
          <!-- Loading placeholder -->
          <div v-if="isLoading">
            <div v-for="key2 in total < limit ? total : limit" :key="key2" class="flex-table-item">
              <VFlexTableCell v-for="columnItem in columns" :key="columnItem" :column="columnItem">
                <VPlaceload />
              </VFlexTableCell>
            </div>
          </div>

          <!-- No results in the database -->
          <div v-else-if="data.length === 0 && searchTerm === ''">
            <VPlaceholderSection
              :title="t('table.section.welcome.title')"
              :subtitle="t('table.section.welcome.subtitle')"
              class="my-6"
            >
              <!--
              <template #image>
                <img
                  class="light-image has-fullwidth mb-5"
                  src="https://cdn.insertchat.com/illustrations/placeholders/launch.svg"
                  alt=""
                />
              </template>
              -->

              <template #action v-if="$slots['buttons']">
                <VButtons align="centered" class="mt-3">
                  <slot name="buttons"> </slot>
                </VButtons>
              </template>
            </VPlaceholderSection>
          </div>

          <!-- No results after search -->
          <div v-else-if="data.length === 0 && searchTerm !== ''">
            <VPlaceholderSection
              :title="t('table.section.no_results.title')"
              :subtitle="t('table.section.no_results.subtitle')"
              class="my-6"
            >
              <template #image>
                <img
                  class="light-image has-fullwidth mb-5"
                  src="https://cdn.insertchat.com/illustrations/placeholders/search-6.svg"
                  alt=""
                />
                <img
                  class="dark-image has-fullwidth mb-5"
                  src="https://cdn.insertchat.com/illustrations/placeholders/search-6-dark.svg"
                  alt=""
                />
              </template>
            </VPlaceholderSection>
          </div>
        </template>

        <template #body-row-pre="{ row }">
          <slot name="body-row-pre" :row="row" :columns="columns"> </slot>
        </template>

        <!-- Table -->
        <template #body-row="{ row }">
          <slot name="row" :row="row" :columns="columns" @row-click="onRowClick"> </slot>
        </template>

        <template #body-row-post="{ row }">
          <slot name="body-row-post" :row="row" :columns="columns"> </slot>
        </template>
      </VFlexTable>

      <!-- Table pagination -->
      <VFlexPagination
        v-if="!filters?.length && Object.keys(data)?.length"
        v-model:currentPage="page"
        :item-per-page="limit"
        :total-items="total"
        :max-links-displayed="3"
        class="mt-3"
        no-router
      />
    </div>
    <!--
			</template>
      </VFlexTableWrapper>
    -->
  </div>
</template>

<style lang="scss">
.is-opaque {
  opacity: 0.5;
  background: var(--white);
}
</style>
