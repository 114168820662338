<script setup lang="ts">
import { computed, onUnmounted, ref, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import { ICONS } from '/@src/resources/files/constant'

export type VModalSize = 'small' | 'medium' | 'large' | 'big' | 'stripe'
export type VModalAction = 'center' | 'right'

export interface ITab {
  label: string
  value: string
  icon?: string
}

export interface VModalEmits {
  (e: 'close'): void
  (e: 'fill'): void
  (e: 'update:selectedTab', value: string): void
}

export interface VModalProps {
  icon?: string
  title: string
  size?: VModalSize
  actions?: VModalAction
  open?: boolean
  rounded?: boolean
  noscroll?: boolean
  noclose?: boolean
  tabs?: Array<ITab>
  selectedTab?: string
  cancelLabel?: string
  overrideModal?: boolean
  disableHeader?: boolean
  disableFooter?: boolean
  narrow?: boolean
  removeCloseBtn?: boolean
  zIndex?: string
  teleportTo?: string
}

const emit = defineEmits<VModalEmits>()
const props = withDefaults(defineProps<VModalProps>(), {
  size: undefined,
  actions: undefined,
  cancelLabel: undefined,
  selectedTab: '',
  tabs: () => [],
  overrideModal: false,
  disabledFooter: false,
  zIndex: '214748364',
  narrow: true,
  teleportTo: 'body',
})

// Composable
const { t } = useI18n()
const wasOpen = ref(false)

// Computed
const cancelLabel = computed(() => props.cancelLabel || t('cancel-label'))

const checkScroll = () => {
  if (props.noscroll && props.open) {
    document.documentElement.classList.add('no-scroll')
    wasOpen.value = true
  } else if (wasOpen.value && props.noscroll && !props.open) {
    document.documentElement.classList.remove('no-scroll')
    wasOpen.value = false
  }
}

watchEffect(checkScroll)

// Lifecycle hooks
onUnmounted(() => {
  document.documentElement.classList.remove('no-scroll')
})

// Functions
const onNavigation = (previous = false) => {
  if (props.tabs.length && props.selectedTab !== '') {
    // Actual tab
    const selectedTabIndex = props.tabs.findIndex((tab) => tab.value === props.selectedTab)

    if (previous) {
      const previousTab = props.tabs[selectedTabIndex - 1]

      if (typeof previousTab === 'undefined') {
        emit('update:selectedTab', props.tabs[props.tabs.length - 1].value)
      } else {
        emit('update:selectedTab', previousTab.value)
      }
    } else {
      const nextTab =
        selectedTabIndex === props.tabs.length - 1
          ? props.tabs[0]
          : props.tabs[selectedTabIndex + 1]

      emit('update:selectedTab', nextTab.value)
    }
  }
}
</script>

<i18n lang="yaml">
de:
  cancel-label: 'Abbrechen'
en:
  cancel-label: 'Cancel'
es-MX:
  cancel-label: 'Cancelar'
es:
  cancel-label: 'Cancelar'
fr:
  cancel-label: 'Annuler'
zh-CN:
  cancel-label: '取消'
</i18n>

<template>
  <Teleport :to="props?.teleportTo" :disabled="overrideModal">
    <div
      class="modal v-modal"
      :class="[open && 'is-active', size && `is-${size}`, overrideModal && 'override-modal']"
      :style="`z-index: ${props.zIndex} !important`"
      v-bind="$attrs"
    >
      <div
        class="modal-background v-modal-close"
        tabindex="0"
        @keydown.space.prevent="() => noclose === false && emit('close')"
        @click="() => noclose === false && emit('close')"
      ></div>
      <div class="modal-content">
        <!-- <div class="modal-card" :style="{ height: 'calc(80vh)' }"> -->
        <div class="modal-card" :class="overrideModal || narrow ? '' : 'is-fullheight-modal'">
          <header
            v-if="!props.disableHeader"
            class="modal-card-head"
            :class="!props.disableHeader && 'no-header'"
            :style="title ? 'height: 59px' : ''"
          >
            <VIcon v-if="icon" :icon="icon" class="chat-icons-color" />
            <h1 class="title is-5 mb-0">{{ title }}</h1>
            <div class="is-flex ml-auto">
              <VButton
                v-if="!props.removeCloseBtn"
                class="v-modal-close"
                aria-label="close"
                tabindex="0"
                @keydown.space.prevent="emit('close')"
                @click="emit('close')"
              >
                <VIcon ref="close_modal" :icon="ICONS.forms.close" />
              </VButton>
            </div>
          </header>
          <div class="modal-card-body" :class="[props.tabs.length && 'has-tabs']">
            <div class="inner-content">
              <slot name="default"> </slot>
            </div>
          </div>
          <div
            v-if="!props.disableFooter"
            class="modal-card-foot"
            :class="[
              props.disableFooter && 'no-footer',
              actions === 'center' && 'is-centered',
              actions === 'right' && 'is-end',
            ]"
          >
            <div v-if="!props.disableFooter" class="form-footer">
              <div class="left">
                <slot v-if="!noclose" name="cancel" :close="() => emit('close')">
                  <a
                    tabindex="0"
                    class="button v-button v-modal-close"
                    :class="[rounded && 'is-rounded']"
                    @keydown.space.prevent="emit('close')"
                    @click="emit('close')"
                  >
                    {{ cancelLabel }}
                  </a>
                </slot>
              </div>

              <div v-if="props.tabs.length > 1" class="center">
                <VButton type="submit" class="back is-secondary p-3" @click="onNavigation(true)">
                  <VIcon :icon="ICONS.forms.backward" />
                </VButton>

                <VButton
                  type="submit"
                  class="forward is-secondary p-3"
                  @click="onNavigation(false)"
                >
                  <VIcon :icon="ICONS.forms.forward" />
                </VButton>
              </div>

              <div class="right">
                <slot name="action" :close="() => emit('close')"> </slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<style lang="scss">
.modal {
  opacity: 1;
  background: transparent;
  transition: all 0.5s;

  &.is-stripe {
    .modal-content {
      width: 100%;
      // max-width: 100%;
      max-width: 1060px;

      .modal-card {
        width: 100%;
      }
    }
  }

  &.is-big {
    .modal-content {
      width: 100%;
      max-width: 900px;

      .modal-card {
        width: 100%;
      }
    }
  }

  &.is-large {
    .modal-content {
      width: 100%;
      max-width: 720px;

      .modal-card {
        width: 100%;
      }
    }
  }

  &.is-medium {
    .modal-content {
      width: 100%;
      max-width: 640px;

      .modal-card {
        width: 100%;
      }
    }
  }

  &.is-small {
    .modal-content {
      width: 100%;
      max-width: 420px;

      .modal-card {
        width: 100%;
      }
    }
  }

  .modal-content {
    overflow: hidden;
    transition: all 0.4s;
    max-height: 100%;
    // max-height: 90%;
  }
}

.v-modal {
  &.is-active {
    z-index: 999999999999 !important;

    .v-modal-close {
      cursor: pointer;
    }
  }

  .v-modal-card {
    width: 100%;
    background: var(--white);
    border: 1px solid var(--border);
    border-radius: var(--radius-large);
    padding: 40px;
  }

  .modal-background {
    background-color: hsl(var(--dark-sidebar-h) var(--dark-sidebar-s) var(--dark-sidebar-l) / 80%);
  }

  .modal-content {
    transform: scale(1) !important;
    opacity: 1 !important;
    max-width: 540px;
    overflow-x: hidden;
    animation: fadeInDown 0.5s;
    margin: 0;
    padding: 0 10px;

    .no-header {
      border-top-left-radius: var(--radius-large);
      border-top-right-radius: var(--radius-large);
    }

    .modal-card {
      border-bottom-left-radius: var(--radius-large);
      border-bottom-right-radius: var(--radius-large);
      border-top-left-radius: var(--radius-large);
      border-top-right-radius: var(--radius-large);

      max-width: 100%;
      margin: 0 auto;

      &.is-rounded {
        border-radius: 12px;
      }

      .modal-card-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 15px;
        background: var(--white);
        border-bottom: 1px solid var(--border);

        &.no-border {
          border-bottom-color: transparent;
        }

        h3 {
          font-family: var(--font-alt);
          color: var(--dark-text);
          font-weight: 600;
          font-size: 1rem;
        }

        .v-modal-close {
          display: flex;
          justify-content: center;
          align-items: center;
          background: none;
          border-color: transparent;
          // width: 22px;
          // height: 22px;
          padding: 0;

          &:hover,
          &:focus {
            svg {
              color: var(--primary);
            }
          }

          &:focus-visible {
            outline-offset: var(--accessibility-focus-outline-offset);
            outline-width: var(--accessibility-focus-outline-width);
            outline-style: var(--accessibility-focus-outline-style);
            outline-color: var(--accessibility-focus-outline-color);
          }

          svg {
            // width: 22px;
            // height: 22px;
            color: var(--muted-grey);
          }
        }
      }

      .modal-card-body {
        .modal-form {
          padding: 10px 0 20px;
        }

        &.has-tabs {
          // To fix the height in case of tabs (multiple forms)
          // height: 550px;
          overflow: auto;
          padding: 0;
          overflow-x: hidden;

          .tabs {
            overflow-x: auto;

            &::-webkit-scrollbar {
              height: 3px !important;
            }

            &::-webkit-scrollbar-thumb {
              border-radius: 10px !important;
              background: rgb(0 0 0 / 20%) !important;
            }

            a {
              padding: 0.75em 0.9em;
            }

            span {
              line-height: 1em;
            }
          }
        }
      }

      .modal-card-foot {
        background-color: var(--white);
        padding: 10px 15px;
        border-top: 1px solid var(--border);

        &.no-footer {
          border: none !important;
        }

        &.no-border {
          border-top-color: transparent;
        }

        &.is-start {
          justify-content: flex-start !important;
        }

        &.is-centered {
          justify-content: center !important;
        }

        &.is-end {
          justify-content: flex-end !important;
        }
      }
    }
  }
}

@media screen and (min-width: 769px) {
  .modal.modal-lg {
    .modal-card,
    .modal-content {
      width: 800px !important;
    }
  }

  .modal.modal-sm {
    .modal-card,
    .modal-content {
      width: 400px !important;
    }
  }
}

.override-modal {
  position: relative;
  z-index: 0 !important;

  .modal-background {
    background: none;
    cursor: default !important;
  }

  .modal-content {
    width: 50% !important;
    max-width: 50% !important;
    padding: 0;
    border-radius: var(--radius-large);
    border: 1px solid var(--border);
  }

  .modal-card {
    height: 100% !important;
    max-height: 100%;
  }

  .modal-card-head {
    display: none !important;
  }

  .modal-card-foot {
    border-radius: 0;
  }

  /*
						.left,
						.right {
							display: none;
						}

						.center {
					    margin: 0 auto;
						}
					  */
}

/* ==========================================================================
9. Media Queries
========================================================================== */
@media (max-width: 767px) {
  .override-modal {
    .modal-content {
      width: 100% !important;
      max-width: 100% !important;
    }
  }
}
</style>
